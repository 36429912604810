import React from 'react';
import { Form, MultiSelect, Button } from '@loanpal/lumos__core';
import { object, string, array } from "yup";
import { Box } from "theme-ui";
import {
  Text
} from "@aws-amplify/ui-react";

const States = ({ active, statesItems, statesArray = [], setInput, addSnack }) => {
  const schema = object({
    states: array().of(string())
  });

  const initialValues = {
    states: statesArray ? statesArray.split(',') : []
  };

  const handleSubmit = ({...values}) => {
    console.log(values);
    setInput("states", String(values.states));
    addSnack("success", "States");
  }

  return (
    <>
    {active &&
        <Form onSubmit={handleSubmit} validationSchema={schema} initialValues={initialValues}>
            <Box sx={{ width: "100%", mr: 5, mt: 2 }}>
                  <MultiSelect
                    name="states"
                    label={<Text>
                    States
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (This list will overwrite the states coming from offers)
                    </Text>
                    </Text>}
                    items={statesItems.map((state) => ({
                      name: state,
                      value: state
                    }))}
                    readOnly={!active}
                />
            </Box>
            <Button type="submit">Select States</Button>
        </Form>
    }
    </>
  )
}

export default States;