import React from 'react';
import { Form, MultiSelect, Button } from '@loanpal/lumos__core';
import { object, string, array } from "yup";
import { Box } from "theme-ui";
import {
  Text
} from "@aws-amplify/ui-react";

const Offers = ({ active, offerItems, offersArray = [], setInput, addSnack, readOnly }) => {
  const schema = object({
    offers: array().of(string()).min(1, "Select at least 1 offer")
  });

  const initialValues = {
    offers: offersArray ? offersArray.split(',') : []
  };

  const handleSubmit = ({...values}) => {
    setInput("offer", String(values.offers));
    addSnack("success", "Offers");
  }

  const OffersInput = () => {
    return (
      <>
      <Box sx={{ width: "100%", mr: 5, mt: 2 }}>
      <MultiSelect
          name="offers"
          label={<Text>
          Offer(s) Requested
          <Text as="span" fontSize="0.8rem" color="#660000">
              {' '}
          (required)
          </Text>
          </Text>}
          description="Select offers and cick the button below to confirm"
          items={offerItems.map((offer) => ({
            name: offer.name,
            value: offer.value
          }))}
          readOnly={readOnly}
      />
      </Box>
      <Button disabled={readOnly} type="submit">Confirm Selected Offers</Button>
      </>
    )
  }

  return (
    <>
    {active &&
        <Form
          onSubmit={handleSubmit}
          validationSchema={schema}
          initialValues={initialValues}>
            <OffersInput />
        </Form>
    }
    </>
  )
}

export default Offers;