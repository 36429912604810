const keys = {
  upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  lowerCase: "abcdefghijklmnopqrstuvwxyz",
  number: "0123456789",
  symbol: "@#$%&*"
}
const getKey = [
  function upperCase() {
    return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
  },
  function lowerCase() {
    return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
  },
  function number() {
    return keys.number[Math.floor(Math.random() * keys.number.length)];
  },
  function symbol() {
    return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
  }
];

export const generatePassword = (length) => {
    if (length < 8) length = 8;
    let password = "";
    while (length > password.length) {
        if (password.length === 0) {
          let keyToAdd = getKey[0];
          password += keyToAdd();
        } else if (password.length === 2) {
          let keyToAdd = getKey[1];
          password += keyToAdd();
        } else if (password.length === 4) {
          let keyToAdd = getKey[2];
          password += keyToAdd();
        } else if (password.length === 6) {
          let keyToAdd = getKey[3];
          password += keyToAdd();
        } else {
          let keyToAdd = getKey[Math.floor(Math.random() * getKey.length)];
          password += keyToAdd();
        }
    }
    return password
}