import React, { useState } from "react";
import { Box, Grid, Spinner } from "theme-ui";
import { object, string } from "yup";
import { styles } from "./styles";
import {
  phoneDigits
} from "../../utils/format";
import {
  Heading,
  TextInput,
  Form,
  Button,
  DatePicker,
  SecretInput
} from "@loanpal/lumos__core";
import { Flex } from '@aws-amplify/ui-react';
import { date, ssn, phone } from '@loanpal/yup';
import { CUSTOMER_SUPPORT_PHONE } from '../../utils/glodbals';

const BorrowerInfo = ({ state, send }) => {
    const [solarLoan] = useState(state.context.solarLoan);
    const [isLoading, setIsLoading] = useState(false);
    const key = state.matches('startApplication.main') ? 'startApplication.main' : 'startApplication.coborrower';
    const metaKey = `applicationForm.${key}`;
    const pageTitle = state.meta[metaKey].title;
    const isCoborrower = key === 'startApplication.coborrower';

    const initialBorrowerInfo = isCoborrower
    ? {
        firstName: solarLoan.coBorrower ? solarLoan.coBorrower.firstName : "",
        lastName: solarLoan.coBorrower ?  solarLoan.coBorrower.lastName: "",
        email: solarLoan.coBorrower ?  solarLoan.coBorrower.email: "",
        mobile: solarLoan.coBorrower ? phoneDigits(solarLoan.coBorrower.phones[0].value) : "",
        dob: state.context.coBorrowerInfo ? state.context.coBorrowerInfo.dob : "",
        secondaryPhoneNumber: "",
        ssn: state.context.coBorrowerInfo ? state.context.coBorrowerInfo.ssn : "",
      } : {
        firstName: solarLoan.borrower ? solarLoan.borrower.firstName : "",
        lastName: solarLoan.borrower ?  solarLoan.borrower.lastName: "",
        email: solarLoan.borrower ?  solarLoan.borrower.email: "",
        mobile: solarLoan.borrower ? phoneDigits(solarLoan.borrower.phones[0].value) : "",
        dob: state.context.borrowerInfo ? state.context.borrowerInfo.dob : "",
        secondaryPhoneNumber: "",
        ssn: state.context.borrowerInfo ? state.context.borrowerInfo.ssn : "",
      };

    return (
        <Form
            initialValues={{
              ...initialBorrowerInfo,
            }}
            enableReinitialize={false}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={object({
              firstName: string().required(`Enter your first name`),
              lastName: string().required(`Enter your last name`),
              email: string()
                .email("Enter a valid email address, like name@example.com")
                .required(`Enter the borrower's email address`),
              ssn: ssn({ allowFour: true })
                .format('no-dash')
                .required('Enter social security number')
                .typeError('Please enter numbers of full 9 digit SSN or last 4 SSN. Dashes are optional.'),
              mobile: phone()
                .format('E164')
                .required('Enter mobile phone number')
                .typeError('Enter a valid 10-digit US phone number with area code. No dashes or parenthesis needed.'),
              secondaryPhoneNumber: phone()
                .format('E164')
                .typeError(
                    'Please enter a valid 10-digit US phone number with area code. No dashes or parenthesis needed.'
                ),
              dob: date()
                .format()
                .minAge(
                  18,
                  `If you are under 18 years old, GoodLeap cannot process or approve your loan. If you have questions, please call ${CUSTOMER_SUPPORT_PHONE}`
                )
                .typeError('Date of birth must be a real date')
                .required('Enter your date of birth')
                .test('Applicant Max Age', 'Please enter an age less than 135 years', (value) => {
                  // Using UNIX epoch for math to account for leap years
                  const currentDate = +new Date();
                  const birthDate= +new Date(value);
                  const getDifferenceInDays = (date1, date2) => {
                    const diffInMs = Math.abs(date1 - date2)
                    return diffInMs / (1000 * 60 * 60 * 24)
                  }
                  const differenceInDays = getDifferenceInDays(currentDate, birthDate);
                  return !(differenceInDays / 365 > 135);
                })
            })}
            onSubmit={({ ...values }  ) => {
                setIsLoading(true);
                setTimeout(() => send('NEXT_PAGE', { input: { ...values } }), 3000);
            }}
            showSummary={false}
          >
              <Box sx={{ pl: 4, pr: 4, pb: 4, pt: 2, backgroundColor: "#fafafa", mt: 2 }}>
                <Heading variant="h4">{pageTitle}</Heading>
                <Box>
                  <>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <TextInput
                      name="firstName"
                      label="First name"
                      autoComplete="off"
                    />
                    <TextInput
                      name="lastName"
                      label="Last name"
                      autoComplete="off"
                    />
                  </Grid>
                  <DatePicker name="dob" label="Date of birth" maxYearLimit={-18} />
                  <SecretInput
                        name="ssn"
                        autoComplete="off"
                        label="Social Security Number"
                        tooltip={
                        <div sx={{ maxWidth: 350 }}>
                            <Heading as="div" variant="h5">
                            Social Security Number
                            </Heading>
                            <div sx={{ inlineSize: '150px'}}>You can provide your full Social Security Number (SSN) or just the last 4 digits.<br/> By
                            providing the full SSN you may reduce stipulations during the loan process.</div>
                        </div>
                        }
                    />
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <TextInput
                      name="mobile"
                      label="Mobile phone number"
                      autoComplete="off"
                      type="phone"
                    />
                    <TextInput
                        name="secondaryPhoneNumber"
                        label="Secondary phone number (optional)"
                        autoComplete="off"
                    />
                  </Grid>
                  <TextInput
                      name="email"
                      label="Email address"
                      autoComplete="off"
                      type="email"
                    />
                  <Flex
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                  >
                    <Button type="submit" disabled={isLoading} sx={{ mt: 3, px: 5 }}>
                      {isLoading ? (
                        <div styles={styles.isLoading}>
                          <Spinner size={20} sx={{ mr: 2, mb: -1 }} />
                          SAVING...
                        </div>
                      ) : (
                        'SAVE AND CONTINUE'
                      )}
                    </Button>
                  </Flex>
                  </>
                </Box>
              </Box>
            </Form>
    )
}

export default BorrowerInfo;