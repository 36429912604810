import React, { useState, useEffect } from "react";
import { StartScreen } from '../../application';
import { Approved, Conditional, Declined, Pending } from '../../application';
import { Box } from "theme-ui";
import { default as ErrorPage } from "../../../utils/error";
import { applyForLoan, changeOrders, addTagByLoanId } from "../../../utils/api";
import Loading from "../../../utils/loading";
import { generateShortUUID } from "../../../utils/uuidGenerator";

const Decision = ({ state, send }) => {
    const pageProps = { state, send };
    const [payload] = useState(state.context.applicationPayload);
    const [submitted] = useState(state.context.submitted);
    const { clientId } = state.context.sharedApp;
    const { solarLoan, selectedBundleAmount } = state.context;
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (payload && errorMessage === "" && !submitted) {
            localStorage.setItem("bundleSolarLoan", solarLoan.id);
            submitApplication(clientId,payload);
        }
        // eslint-disable-next-line
    }, []);

    const submitApplication = async (clientId, payload) => {
        try {
            await applyForLoan(clientId, payload).then(async (response) => {
                const decision = response;
                console.log("RESPONSE", decision);
                console.log("SOLAR LOAN", solarLoan);
                // if APPROVED DO Change Order and Tags here...
                if (decision && decision.status === "APPROVED") {
                    // Do change order on Solar loan
                    // const amount = Number(solarLoan.amount.value) + Number(decision.applicationAmount.value);
                    const amount = Number(selectedBundleAmount);
                    const uuid = generateShortUUID();
                    const payloadCO = JSON.stringify({
                        changes: [{
                            type: "AMOUNT",
                            value: {
                                type: "USD",
                                value: amount
                            }
                        }]
                    });
                    await changeOrders(solarLoan.partnerId, solarLoan.id, payloadCO).then(async (response) => {
                    if (response) {
                        // add tag to solar and hi loans
                        await addTagByLoanId(solarLoan.partnerId, solarLoan.id, "GLP-QL-".concat(uuid)).then((response) => {
                        if (response) {
                            // addSnack({ variant: 'success', message: `Tag for Solar added!` });
                            console.log(response, "Solar Tag added to loan", "GLP-QL-".concat(uuid));
                        }
                        });
                        await addTagByLoanId(clientId, decision.id, "GLP-QL-".concat(uuid)).then((response) => {
                            if (response) {
                                // addSnack({ variant: 'success', message: `Tag for Hi added!` });
                                console.log(response, "Hi Tag added", "GLP-QL-".concat(uuid));
                            }
                        });
                    }
                    setTimeout(() => send('APPLICATION_RESPONSE', { input: { applicationResponse: decision, submitted: true } }), 5000);
                    });
                } else if (decision && decision.status === "CONDITIONAL") {
                    // add goodleapplus tag to solar
                    await addTagByLoanId(solarLoan.partnerId, solarLoan.id, "goodleapplus").then((response2) => {
                        if (response2) {
                            // addSnack({ variant: 'success', message: `Tag for Solar added!` });
                            console.log(response2, "Solar Tag added to loan");
                        }
                    });
                    await addTagByLoanId(clientId, decision.id, solarLoan.id).then((response3) => {
                        if (response3) {
                            // addSnack({ variant: 'success', message: `Tag for Hi added!` });
                            console.log(response, "Hi Tag added to loan");
                        }
                    });
                    setTimeout(() => send('APPLICATION_RESPONSE', { input: { applicationResponse: decision, submitted: true } }), 5000);
                } else {
                    console.log("OTHER DECISION", decision);
                    setErrorMessage("Error submitting app");
                }
            });
        } catch (e) {
            console.log("Error", e);
            setErrorMessage("Error submitting app");
        }
    }

    return (
        <>
        {errorMessage === "" && !state.context.applicationResponse && <Loading />}
        {errorMessage !== "" && <ErrorPage sharedApp={state.context.sharedApp} errorMessage={"ERROR no submit app response"} />}
        {state.context.applicationResponse && (
        <Box
        sx={{
            maxWidth: "960px",
            backgroundColor: "#ffffff",
            mx: "auto",
            display: 'flex',
            flexDirection: 'column',
            // minHeight: '100vh',
        }}>
        <>
        <StartScreen />
            <Box
            sx={{
                // width: "100%",
                margin: "0 auto",
                padding: "10px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                borderColor: '#d7d7d7',
                backgroundColor: "#ffffff",
                m: 2 }}
            >
            {state.context.applicationResponse.status === "APPROVED" && <Approved  {...pageProps} />}
            {state.context.applicationResponse.status === "CONDITIONAL" && <Conditional  {...pageProps} />}
            {state.context.applicationResponse.status === "DECLINED" && <Declined  {...pageProps} />}
            {state.context.applicationResponse.status === "PENDING" && <Pending  {...pageProps} />}
            </Box>
        </>
        </Box>
        )};
    </>
    )
}

export default Decision;