import React from 'react';
// prod SharedApp-ddrj5opqljgbtnn5p4d2i7d25i-prod
// prod Requests-ddrj5opqljgbtnn5p4d2i7d25i-prod
// dev SharedApp-4he23jw7efc3vo3bspj27imobi-dev
// dev Requests-4he23jw7efc3vo3bspj27imobi-dev
import ExportDynamoDBTable from '../../utils/export';
import { Amplify } from "aws-amplify";
import {
  Authenticator,
  Flex
} from "@aws-amplify/ui-react";
import { Box } from "theme-ui";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import {
  Heading,
  Button as LumosButton,
 } from "@loanpal/lumos__core";

 import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const App = () => {
  return (
<Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  marginTop: "20px",
                  fontSize: "20px",
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <ExportDynamoDBTable tableName="SharedApp-ddrj5opqljgbtnn5p4d2i7d25i-prod" />
                </Flex>
              </Heading>
            </Box>
            </div>
        </>
    )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "30px"
  }
};

export default App;