import React, { useEffect } from "react"
import { ChoiceInput, TextInput, Heading } from "@loanpal/lumos__core"
import { useFormikContext } from "formik"
import { Grid } from "theme-ui"
import { HomeOwnershipEnum } from "../../utils/types"

const ownershipOptions = [
  {
    label: "Yes, with one or more mortgages",
    value: HomeOwnershipEnum.OWNED_WITH_MORTGAGE
  },
  {
    label: "Yes, free and clear with no mortgages",
    value: HomeOwnershipEnum.OWNED_FREE_AND_CLEAR
  },
  { label: "No, I do not own this home", value: HomeOwnershipEnum.NOT_OWNED }
]

const mortgageFieldsTooltip = (
  <div sx={{ maxWidth: 240 }}>
    <Heading as="div" variant="h5">
      Estimated mortgage
    </Heading>
    Please provide this information to reduce the need for additional
    documentation.
  </div>
)

const Ownership = ({ shouldAskMortgage, isCoborrower }) => {
  const {
    values: { homeOwnership, totalMonthlyPayment, totalRemainingBalance },
    setFieldValue
  } = useFormikContext()

  const hasMortgage = homeOwnership === HomeOwnershipEnum.OWNED_WITH_MORTGAGE
  const showMortgageFields = Boolean(shouldAskMortgage && hasMortgage)

  useEffect(() => {
    const shouldRequireMortgagePaymentInput =
      !!totalMonthlyPayment || !!totalRemainingBalance || hasMortgage;
    setFieldValue(
      "shouldRequireMortgagePaymentInput",
      String(shouldRequireMortgagePaymentInput)
    )
  }, [totalMonthlyPayment, totalRemainingBalance, hasMortgage]) // eslint-disable-line react-hooks/exhaustive-deps

  const ownershipLabel = isCoborrower
    ? "Do you own this home where the work will be done?"
    : "Do you own this home?"

  return (
    <>
      <ChoiceInput
        name="homeOwnership"
        label={ownershipLabel}
        items={ownershipOptions}
      />
      {showMortgageFields && (
        <Grid columns={[1, 2]} px={[2, 4]} gap={[0, 3]} pb={2}>
          <TextInput
            prefix="$"
            autoComplete="off"
            name="totalMonthlyPayment"
            tooltip={mortgageFieldsTooltip}
            label="Estimated monthly mortgage payment"
          />
          <TextInput
            prefix="$"
            autoComplete="off"
            name="totalRemainingBalance"
            tooltip={mortgageFieldsTooltip}
            label="Estimated mortgage balance"
          />
        </Grid>
      )}
    </>
  )
}

export default Ownership
