import HOME_IMPROVEMENT from "../images/HI_1.png";
import ROOF from "../images/ROOF_1.png";
import SOLAR from "../images/SOLAR_1.png";
import BATTERY from "../images/BATTERY_1.png";

export const DEFAULT_CURRENCY = 'USD';

export const CUSTOMER_SUPPORT_PHONE = '(844) 910-0111';
export const CUSTOMER_CARE_PHONE = ' (877)-290-9991';

export const EMPTY_DISPLAY_VALUE = '-';
export const COMMA = ',';

export const DEFAULT_PERCENTAGE = 0.26;

export const getCategoryName = (category) => {
    if (category === "HI_1") return "Home Improvement";
    else if (category === "ROOF_1") return "Roofing / Windows / Doors / Siding / Geothermal / Restoration";
    else if (category === "Solar") return "Solar";
    else if (category === "Battery") return "Stand-Alone Battery";
  }

export const getCategoryLogo = (category) => {
        if (category === "HI_1") return HOME_IMPROVEMENT
        else if (category === "ROOF_1") return ROOF;
        else if (category === "Solar") return SOLAR;
        else if (category === "Battery") return BATTERY;
    }