export const listSalesforceURLs = /* GraphQL */ `
    query AllRequests {
    searchRequestss(sort: {direction: desc, field: createdAt}) {
        items {
        id
        salesforce
        salesforceId
        createdAt
        updatedAt
        }
    }
    }
`;

export const reqByClientId = /* GraphQL */ `
  query reqByClientId(
    $clientId: String
  ) {
    reqByClientId(
      clientId: $clientId
    ) {
      items {
        id
        accountName
        clientId
        status
        offer
      }
    }
  }
`;

export const appsByClientId = /* GraphQL */ `
  query AppsByClientId(
    $clientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSharedAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        submittingUser {
            email
            firstName
            lastName
            phone
            __typename
        }
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const searchRequestss = /* GraphQL */ `
  query SearchRequestss(
    $filter: SearchableRequestsFilterInput
    $sort: SearchableRequestsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRequestss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
            email
            firstName
            lastName
            phone
            __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
