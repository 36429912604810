import React, { useEffect } from "react";
import { CategorySelect, StartScreen, Greeting, SharedApp } from '../../application';
import Loading from "../../../utils/loading";
import { useParams } from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine, assign } from 'xstate';
import { Box } from "theme-ui";
// api
import { API, graphqlOperation } from "aws-amplify";
import { getSharedApp } from "../../../graphql/queries";

const fetchSharedApp = async (context) => {
  try {
    const appData = await API.graphql(
      graphqlOperation(getSharedApp, { id: context.appid })
    );
    return(appData.data.getSharedApp);
  } catch (err) {
    console.log("error fetching SharedApp", err);
  }
}

const fetchUsers = async (context) => {
  try {
  const myInit0 = {
      queryStringParameters: {
        apiId: `${context.sharedApp.clientId}ApiId`,
        apiKey: `${context.sharedApp.clientId}ApiKey`,
        clientId: context.sharedApp.clientId
      },
    };
    // get users
    const response = await API.get("developerApi", "/users", myInit0);
    return (response.data);
  } catch (err) {
    console.log("error getting users", err);
    return false;
  }
}

const fetchAvailableCategories = async (context) => {
  try {
  const myInit0 = {
      queryStringParameters: {
        apiId: `${context.sharedApp.clientId}ApiId`,
        apiKey: `${context.sharedApp.clientId}ApiKey`,
        clientId: context.sharedApp.clientId
      },
    };
    // get categories exclude Solar and Battery for QL
    const data = await API.get("developerApi", "/categories", myInit0);
    const modifiedData = data.filter(obj => obj.name !== "SOLAR" && obj.name !== "BATTERY");
    return (modifiedData);
  } catch (err) {
    console.log("error getting categories", err);
    return false;
  }
}

const starting = (context) => {
    console.log(context);
  return null;
}

var setAvailableCategories = assign({
    categories: function (_, e) { return e.data; }
});

var clearOtherFields = assign({
  selectedOffer: undefined
})

var setSelectedCategory = assign({
  selectedCategory: function (_, e) { return e.input.categoryId; },
  selectedUser: function (_, e) { return e.input.userId },
  salesRepEmail: function (_, e) { return e.input.salesRepEmail },
  salesRep: function (_, e) { return e.input.salesRep },
  selectedState: function (_, e) { return e.input.state },
  selectedChannel: function (_, e) { return e.input.groupId },
  referenceNumber: function (_, e) { return e.input.referenceNumber }
})

const applicationFormMachine = createMachine({
  predictableActionArguments: true,
  id: 'applicationForm',
  initial: 'fetchSharedApp',
  context: {
    clientId: undefined,
    appid: undefined,
    sharedApp: undefined,
    users: undefined,
    selectedUser: undefined,
    salesRep: undefined,
    categories: undefined,
    states: undefined,
    selectedState: undefined,
    selectedCategory: undefined,
    products: undefined,
    selectedOffer: undefined,
    selectedChannel: undefined,
    referenceNumber: undefined
  },
  states: {
    fetchSharedApp: {
      invoke: {
        src: fetchSharedApp,
        onDone: {
          target: 'fetchUsers',
          actions: assign({ sharedApp: (context, event) => event.data })
        }
      }
    },
    fetchUsers: {
      invoke: {
        src: fetchUsers,
        onDone: {
          target: 'fetchCategories',
          actions: assign({ users: (context, event) => event.data })
        }
      }
    },
    fetchCategories: {
      invoke: {
        src: fetchAvailableCategories,
        onDone: {
          target: 'categorySelect',
          actions: setAvailableCategories
          // actions: assign({ categories: (context, event) => event.data })
        }
      }
    },
    categorySelect: {
        initial: 'main',
        states: {
            main: {
            on: {
                SELECTED_CATEGORY: [
                {
                    target: '#applicationForm.startApplication',
                    actions: [clearOtherFields, setSelectedCategory]
                }
                ]
            }
            }
        }
    },
    startApplication: {
        initial: 'main',
        states: {
            main: {
              on: {
                PREVIOUS_PAGE: '#applicationForm.categorySelect',
                SHARE_APPLICATION: 'starting'
              }
            },
            starting: {
                invoke: {
                    src: starting
                }
            }
        }
    }
  }
});


const SunRunForm = () => {
  const { id, client = 'sunrun' } = useParams();
  const [state, send, service] = useMachine(applicationFormMachine, { context:
    {
      clientId: client,
      appid: id,
      sharedApp: undefined,
      categories: undefined,
      states: undefined,
      selectedState: undefined,
      selectedCategory: undefined,
      selectedUser: undefined,
      salesRepEmail: undefined,
      salesRep: undefined,
      selectedOffer: undefined,
      selectedChannel: undefined,
      referenceNumber: undefined
    }
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      console.log(state);
    });
    return subscription.unsubscribe;
  }, [service]);

  const pageProps = { state, send };

  const renderNode = () => {
    switch (true) {
    //   case state.matches('fetchSharedApp'):
    //   case state.matches('fetchCategories'):
    //     return <Loading />;
      case state.matches('categorySelect'):
        return <CategorySelect {...pageProps} />;
      case state.matches('startApplication'):
        return <SharedApp {...pageProps} />;
      // case state.matches('error'):
      //   return <ErrorPage statusCode={500} error={state.context.error} />;
      // case state.matches('offerSelect'):
      //   return <OfferSelect {...pageProps} />;
      // case state.matches('sendEmail'):
      //   return <EmailInput {...pageProps} />;
      default:
        return <Loading />;
    }
  };
  return (
    <>
    {!state.matches('startApplication') && (
     <Box
      sx={{
        maxWidth: "960px",
        backgroundColor: "#ffffff",
        mx: "auto",
         display: 'flex',
        flexDirection: 'column',
        // minHeight: '100vh',
      }}>
      <>
      <StartScreen />
        <Box
          sx={{
            // width: "100%",
            margin: "0 auto",
            padding: "10px",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            borderColor: '#d7d7d7',
            backgroundColor: "#ffffff",
            m: 2 }}
        >
          <Greeting />
          <Box sx={{ bg: '#ffffff' }}>{renderNode()}</Box>
        </Box>
       </>
      </Box>)}
    {state.matches('startApplication') && (<Box sx={{ bg: '#f5f5f5' }}>{renderNode()}</Box>)}
    </>
  )
}

export default SunRunForm;
