export const Status = {
  ACTIVE: "Active",
  INPROGRESS: "In progress",
  BLOCKED: "Blocked",
  PENDING: "Pending",
  NOTACTIVE: "Not active",
  TEST: "Test account",
  NEW: "New",
  NEWPLUS: "Ready for creation",
  COMPLETED: "Completed",
  WAITINGONAPIKEYS: "Waiting on API keys",
  READYFORREQUESTER: "Ready for 2nd Step",
  READYFORCREATION: "Ready for Quicklink",
  UPDATEQUICKLINK: "Update QuickLink"
};

export const StatusVariant = [
    {
        type: "ACTIVE",
        variant: "success"
    },
    {
        type: "INPROGRESS",
        variant: "primary"
    },
    {
        type: "NOTACTIVE",
        variant: "warning"
    },
    {
        type: "PENDING",
        variant: "warning"
    },
    {
        type: "BLOCKED",
        variant: "danger"
    },
    {
        type: "TEST",
        variant: "info"
    },
    {
        type: "NEW",
        variant: "warning"
    },
    {
        type: "NEWPLUS",
        variant: "warning"
    },
    {
        type: "COMPLETED",
        variant: "success"
    },
    {
        type: "WAITINGONAPIKEYS",
        variant: "info"
    },
    {
        type: "READYFORREQUESTER",
        variant: "success"
    },
    {
        type: "READYFORCREATION",
        variant: "warning"
    },
    {
        type: "UPDATEQUICKLINK",
        variant: "warning"
    }
];
