import React, { useEffect } from "react";
import { CategorySelect, StartScreen } from '../application';
import Loading from "../../utils/loading";
import { useParams } from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine, assign } from 'xstate';
import { Box, Card } from "theme-ui";

// api
import { API, graphqlOperation } from "aws-amplify";
import { getSharedApp } from "../../graphql/queries";

const fetchSharedApp = async (context) => {
  try {
    const appData = await API.graphql(
      graphqlOperation(getSharedApp, { id: context.appid })
    );
    return(appData.data.getSharedApp);
  } catch (err) {
    console.log("error fetching SharedApp", err);
  }
}

const fetchUsers = async (context) => {
  try {
  const myInit0 = {
      queryStringParameters: {
        apiId: `${context.sharedApp.clientId}ApiId`,
        apiKey: `${context.sharedApp.clientId}ApiKey`,
        clientId: context.sharedApp.clientId
      },
    };
    // get users
    const response = await API.get("developerApi", "/users", myInit0);
    return (response.data);
  } catch (err) {
    console.log("error getting users", err);
    return false;
  }
}

const fetchAvailableCategories = async (context) => {
  try {
  const myInit0 = {
      queryStringParameters: {
        apiId: `${context.sharedApp.clientId}ApiId`,
        apiKey: `${context.sharedApp.clientId}ApiKey`,
        clientId: context.sharedApp.clientId
      },
    };
    // get categories
    const data = await API.get("developerApi", "/categories", myInit0);
    const modifiedData = data.filter(obj => obj.name !== "SOLAR" && obj.name !== "BATTERY");
    return (modifiedData);
  } catch (err) {
    console.log("error getting categories", err);
    return false;
  }
}

const fetchProducts = (context) => {
  return null;
}

var setAvailableCategories = assign({
    categories: function (_, e) { return e.data; }
});

var clearOtherFields = assign({
  products: undefined,
  seletedOffer: undefined
})

var setSelectedCategory = assign({
  selectedCategory: function (_, e) { return e.input.categoryId; },
  selectedUser: function (_, e) { return e.input.userId }
})

const applicationFormMachine = createMachine({
  id: 'applicationForm',
  initial: 'fetchSharedApp',
  context: {
    clientId: undefined,
    appid: undefined,
    sharedApp: undefined,
    users: undefined,
    selectedUser: undefined,
    categories: undefined,
    selectedCategory: undefined,
    products: undefined,
    selectedOffer: undefined
  },
  states: {
    fetchSharedApp: {
      invoke: {
        src: fetchSharedApp,
        onDone: {
          target: 'fetchUsers',
          actions: assign({ sharedApp: (context, event) => event.data })
        }
      }
    },
    fetchUsers: {
      invoke: {
        src: fetchUsers,
        onDone: {
          target: 'fetchCategories',
          actions: assign({ users: (context, event) => event.data })
        }
      }
    },
    fetchCategories: {
      invoke: {
        src: fetchAvailableCategories,
        onDone: {
          target: 'categorySelect',
          actions: setAvailableCategories
          // actions: assign({ categories: (context, event) => event.data })
        }
      }
    },
    categorySelect: {
        initial: 'main',
        states: {
          main: {
            on: {
              SELECTED_CATEGORY: [
                {
                  target: 'fetchProducts',
                  actions: [clearOtherFields, setSelectedCategory]
                }
              ]
            }
          },
          fetchProducts: {
              invoke: {
              src: fetchProducts
            }
          }
        }
      }
    }
  });


const SunRunForm = () => {
  const { id, client = 'sunrun' } = useParams();
  const [state, send, service] = useMachine(applicationFormMachine, { context:
    {
      clientId: client,
      appid: id,
      sharedApp: undefined,
      categories: undefined,
      selectedCategory: undefined,
      selectedUser: undefined
    }
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      console.log(state);
    });
    return subscription.unsubscribe;
  }, [service]);

  const pageProps = { state, send };

  const renderNode = () => {
    switch (true) {
      case state.matches('fetchCategories'):
        return <Loading />;
      case state.matches('categorySelect'):
        return <CategorySelect {...pageProps} />;
      // case state.matches('error'):
      //   return <ErrorPage statusCode={500} error={state.context.error} />;
      // case state.matches('offerSelect'):
      //   return <OfferSelect {...pageProps} />;
      // case state.matches('sendEmail'):
      //   return <EmailInput {...pageProps} />;
      default:
        return <div></div>;
    }
  };
  return (
     <Box
      sx={{
        maxWidth: "100%",
        mx: "auto",
      }}
    >
      <StartScreen />
      <Card
        sx={{
          width: 960,
          margin: "0 auto",
          border: 1,
          borderColor: '#d7d7d7',
          backgroundColor: '#ffffff'
        }}>
          <Box sx={{ bg: '#ffffff' }}>{renderNode()}</Box>
        </Card>
      </Box>
  )
}

export default SunRunForm;
