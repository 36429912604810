import { Button, Icon } from '@loanpal/lumos__core';
import { styles } from "./styles";

export const BackButton = ({ onClick, children }) => (
  <Button
    onClick={onClick}
    variant="ghost"
    sx={{ color: '#00838f', textTransform: 'capitalize', fontSize: 2 }}
  >
    <div style={styles.backButtonIcon}>
      <Icon variant="angle-left" color="white" />
    </div>
    {children}
  </Button>
);

export const BackBar = ({ onClick, children }) => (
  <div style={styles.backBar}>
    <BackButton onClick={onClick}>{children}</BackButton>
  </div>
);