import React from "react";
import { Box, Text, Image } from "theme-ui";
import { styles } from "./styles";
import logoSR from '../../images/sunrun-vector-logo.svg';
import logoGLP from '../../images/glplus_grey_gradient.svg';

const Greeting = ({ logo, redirectFromSharedApp = false}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pl: 2, pr: 2, fontSize: '120%' }}>
      { redirectFromSharedApp && (
        <Text>
            <span style={styles.bold}>Congratulations!</span> See details below...
        </Text>
      )}
      { !redirectFromSharedApp && (
        <Text>
            <span style={styles.bold}>Welcome!</span> Lets get started.
        </Text>
      )}
        {logo === "SunRun" && (
          <Image src={logoSR} alt="SunRun" sx={{ width: '100px' }} />
        )}
        {logo === "GLP" && (
          <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '150px', margin: '10px' }} />
        )}
     </Box>
  )
};

export default Greeting;