import React from "react"

const isStreetAddress = address => address.street !== undefined
const Address = ({ address, inline = false, sx, ...rest }) => {
  if (!address) return null
  const street = isStreetAddress(address) ? address.street : address.street1
  const { street2, city, state, zip } = address

  if (inline)
    return (
      <address sx={{ fontStyle: "normal", ...sx }} {...rest}>
        {street}
        {street2 ? ` ${street2}` : ""}, {city}, {state} {zip}
      </address>
    )
  return (
    <address sx={{ fontStyle: "normal", ...sx }} {...rest}>
      {street}
      <br />
      {street2 && (
        <>
          {street2}
          <br />
        </>
      )}
      {city}, {state} {zip}
    </address>
  )
}
export default Address