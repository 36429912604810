import React, { useState, useEffect } from "react";
import { Box, Image, Text } from "theme-ui";
import Loading from "../../../utils/loading";
import { Divider } from "@aws-amplify/ui-react";
import { DisplayConditionalDetails, DisplayLoan } from "../../application";
import {
        useNotify,
         Heading,
         Icon,
         Card
        } from '@loanpal/lumos__core';
import { getLoanById } from "../../../utils/api";
import logoGLP from '../../../images/glplus_grey_gradient.svg';
import { numberToMoney } from "../../../utils/format";
import { styles } from "../styles";

const Conditional = ({ state, send }) => {
    const [loanDetails, setLoanDetails] = useState();
    const [decision] = useState(state.context.applicationResponse);
    const [sharedApp] = useState(state.context.sharedApp);
    const [loading, setLoading] = useState(true);
const { addSnack } = useNotify();

    useEffect(() => {
      if (decision && sharedApp) {
        getLoan();
      }
    // eslint-disable-next-line
    }, []);

    const getLoan = async () => {
      try {
        setLoading(true);
        await getLoanById(sharedApp.clientId, decision.id).then(loan => {
          if (loan) {
            setLoanDetails(loan);
          }
        });
      } catch (err) {
        console.log("Error getting underlying loan", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    return (
      <>
          {loading && <Loading />}
          {loanDetails && (
          <>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pl: 2, pr: 2, mb: 3, fontSize: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Icon
                  variant="success"
                  size="xl"
                  color="green"
                  sx={{
                    mr: 3,
                  }}
              />
              <Heading variant="h5" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '26px' }}>
                Congratulations {loanDetails.borrower.firstName}!<br/>
                Your Home Improvement Interim loan was conditionally approved for up to {numberToMoney(loanDetails.maxAmount.value)}
             </Heading>
            </Box>
            <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '150px', margin: '10px' }} />
          </Box>
          <Box>
            <DisplayConditionalDetails hiLoan={loanDetails} consumerDisplay={true} addSnack={addSnack} nextSteps={true} />
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <Card>
                <Heading variant="h4" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '28px' }}>
                    After your loan is approved
                </Heading>
                <Box
                    sx={{
                        display: 'flex',
                        rowGap: '15px',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                    >
                    <Box
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        }}
                    >
                    <Icon
                        variant="circle-file-edit"
                        size="lg"
                        sx={{
                            mr: 2,
                            minHeight: '48px',
                            minWidth: '48px'
                        }}
                    />
                        <Text
                            as="div"
                            sx={{
                                fontWeight: '500',
                            }}
                        >
                        Request and sign loan documents.
                        </Text>
                    </Box>
                </Box>
            </Card>
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <DisplayLoan loan={loanDetails}  loanStatus={null} loanAmount={loanDetails.amount.value} consumerDisplay={true} conditional={true} />
          </Box>
          </>
        )}
      </>
    )
}

export default Conditional;