import React, { useState } from "react";
import {
         requestLoanDocuments
} from "../../../utils/api";
import { Card,
         Button as LumosButton,
         Heading,
         Icon
    } from '@loanpal/lumos__core';
import { Box, Spinner, Text, Image } from 'theme-ui';
import {
  Flex
} from "@aws-amplify/ui-react";
import { styles } from "../styles";
import logoGLP from '../../../images/glplus_grey_gradient.svg';

const DisplayBundleDetails = ({ hiLoan, solarLoan, bundleTag, getStatus, addSnack, consumerDisplay, nextSteps }) => {
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);

    const resetStatus = async () =>{
        try {
            setLoader2(true);
            await getStatus(solarLoan.partnerId, solarLoan.id, "solar");
            await getStatus(hiLoan.partnerId, hiLoan.id, "hi");
        } catch (err) {
            console.log("error getting loan status", err);
            addSnack({ variant: 'warnint', message: `Error refreshing loan status!` });
            return false;
        } finally {
            setLoader2(false);
        }
    }

    const getLoanDocuments = async () => {
        try {
            setLoader(true);
            await requestLoanDocuments(hiLoan.partnerId, hiLoan.id).then((response) => {
                if (response) {
                    addSnack({ variant: 'success', message: `Loan Documents have been sent by email!` });
                }
            });

        } catch (err) {
            console.log("error getting loan documents", err);
            addSnack({ variant: 'danger', message: `Error requesting loan documents!` });
            return false;
        } finally {
            setLoader(false);
        }
    }
    return (
    <Card style={styles.glpcard}>
        <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '120px', mb: 2}} />
        <Box
            sx={{ mt: 1, mb: 1 }}
            >
            <Text>
                <span style={styles.bold}>Bundle Id: </span> {bundleTag}
            </Text>
        </Box>
        {nextSteps && (
        <>
        <Heading variant="h4" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '28px' }}>
                Next steps
        </Heading>
         <Box
            sx={{
                display: 'flex',
                rowGap: '15px',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            >
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                }}
            >
              <Icon
                  variant="circle-file-edit"
                  size="lg"
                  sx={{
                    mr: 2,
                    minHeight: '48px',
                    minWidth: '48px'
                  }}
              />
                <Text
                    as="div"
                    sx={{
                        fontWeight: '500',
                    }}
                >
                Request and sign loan documents
                </Text>
            </Box>
        </Box>
        </>
        )}
        <Flex
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            alignContent="flex-end"
            wrap="nowrap"
            gap="1rem"
            >
        {!consumerDisplay && (
        <LumosButton onClick={() => resetStatus()}>
            {loader2 ? (
                <div styles={styles.isLoading}>
                    <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                    Reloading status...
                </div>
            ) : (
                'Reload Loan Status'
            )}
        </LumosButton>
        )}
         <LumosButton onClick={() => getLoanDocuments()}>
            {loader ? (
                <div styles={styles.isLoading}>
                    <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                    REQUESTING...
                </div>
            ) : (
                'Get loan documents'
            )}
        </LumosButton>
        </Flex>
    </Card>
    )
}

export default DisplayBundleDetails;