/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSharedApp = /* GraphQL */ `
  query GetSharedApp($id: ID!) {
    getSharedApp(id: $id) {
      id
      clientId
      status
      partnerName
      offerId
      offerName
      dealerFee
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      states
      offers
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      displayIframe
      referenceId
      formUrl
      partnerWebsiteUrl
      bundleClientId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSharedApps = /* GraphQL */ `
  query ListSharedApps(
    $filter: ModelSharedAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoodLinks = /* GraphQL */ `
  query GetGoodLinks($id: ID!) {
    getGoodLinks(id: $id) {
      id
      clientId
      custFirstName
      custLastName
      custEmail
      custMobile
      custState
      custLoanAmount
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoodLinkss = /* GraphQL */ `
  query ListGoodLinkss(
    $filter: ModelGoodLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoodLinkss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequests = /* GraphQL */ `
  query GetRequests($id: ID!) {
    getRequests(id: $id) {
      id
      accountName
      clientId
      status
      salesforce
      salesforceId
      offer
      offersRequested
      states
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      referenceId
      contactEmail
      partnerEmail
      apiKeys
      notes
      requestNotes
      bundleClientId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequestss = /* GraphQL */ `
  query ListRequestss(
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appsByClientId = /* GraphQL */ `
  query AppsByClientId(
    $clientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSharedAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linksByClientId = /* GraphQL */ `
  query LinksByClientId(
    $clientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelGoodLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linksByClientIdSorted = /* GraphQL */ `
  query LinksByClientIdSorted(
    $clientId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoodLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByClientIdSorted(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linksByEmail = /* GraphQL */ `
  query LinksByEmail(
    $clientId: String
    $custEmail: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoodLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByEmail(
      clientId: $clientId
      custEmail: $custEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linksByEmailwoClientId = /* GraphQL */ `
  query LinksByEmailwoClientId(
    $custEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelGoodLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByEmailwoClientId(
      custEmail: $custEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linksByEmailwoClientIdSorted = /* GraphQL */ `
  query LinksByEmailwoClientIdSorted(
    $custEmail: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoodLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByEmailwoClientIdSorted(
      custEmail: $custEmail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appsByAccountName = /* GraphQL */ `
  query AppsByAccountName(
    $accountName: String
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByAccountName(
      accountName: $accountName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqsByStatus = /* GraphQL */ `
  query ReqsByStatus(
    $status: StatusEnum
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqsByStatusSorted = /* GraphQL */ `
  query ReqsByStatusSorted(
    $status: StatusEnum
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqsByStatusSorted(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqByClientId = /* GraphQL */ `
  query ReqByClientId(
    $clientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqsBySalesforceId = /* GraphQL */ `
  query ReqsBySalesforceId(
    $salesforceId: String
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqsBySalesforceId(
      salesforceId: $salesforceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchSharedApps = /* GraphQL */ `
  query SearchSharedApps(
    $filter: SearchableSharedAppFilterInput
    $sort: SearchableSharedAppSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSharedApps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchGoodLinkss = /* GraphQL */ `
  query SearchGoodLinkss(
    $filter: SearchableGoodLinksFilterInput
    $sort: SearchableGoodLinksSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchGoodLinkss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        clientId
        custFirstName
        custLastName
        custEmail
        custMobile
        custState
        custLoanAmount
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchRequestss = /* GraphQL */ `
  query SearchRequestss(
    $filter: SearchableRequestsFilterInput
    $sort: SearchableRequestsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRequestss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
