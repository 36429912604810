import React, { useState, useEffect } from "react";
import { Card, DataList, AlertInline } from '@loanpal/lumos__core';
import { Box, Text, Grid } from 'theme-ui';
import { Divider } from "@aws-amplify/ui-react";
import { styles } from "../styles";
import { numberToMoney, phone } from "../../../utils/format";
import { loanStatusPanel } from "../loan-status-panel";
import { getCategoryLogo, getCategoryName } from "../../../utils/glodbals"
import { getOfferByLoanId } from "../../../utils/api";

const DisplayLoan = ({ loan, loanStatus, loanAmount, consumerDisplay, conditional, hasCoborrower }) => {
    const [offer, setOffer] = useState();

    useEffect(() => {
        if (loan) {
            getOffer(loan.id);
        }
        // eslint-disable-next-line
    }, []);

    const getOffer = async (loanId) => {
        try {
        // get offer by loan Id
        const offer = await getOfferByLoanId(loan.partnerId, loanId);
        if (offer) {
            setOffer(offer[0].offer);
        }
        } catch (err) {
        console.log("error getting offer", err);
        return false;
        }
    }

    const loanOfferDetails = [
        { label: 'Loan Status', text: <span style={styles.bold}>{loan.status}</span>},
        { label: 'Max loan amount', text: <span style={styles.bold}>{numberToMoney(loan.maxAmount.value)}</span>},
        {
        label: 'Term and rate',
        text: offer ? <span style={styles.bold}>{offer.name}</span> : "",
        },
        {
        label: '',
        text: offer ? offer.productDescription : "",
        }
    ];

    let borrowerDetails = [
        { label: 'Borrower Name', text: <span style={styles.bold}>{loan.borrower.firstName} {loan.borrower.lastName}</span>},
        { label: 'Street Address', text: <span style={styles.bold}>{loan.borrower.address.street}</span>},
        {
        label: 'Phone',
        text: loan.borrower.phones.length > 0 ? <span style={styles.bold}>{phone(loan.borrower.phones[0].value)}</span> : "",
        }
    ];

    borrowerDetails = !hasCoborrower || hasCoborrower === "no" ? borrowerDetails : [
        ...borrowerDetails.slice(0, 1),
        { label: 'Coborrower Name', text: <span style={styles.bold}>{loan.coBorrower.firstName} {loan.coBorrower.lastName}</span>},
        ...borrowerDetails.slice(1, borrowerDetails.length)
    ]

    return (
        <Card
        header={{
            title: (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={getCategoryLogo(loan.installationCategoryId)} alt={getCategoryName(loan.installationCategoryId)} style={styles.catLogo} />
                {getCategoryName(loan.installationCategoryId)} loan id  &nbsp; <span style={styles.thin}>{loan.id}</span>&nbsp; details
            </Box>
            ),
        }}
        >
        {conditional && (
        <AlertInline
            sx={{ my: 3 }}
            variant="info"
            >
            Additional review is required, and we will provide a decision shortly by e-mail
        </AlertInline>
        )}
        <Box
        sx={{
            pt: 3,
            mt: 3,
            borderTop: "1px solid",
            borderTopColor: "neutral.l3",
            }}>
            { consumerDisplay && (
            <Grid columns={[1, 1, '1.5fr 2fr']}>
            <Box
                sx={{
                borderRight: '1px solid',
                borderColor: ['transparent', 'transparent', 'neutral.l3'],
                mb: 3,
                }}
            >
                <DataList columns={1} items={borrowerDetails} />
                <Divider
                    orientation="horizontal"
                    style={styles.divider10}
                    size="small"
                />
                <div>Loan amount</div>
                <Text variant="h3" as="h2">
                {numberToMoney(loanAmount)}
                </Text>
            </Box>
            <Box
                sx={{
                borderRight: '1px solid',
                borderColor: ['transparent', 'transparent', 'neutral.l3'],
                mb: 3,
                pr: 2,
                }}
            >
                <DataList columns={1} items={loanOfferDetails} />
            </Box>
            </Grid>
            )}
            { !consumerDisplay && (
            <Grid columns={[1, 1, '1fr 2fr 2fr']}>
            <Box
                sx={{
                borderRight: '1px solid',
                borderColor: ['transparent', 'transparent', 'neutral.l3'],
                mb: 3,
                }}
            >
                <DataList columns={1} items={borrowerDetails} />
                <Divider
                    orientation="horizontal"
                    style={styles.divider10}
                    size="small"
                />
                <div>Loan amount</div>
                <Text variant="h3" as="h2">
                {numberToMoney(loanAmount)}
                </Text>
            </Box>
            <Box
                sx={{
                borderRight: '1px solid',
                borderColor: ['transparent', 'transparent', 'neutral.l3'],
                mb: 3,
                pr: 2,
                }}
            >
                <DataList columns={1} items={loanOfferDetails} />
            </Box>
             <Box sx={{ mb: 3 }}>
                { loanStatus && loanStatusPanel(loanStatus) }
            </Box>
            </Grid>
            )}
        </Box>
    </Card>
    )
}

export default DisplayLoan;