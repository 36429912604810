import React, { useState, useEffect } from "react";
import { Box, Image } from "theme-ui";
import Loading from "../../../utils/loading";
import { Divider } from "@aws-amplify/ui-react";
import { DisplayBundleDetails, DisplayLoan } from "../../application";
import {
        useNotify,
         Heading,
         Icon
        } from '@loanpal/lumos__core';
import { getLoanById } from "../../../utils/api";
import logoGLP from '../../../images/glplus_grey_gradient.svg';
import { numberToMoney } from "../../../utils/format";
import { styles } from "../styles";

const Approved = ({ state, send }) => {
    const [loanDetails, setLoanDetails] = useState();
    const [solarLoanDetails, setSolarLoanDetails] = useState();
    const [decision] = useState(state.context.applicationResponse);
    const [sharedApp] = useState(state.context.sharedApp);
    const [solarLoan] = useState(state.context.solarLoan);
    const [selectedBundleAmount] = useState(state.context.selectedBundleAmount);
    const [loading, setLoading] = useState(true);
    const [hasCoborrower] = useState(state.context.hasCoborrower)
const { addSnack } = useNotify();

    useEffect(() => {
      if (decision && sharedApp) {
        getLoan();
      }
    // eslint-disable-next-line
    }, []);

    const getLoan = async () => {
      try {
        setLoading(true);
        await getLoanById(sharedApp.clientId, decision.id).then(loan => {
          if (loan) {
            setLoanDetails(loan);
          }
        });
        await getLoanById(solarLoan.partnerId, solarLoan.id).then(loan => {
          if (loan) {
            setSolarLoanDetails(loan);
          }
        });
      } catch (err) {
        console.log("Error getting underlying loan", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    return (
      <>
          {loading && <Loading />}
          {loanDetails && solarLoanDetails && (
          <>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pl: 2, pr: 2, mb: 3, fontSize: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Icon
                  variant="success"
                  size="xl"
                  color="green"
                  sx={{
                    mr: 3,
                  }}
              />
              <Heading variant="h5" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '26px' }}>
                Congratulations {loanDetails.borrower.firstName}!<br/>
                Your Home Improvement Interim loan was approved for up to {numberToMoney(loanDetails.maxAmount.value)}
             </Heading>
            </Box>
            <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '150px', margin: '10px' }} />
          </Box>
          <Box>
            <DisplayBundleDetails hiLoan={loanDetails} solarLoan={solarLoanDetails} bundleTag={loanDetails.tags[0]}  addSnack={addSnack} consumerDisplay={true} nextSteps={true}  />
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <DisplayLoan loan={solarLoanDetails}  loanStatus={null} loanAmount={selectedBundleAmount ? selectedBundleAmount : solarLoanDetails.amount.value} consumerDisplay={true} hasCoborrower={hasCoborrower} />
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <DisplayLoan loan={loanDetails}  loanStatus={null} loanAmount={loanDetails.amount.value} consumerDisplay={true} hasCoborrower={hasCoborrower} />
          </Box>
          </>
        )}
      </>
    )
}

export default Approved;