import React, { Fragment } from "react"

export const Description = ({ value, emptyState = "None selected" }) => (
  <dd style={{ fontStyle: value ? "normal" : "italic", marginLeft: 0, marginBottom: 10, marginInlineStart: 0 }}>
    {value || emptyState}
  </dd>
)

export const Term = ({ title }) => (
  <dt
    style={{
      color: "neutral.base",
      fontSize: "80%",
      fontWeight: 500,
      paddingBottom: 1
    }}
  >
    {title}
  </dt>
)

export const DefinitionList = ({ description }) => (
  <dl style={{ paddingTop: 0, paddingBottom: 0, "& > dd:not(:lastOfType)": { marginBottom: 3 } }}>
    {description.map(({ title, ...rest }) => (
      <Fragment key={title}>
        <Term title={title} />
        <Description {...rest} />
      </Fragment>
    ))}
  </dl>
)