import * as React from "react";
import "./App.css";
import Views from "./views";

export default function App() {
  return (
    <div style={styles.main}>
      <Views />
    </div>
  );
}

const styles = {
  main: {
    backgroundColor: '#f5f5f5',
     minHeight: '100vh',
  }
};
