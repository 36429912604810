import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeProvider, { GoodLeap } from "@loanpal/lumos__theme";
import { NotifyProvider } from '@loanpal/lumos__core';
Amplify.configure(awsExports);

const Main = ({ children }) => {
  return (
    <ThemeProvider theme={GoodLeap}>{children}</ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
      <Main>
        <NotifyProvider>
         <App  />
        </NotifyProvider>
      </Main>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
