import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { reqsBySalesforceId } from "../../graphql/queries";
import { createRequests, updateRequests } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { Box } from "theme-ui";
import {
  Heading,
  useNotify,
  Card as LumosCard,
  Button as LumosButton,
} from "@loanpal/lumos__core";
import {
  View,
  Flex,
  Card as ReactCard,
  TextField,
  SelectField,
  TextAreaField,
  Divider,
  Text
} from "@aws-amplify/ui-react";
import { Categories } from "../../utils/categories";
import logo from "../../images/goodleap-white-orange.svg";
import Loading from "../../utils/loading";
import { getRequests } from "../../graphql/queries";
import Users from "../request/users";
import Offers from "../request/offers";
import {
  phoneDigits,
  dateTimeStr,
  moneyStringToNumber
} from "../../utils/format";
import {
  email as validateEmail
} from "../../utils/formValidation";

const initialState = {
  id: "",
  clientId: "",
  status: "WAITINGONAPIKEYS",
  accountName: "",
  salesforce: "",
  salesforceId: "",
  category: "",
  offer: "",
  loanAmount: 0.0,
  referenceId: "quickLink",
  channel: "GoodLink",
  formUrl: "",
  submittingUserFname: "",
  submittingUserLname: "",
  submittingUserEmail: "",
  submittingUserPhone: "",
  submittingUserRole: "",
  submittingUserPassword: "",
  contactEmail: "",
  partnerEmail: "",
  username: "",
  password: "",
  apiKeys: false,
  updateKeys: false,
  notes: "",
  requestNotes: ""
};

const Home = () => {
// Change environment dev/prod accordingly
const [env] = useState("prod");
const [formState, setFormState] = useState(initialState);
const [spinner, setSpinner] = useState(false);
const [salesforceErrorFlag, setSalesforceErrorFlag] = useState(false);
const [salesforceError, setSalesforceError] = useState("");
const [requesterError, setRequesterError] = useState(false);
const [partnerError, setPartnerError] = useState(false);
const [disableSendRequestButton, setDisableSendRequestButton] = useState(true);
const [usersItems, setUsersItems] = useState();
const [users, setUsers] = useState();
const [offers, setOffers] = useState();
const [offerItems, setOfferItems] = useState(null);
const [categories, setCategories] = useState();
const [sUser, setUser] = useState();
const { addSnack } = useNotify();
const { id } = useParams();
let navigate = useNavigate();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const emailRequest = useQuery().get("emailRequest");

const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value });
}

useEffect(() => {
  if (id !== 'new') {
    fetchRequest(id);
  } else if (id === 'new') {
    clearForm();
  }
  // eslint-disable-next-line
}, [id]);

useEffect(() => {
  if (emailRequest && emailRequest === 'no-email') {
    setFormState({...formState, status: "NEW"})
  }
  // eslint-disable-next-line
}, [emailRequest]);

useEffect(() => {
  if (id === 'new') {
    if (validateRequestIsReady()) {
      setDisableSendRequestButton(false);
    } else {
      setDisableSendRequestButton(true);
    }
  }
  // eslint-disable-next-line
}, [formState, requesterError, partnerError, salesforceErrorFlag]);

useEffect(() => {
  if (formState && formState.apiKeys && formState.clientId) {
    getOffersByCategory(formState.clientId);
    getUsers(formState.clientId);
  }
  // eslint-disable-next-line
}, [formState.apiKeys]);

const validateRequestIsReady = () => {
  if (!salesforceErrorFlag &&
    !requesterError &&
    !partnerError && formState.accountName !== "" && formState.salesforceId !== "" && formState.category !== "") {
      return true;
    } else {
      return false
    }
}

const getUsers = async (clientId) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get users
      await API.get("developerApi", "/users", myInit0)
      .then(async (response) => {
          if (response.data.length > 0) {
            setUsers(response.data);
            let usersArrayOptions = [];
            usersArrayOptions = response.data
              .map((element) => [
                ...usersArrayOptions,
                { name: `${element.email}`, value: element.id },
              ])
              .flat();
            setUsersItems(usersArrayOptions);
          }
      },
      (err) => {
        console.log("Error getting users", err);
      })

    } catch (err) {
      console.log("error getting users", err);
      return false;
    }
  }


const setInputUser = (value) => {
  const user = users.find(element => element.id === value);
  if (user && user.email !== formState.submittingUserEmail) {
    setUser(user);
    setFormState({...formState, submittingUserEmail: user.email, submittingUserFname: user.firstName, submittingUserLname: user.lastName, submittingUserPhone: phoneDigits(user.phoneNumber.number) })
  }
}

const categoryName = (cat) => {
  if (cat === "HOME_IMPROVEMENT") return "HOME IMPROVEMENT";
  else if (cat === "SOLAR") return "SOLAR";
  else if (cat === "BATTERY") return "BATTERY";
  else if (cat === "ROOF") return  "ROOFING / WINDOWS / DOORS / SIDING / GEOTHERMAL / RESTORATION";
}

const categoryCode = (cat) => {
  if (cat === "HOME IMPROVEMENT") return "HOME_IMPROVEMENT";
  else if (cat === "SOLAR") return "SOLAR";
  else if (cat === "BATTERY") return "BATTERY";
  else if (cat === "ROOFING / WINDOWS / DOORS / SIDING / GEOTHERMAL / RESTORATION") return  "ROOF";
}

const getOffersByCategory = async (clientId) => {
    try {
      setSpinner(true);
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get categoryId
      await API.get("developerApi", "/categories", myInit0)
      .then(async (response) => {
          if (response.length > 0) {
              setCategories(response);
              const cat = response.find(el => el.name === categoryName(formState.category));
            const myInit1 = {
              queryStringParameters: {
                apiId: `${clientId}ApiId`,
                apiKey: `${clientId}ApiKey`,
                clientId: clientId,
                categoryId: cat.id,
                env: env
              },
            };

            // get offersByCategory
            await API.get("developerApi", "/offersByCategory", myInit1)
            .then((response) => {
              if (response.data.length > 0) {

               setOffers(response.data);
               let offersArrayOptions = [];
               offersArrayOptions = response.data
                  .map((element) => [
                    ...offersArrayOptions,
                    { name: element.productCode.includes("DEFERRED") ? `${element.productName} - ${element.name}` : `${element.name} - ${element.productName}`, value: element.id },
                  ])
                  .flat();
               setOfferItems(offersArrayOptions);
              }
            })
          }
        setSpinner(false);
      },
      (err) => {
        addSnack({ variant: 'danger', message: `Error getting offers!` });
        console.log("Error getting offers", err);
        setSpinner(false);
      })

    } catch (err) {
      addSnack({ variant: 'danger', message: `Error getting offers, check that Category is correct!` });
      console.log("Error getting offers", err);
      setSpinner(false);
      return false;
    }
  };

async function fetchRequest(id) {
  if (!id && id === "") return;
  try {
    const reqData = await API.graphql(
      graphqlOperation(getRequests, { id: id })
    );
    const req = reqData.data.getRequests;
    if (req) {
      loadRequest(req);
    }
  } catch (err) {
    console.log("error fetching Request", err);
  }
}

  const loadRequest = (req) => {
    const reqState = {
      id: req.id,
      clientId: req.clientId,
      status: req.status,
      accountName: req.accountName,
      salesforce: req.salesforce,
      salesforceId: req.salesforceId,
      offer: req.offer,
      loanAmount: req.loanAmount,
      channel: req.channel,
      category: req.category,
      sendEmail: req.sendEmail,
      referenceId: req.referenceId,
      submittingUserFname: req.submittingUser.firstName,
      submittingUserLname: req.submittingUser.lastName,
      submittingUserEmail: req.submittingUser.email,
      submittingUserPhone: req.submittingUser.phone,
      submittingUserPassword: "",
      contactEmail: req.contactEmail,
      partnerEmail: req.partnerEmail ? req.partnerEmail : "",
      username: "",
      password: "",
      updateKeys: false,
      apiKeys: req.apiKeys,
      notes: req.notes,
      requestNotes: req.requestNotes
    };
    setFormState(reqState);
  };

const getStringBetween = (str, start, end) => {
  try {
    const result = str.match(new RegExp(start + "(.*)" + end));
    return result[1];
  } catch(e) {
    console.log("ERROR");
    return "";
  }
}

const checkRequestByCateogry = (reqs, category) =>{
  const exists = reqs.find(r => r.category === category);
  return exists;
}

const  checkRequestExists = async (url, category) => {
    const reqData = await API.graphql(
        graphqlOperation(reqsBySalesforceId, { salesforceId: formState.salesforceId } )
      );
    if (reqData.data.reqsBySalesforceId.items.length > 0) {
      const reqs = reqData.data.reqsBySalesforceId.items;
      console.log(reqs);
      const found = checkRequestByCateogry(reqs, category);
      console.log(found);
      if (found !== undefined) {
        setSalesforceErrorFlag(true);
        setSalesforceError("Request already exist for this account");
        return true;
      } else {
        setSalesforceErrorFlag(false);
        setSalesforceError("");
        return false;
      }
    } else {
      setSalesforceErrorFlag(false);
      setSalesforceError("");
      return false;
    }
  }

  const validateSimpleRequest = async (formState) => {
    let error = "";
    if (!formState.contactEmail) error += " [Requester Email]";
    if (!formState.accountName) error += " [Account SFDC Name]";
    if (!formState.salesforce) error += " [SDFC Link]";
    if (!formState.category) error += " [Category]";
    if (!formState.clientId) error += " [Client ID]";
    if (error !== "") {
      addSnack({ variant: 'danger', message: `Please enter ${error}` });
      setSpinner(false);
      return false;
    }
    if (formState.salesforce && formState.category) {
      const exists = await checkRequestExists(formState.salesforce, formState.category);
      console.log("exists", exists, !!exists);
      if (!!exists) {
          addSnack({ variant: 'danger', message: `Request already exist for this account and category!` });
          setSpinner(false);
          return false;
      }
    }
    return true;
  }

  const validateRequest = async (formState) => {
    let error = "";
      console.log(formState);
      if (!formState.accountName) error += " [Account Name]";
      if (!formState.contactEmail) error += " [Requester Email]";
      if (!formState.partnerEmail) error += " [Partner Email]";
      if (!formState.salesforce) error += " [SDFC Link]";
      if (!formState.category) error += " [Category]";
      if (!formState.clientId) error += " [Client ID]";
      if (!formState.offer) error += " [Offers and Save them]";
      if (formState.loanAmount === 0) error += " [Loan Amount]";
      if (!formState.submittingUserEmail) error += " [Submitting User Email]";
      if (!formState.submittingUserFname) error += " [Submitting User First Name]";
      if (!formState.submittingUserLname) error += " [Submitting User Last Name]";
      if (!formState.submittingUserPhone) error += " [Submitting User Phone]";
      if (error !== "") {
        addSnack({ variant: 'danger', message: `Please enter ${error}` });
        setSpinner(false);
        return false;
      }
      return true;
  }

  const addASnack = (status, message) => {
    if (status === "success") {
      addSnack({ variant: 'success', message: `${message} saved!` });
    } else {
      addSnack({ variant: 'danger', message: `Error saving ${message}!` });
    }
  }

const updateNotesRequest = async (message) => {
    let note = formState.notes;
    if (note !== null && note.trim() !== "") note = note.concat("\n");
    setFormState({ ...formState, notes: note.concat(dateTimeStr(Date.now())).concat(" - " + message) });
  }

  async function addRequest() {
    try {
        setSpinner(true);
        let createIt = false;
        if (id !== "new") {
          createIt = await validateRequest(formState);
        } else {
          createIt = await validateSimpleRequest(formState);
        }
          if (createIt) {
            if (id === "new") {
              updateNotesRequest("API keys requested to CFAppSupport");
            }
            const app = buildApp(formState, formState.id !== "");
            if (formState.id !== "") {
              await API.graphql(graphqlOperation(updateRequests, { input: app }));
              sendEmailToAdmin();
              addSnack({ variant: 'success', message: 'Request saved successfully!' });
            } else {
              await API.graphql(
                graphqlOperation(createRequests, { input: app })
              );
              addSnack({ variant: 'success', message: 'Request created successfully!' });
            }
            clearForm();
            navigate(`../request-quicklink/new`, { replace: false });
          }
    } catch (err) {
      console.log("Error saving request:", err);
      addSnack({ variant: 'danger', message: 'Error saving Request' });
    }
    setSpinner(false);
  }

  const sendEmailToAdmin = async () => {
  try {
    if (formState.apiKeys && formState.id !== "" && formState.contactEmail !== "" && formState.category !== "" && formState.accountName !== "") {
      const myParams = {
        queryStringParameters: {
          accountName: formState.accountName,
          id: formState.id,
          category: formState.category,
          contactEmail: formState.contactEmail,
          reason: formState.status === "READYFORREQUESTER" ? "Request is ready for QuickLink:" : "Request to update QuickLink:",
          env: env
        },
      };
      console.log("send email", myParams);
      await API.post("developerApi", "/requestReadyForQuickLinkEmail", myParams);
    }
  } catch (err) {
    console.log("Error Sending Email:", err);
  }
}

  const buildApp = (app, update) => {
    if (update)
      return {
        id: app.id !== "" ? app.id : "",
        clientId: app.clientId,
        status: app.status === "READYFORREQUESTER" || app.status === "NEWPLUS" ? "NEWPLUS" : "UPDATEQUICKLINK",
        accountName: app.accountName,
        salesforce: app.salesforce,
        salesforceId: app.salesforceId,
        offer: app.offer,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        submittingUser: {
          firstName: app.submittingUserFname,
          lastName: app.submittingUserLname,
          email: app.submittingUserEmail,
          phone: app.submittingUserPhone,
        },
        sendEmail: app.sendEmail,
        referenceId: app.referenceId,
        contactEmail: app.contactEmail,
        partnerEmail: app.partnerEmail,
        apiKeys: app.apiKeys,
        notes: app.notes,
        requestNotes: app.requestNotes
      };
    else
      return {
        clientId: app.clientId,
        status: app.status,
        accountName: app.accountName,
        salesforce: app.salesforce,
        salesforceId: app.salesforceId,
        offer: app.offer,
        offersRequested: app.offersRequested,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        submittingUser: {
          firstName: app.submittingUserFname,
          lastName: app.submittingUserLname,
          email: app.submittingUserEmail,
          phone: app.submittingUserPhone,
        },
        sendEmail: app.sendEmail,
        referenceId: app.referenceId,
        contactEmail: app.contactEmail,
        partnerEmail: app.partnerEmail,
        apiKeys: app.apiKeys,
        notes: app.notes,
        requestNotes: app.requestNotes
      };
  };

const clearForm = () => {
    setFormState(initialState);
  }

 return (
    <>
    {spinner && <Loading />}
    <Box
          sx={{
            maxWidth: "100%",
            backgroundColor: "#ffffff",
            mx: "auto",
            minHeight: '100vh'
          }}
        >
          <Box>
            <Heading
              className="heading-box"
              sx={{
                width: "960px",
                margin: "0 auto",
                backgroundColor: "#003439",
              }}
            >
              <img src={logo} alt="GoodLeap" style={styles.logo} />
            </Heading>
          </Box>
          <div style={styles.container}>
            <Heading mb={3} ml={1} variant="h3">
              <View>QuickLink Request</View>
              {formState && formState.id !== "" && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS"  && (
                <Text style={styles.subTitle}>To request changes please use the Notes field at the bottom of the form</Text>
              )}

            </Heading>
            <ReactCard>
              <TextField
                label={<Text>
                    Your Email - Who is requesting this QuickLink?
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                onChange={(event) =>
                  setInput("contactEmail", event.target.value)
                }
                 onBlurCapture={({target}) => {
                  if (!validateEmail(target.value)) {
                    setRequesterError(true);
                  } else {
                    setRequesterError(false);
                  }
                }}
                size="small"
                width="50%"
                style={styles.input}
                value={formState.contactEmail}
                hasError={requesterError}
                errorMessage="Please enter a valid email address"
                isDisabled={formState.status !== "NEW" && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" && formState.status !== "WAITINGONAPIKEYS"}
              />
           </ReactCard>
           <ReactCard>
              <TextField
                label={id === 'new' ? (<Text>
                    Partner Email - Who is the contact on the partner's side?
                    </Text>) : (<Text>
                      Partner Email - Who is the contact on the partner's side?
                      <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                      (required)
                    </Text>
                    </Text>)}
                onChange={(event) =>
                  setInput("partnerEmail", event.target.value)
                }
                onBlurCapture={({target}) => {
                  if (!validateEmail(target.value)) {
                    setPartnerError(true);
                  } else {
                    setPartnerError(false);
                  }
                }}
                size="small"
                width="50%"
                style={styles.input}
                value={formState.partnerEmail}
                hasError={partnerError}
                errorMessage="Please enter a valid email address"
                isDisabled={formState.status !== "NEW" && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" && formState.status !== "WAITINGONAPIKEYS"}
              />
           </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput("accountName", event.target.value)}
                size="small"
                 width="75%"
                value={formState.accountName}
                label={<Text>
                    Requesting Partner Name
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                style={styles.input}
                descriptiveText={"Please copy/paste exact name as in SDFC Account Name Field"}
                isDisabled={formState.status !== "NEW" && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" && formState.status !== "WAITINGONAPIKEYS" }
              />
            </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput("salesforce", event.target.value)}
                size="small"
                 width="75%"
                value={formState.salesforce}
                label={<Text>
                    Requesting Partner SFDC link
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                descriptiveText="e.g. https://goodleap.lightning.force.com/lightning/r/Account/0015G000023BS4VQAW/view"
                style={styles.input}
                onBlurCapture={({target}) => {
                  if (target.value !== "") {
                    const salesforceId = getStringBetween(target.value, "Account/", "/view" );
                    console.log("salesforceId", salesforceId);
                    if (salesforceId !== "") {
                      setFormState({ ...formState, salesforceId: salesforceId });
                      setSalesforceErrorFlag(false);
                      setSalesforceError("");
                    } else {
                      setSalesforceErrorFlag(true);
                      setSalesforceError("This is not a valid SFDC link");
                    }
                  }
                }}
                hasError = {salesforceErrorFlag}
                errorMessage={salesforceError}
                isDisabled={formState.status !== "NEW" && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" && formState.status !== "WAITINGONAPIKEYS" }
              />
            </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput("clientId", event.target.value)}
                size="small"
                 width="75%"
                value={formState.clientId}
                label={<Text>
                    Requesting Partner Client ID
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                style={styles.input}
                descriptiveText={"This is the client ID from Dynamo DB, you can find it in Bridge"}
                isDisabled={formState.status !== "NEW" && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" && formState.status !== "WAITINGONAPIKEYS" }
              />
            </ReactCard>
            {id === 'new' && (
            <ReactCard>
              <SelectField
                style={styles.input}
                label={<Text>
                    What Category is this QuickLink for?
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                value={formState.category}
                onChange={(e) => setInput("category", e.target.value)}
                size="small"
                width="50%"
              >
                <option value="">Please select a Category</option>
                <option value={Categories.HOME_IMPROVEMENT.cat}>{Categories.HOME_IMPROVEMENT.cat}</option>
                <option value={Categories.ROOF.cat}>{Categories.ROOF.cat}</option>
                <option value={Categories.SOLAR.cat}>{Categories.SOLAR.cat}</option>
                <option value={Categories.BATTERY.cat}>{Categories.BATTERY.cat}</option>
              </SelectField>
            </ReactCard>
            )}
            {id !== 'new' &&
            (<>
            <ReactCard>
              <SelectField
                style={styles.input}
                 label={<Text>
                    What Category is this QuickLink for?
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                value={formState.category}
                onChange={(e) => setInput("category", e.target.value)}
                size="small"
                width="50%"
                isDisabled={formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" }
              >
                <option value="">Please select a Category</option>
                {categories ? (
                  categories.map(cat =>
                    <option key={cat.id} value={categoryCode(cat.name)}>{categoryCode(cat.name)}</option>
                  )
                ) : (
                <>
                <option value={Categories.HOME_IMPROVEMENT.cat}>{Categories.HOME_IMPROVEMENT.cat}</option>
                <option value={Categories.ROOF.cat}>{Categories.ROOF.cat}</option>
                <option value={Categories.SOLAR.cat}>{Categories.SOLAR.cat}</option>
                <option value={Categories.BATTERY.cat}>{Categories.BATTERY.cat}</option>
                </>
                )}
              </SelectField>
            </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput("loanAmount", event.target.value)}
                onBlurCapture={({target}) => {
                  setInput("loanAmount", moneyStringToNumber(target.value));
                }}
                size="small"
                width="50%"
                style={styles.input}
                descriptiveText="Two point precision decimal."
                value={formState.loanAmount}
                isDisabled={formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" }
                label={<Text>
                    Average loan amount financed
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
              />
            </ReactCard>
            <ReactCard>
              <Offers offersArray={formState.offer} offerItems={offerItems} active={offers} setInput={setInput} addSnack={addASnack} readOnly={!!formState.status && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" } />
            </ReactCard>
            <LumosCard
                header={{
                  title: "Submitting User",
                }}
                sx={{ marginTop: 20, marginBottom: 20, width: "75%" }}
              >
              <ReactCard>
                <Text style={styles.Title2}>Select from the list of existing users below or add a new one.</Text>
              </ReactCard>
              <ReactCard>
                <Users usersItems={usersItems} setInput={setInputUser} sUser={sUser} users={users} readOnly={!!formState.status && formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" } />
              </ReactCard>
              <ReactCard>
                <Flex gap="1rem" direction="column">
                  <TextField
                    label={<Text>
                    Submitting User Email
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                    onChange={(event) => setInput("submittingUserEmail", event.target.value.toLowerCase())}
                    size="small"
                    value={formState.submittingUserEmail}
                    style={styles.input}
                    isDisabled={formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" }
                  />
                </Flex>
              </ReactCard>
              <ReactCard>
                <Flex direction="row" alignItems="flex-start">
                  <TextField
                    label={<Text>
                    Submitting User First Name
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                    onChange={(event) =>
                      setInput("submittingUserFname", event.target.value)
                    }
                    size="small"
                    width="50%"
                    value={formState.submittingUserFname}
                    style={styles.input}
                    isDisabled={formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" }
                  />
                  <TextField
                    label={<Text>
                    Submitting User Last Name
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                    onChange={(event) =>
                      setInput("submittingUserLname", event.target.value)
                    }
                    size="small"
                    width="50%"
                    style={styles.input}
                    value={formState.submittingUserLname}
                    isDisabled={formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" }
                  />
                </Flex>
              </ReactCard>
              <ReactCard>
                <TextField
                  label={<Text>
                    Submitting User Phone
                    <Text as="span" fontSize="0.8rem" color="#660000">
                        {' '}
                    (required)
                    </Text>
                    </Text>}
                  onChange={(event) =>
                    setInput("submittingUserPhone", event.target.value)
                  }
                  size="small"
                  width="50%"
                  descriptiveText="10 digits, no other characters"
                  style={styles.input}
                  value={formState.submittingUserPhone}
                  isDisabled={formState.status !== "READYFORREQUESTER" && formState.status !== "NEWPLUS" }
                />
              </ReactCard>
            </LumosCard>
            <ReactCard>
              <TextAreaField
                style={styles.input}
                label="Notes"
                size="default"
                onChange={(event) => setInput("requestNotes", event.target.value)}
                rows="3"
                direction="column"
                value={formState.requestNotes}
                descriptiveText={"Use this section to add special instructions or requests"}
              />
            </ReactCard>
            </>)}
             <Divider
              orientation="horizontal"
              style={styles.divider}
              size="small"
            />
            {id === 'new' && (
              <LumosButton
                  sx={{ margin: "20px 10px 40px 10px", padding: "10px", width: "130px", height: "40px" }}
                  onClick={addRequest}
                  disabled={disableSendRequestButton}
                >
                Send request
              </LumosButton>
            )}
            {id !== 'new' && (
              <LumosButton
                  sx={{ margin: "20px 10px 40px 10px", padding: "10px", height: "40px" }}
                  onClick={addRequest}
                >
                Send request
              </LumosButton>
            )}
          </div>
    </Box>
    </>
 );
};

 const styles = {
  requiredInput: {
    borderColor: "#000000",
    padding: 8,
    fontSize: 16
  },
  input: {
    // marginBottom: 20,
    padding: 8,
    fontSize: 16
  },
  divider: { margin: "5px 0", color: "white", borderColor: "white" },
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px 20px"
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: "200"
  },
  Title2: {
    fontSize: "16px",
    fontWeight: "300"
  },
  logo: {
    width: 250,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
  },
  heading: {
    backgroundColor: "#003439",
  }
};

export default Home;