import { Grid, Box } from "theme-ui"
import { Loader } from '@aws-amplify/ui-react';
import { styles } from "./styles";

const ProgressSteps = ({ steps, sx = {}, ...props }) => {
  const stepsCompleted = steps.reduce(
    (acc, curr) => (curr.complete ? acc + 1 : acc),
    0
  );
  const progress = (stepsCompleted / steps.length) * 100;
  const stepInfo = `(Step ${stepsCompleted} of ${steps.length})`;

  return (
    <div sx={sx} {...props} style={styles.progressBar}>
      <Loader percentage={progress} variation="linear" isDeterminate isPercentageTextHidden emptyColor="#ffffff" filledColor="#F58320" sx={{height: '10rem' }} />
      <Box
        sx={{
          variant: 'text.small',
          display: 'block',
          mt: 1,
          color: 'neutral.d1',
          fontWeight: 500,
          '@media (min-width: 1200px)': {
            display: 'none',
          },
        }}
      >
        {stepInfo}
      </Box>
      <Grid
        columns={steps.length}
        sx={{
          mt: 0,
          fontWeight: 500,
          fontSize: '0.75rem',
          color: '#707070',
          "@media (max-width: 1201px)": {
            display: "none"
          }
        }}
      >
        {steps.map(({ key, title }, i) => (
          <div
            key={key}
            sx={{
              variant: "text.small",
              color: stepsCompleted === i ? "neutral.d1" : "neutral.l1"
            }}
          >
            {title}
          </div>
        ))}
      </Grid>
    </div>
  )
}

export default ProgressSteps
