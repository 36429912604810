import React from "react";
import { Box, Flex } from 'theme-ui';
import logo from "../../images/goodleap-white-orange.svg";
import { useNavigate, useLocation } from "react-router-dom";

const NavigationBar = ({ children }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const navLinks = [{
    to: '/partners',
    text: 'Partners'
  }, {
    to: '/requests',
    text: 'Requests'
  }, {
    to: '/Links',
    text: 'QuickLinks History'
  }
  ];

  const handleLink = (to) => {
    navigate(`../${to}`, { replace: false });
  }

  const isCurrentPath = (path) => {
    if (path === '/' && pathname === '/') return true;
    if (path !== '/') return pathname.includes(path);
    return false;
  };

  return (
    <>
      {/* <NavBar logo='goodleap' currentPath={'location.pathname'} handleLink={'history.push'} style={{ zIndex: 1, position: 'relative' }} navLinks={navLinks} > */}
      <Box
        as="nav"
        aria-label="Main"
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 56,
          width: '100%',
          mx: 'auto',
          backgroundColor: '#003439'
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              mr: [3, 4],
              bg: 'transparent',
              p: 0,
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <h1 sx={{ m: 0, p: 0 }}>
              <img src={logo} alt="GoodLeap" style={styles.logo} />
            </h1>
          </Box>
          {navLinks?.map(({ to, text, hiddenDesktop }) => {
            if (hiddenDesktop) return null;
            return (
              <Box
                as="button"
                variant="text.base"
                key={`${to}${text}`}
                onClick={() => handleLink(to)}
                sx={{
                  color: 'text.negative',
                  border: 'none',
                  bg: 'transparent',
                  borderBottom: '2px solid',
                  borderColor: isCurrentPath(to) ? 'logo.icon' : 'transparent',
                  textDecoration: 'none',
                  fontWeight: 600,
                  py: '2px',
                  cursor: 'pointer',
                  mr: 3,
                  ':hover': {
                    textDecoration: 'none',
                    borderColor: 'secondary.l2',
                  },
                }}
              >
                {text}
              </Box>
            );
          })}
        </Flex>
        <Box sx={{ ml: 'auto', color: 'text.negative' }}>{children}</Box>
      </Box>
    </>
  )
}

const styles = {
  signOut: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#FF8300",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px",
  },
  logo: {
    width: 160,
    margin: "0 auto",
    display: "block",
    padding: 20,
  }
};

export default NavigationBar;
