export let SubjectPropertyType
;(function(SubjectPropertyType) {
  SubjectPropertyType["MANUFACTURED_HOME"] = "MANUFACTURED_HOME"
  SubjectPropertyType["SITE_BUILT_HOME"] = "SITE_BUILT_HOME"
})(SubjectPropertyType || (SubjectPropertyType = {}))

export let HomeOccupationEnum
;(function(HomeOccupationEnum) {
  HomeOccupationEnum["INVESTMENT"] = "INVESTMENT"
  HomeOccupationEnum["OTHER"] = "OTHER"
  HomeOccupationEnum["PRIMARY"] = "PRIMARY"
  HomeOccupationEnum["SECONDARY"] = "SECONDARY"
})(HomeOccupationEnum || (HomeOccupationEnum = {}))

export let HomeOwnershipEnum
;(function(HomeOwnershipEnum) {
  HomeOwnershipEnum["NOT_OWNED"] = "NOT_OWNED"
  HomeOwnershipEnum["OWNED_FREE_AND_CLEAR"] = "OWNED_FREE_AND_CLEAR"
  HomeOwnershipEnum["OWNED_WITH_MORTGAGE"] = "OWNED_WITH_MORTGAGE"
})(HomeOwnershipEnum || (HomeOwnershipEnum = {}))

export let CitizenshipStatus
;(function(CitizenshipStatus) {
  CitizenshipStatus["LAWFUL_PERMANENT_RESIDENT_ALIEN"] =
    "LAWFUL_PERMANENT_RESIDENT_ALIEN"
  CitizenshipStatus["OTHER"] = "OTHER"
  CitizenshipStatus["US_CITIZEN"] = "US_CITIZEN"
})(CitizenshipStatus || (CitizenshipStatus = {}))

export let EmploymentStatus
;(function(EmploymentStatus) {
  EmploymentStatus["EMPLOYED"] = "EMPLOYED"
  EmploymentStatus["NOT_EMPLOYED"] = "NOT_EMPLOYED"
  EmploymentStatus["OTHER"] = "OTHER"
  EmploymentStatus["RETIRED"] = "RETIRED"
})(EmploymentStatus || (EmploymentStatus = {}))

export let Language
;(function(Language) {
  Language["ENGLISH"] = "ENGLISH"
  Language["SPANISH"] = "SPANISH"
})(Language || (Language = {}))

export let Questions
;(function(Questions) {
  Questions["ASK_MORTGAGE"] = "MORTGAGE_PAYMENTS"
  Questions["ASK_EMPLOYMENT"] = "EMPLOYMENT_DURATION"
  Questions["ASK_PROPERTY_TYPE"] = "SUBJECT_PROPERTY_TYPE"
})(Questions || (Questions = {}))
