export const generateShortUUID = () => {
  return 'xxxxxx-yxxx'.replace(/[xy]/g, function() {
    return Math.floor(Math.random() * 16).toString(16);
  });
}

// export const generateShortUUID = () => {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//     const r = Math.random() * 16 | 0;
//     const v = c === 'x' ? r : (r && 0x3 | 0x8);
//     return v.toString(16);
//   });
// }