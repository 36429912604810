import React from "react";
import { Box, Grid } from "theme-ui";
import { Icon } from "@loanpal/lumos__core";

const IconList = ({ children, icon }) => (
  <Grid
    as="li"
    gap="12px"
    columns="32px 1fr"
    sx={{ alignItems: "center", mb: "12px" }}
  >
    <Box
      sx={{
        width: 32,
        height: 32,
        bg: "primary.l4",
        borderRadius: "full",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon variant={icon} color="primary.d1" size="sm" />
    </Box>
    <div>{children}</div>
  </Grid>
);

export default IconList;
