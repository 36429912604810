import React from 'react';
import {
  Modal,
  ModalControls
} from '@loanpal/lumos__core';
import { Grid, View, Heading, Flex } from '@aws-amplify/ui-react';
import { DataList } from '@loanpal/lumos__core';

const UpdatePreview = ({previewCurrent, previewUpdate, isOpen, updateApp, toggleOpen}) => {
  return (
      <Modal variant="full" isOpen={isOpen} title="Preview QuickLink Update" onDismiss={() => toggleOpen(false)}>
        <Grid
          templateColumns="1fr 1fr"
          templateRows="50rem"
          gap="0.5rem"
        >
          {previewCurrent && (
          <View backgroundColor="#d7d7d7" padding="1rem">
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="0.5rem"
            >
              <Heading level={6}>
                Current
              </Heading>
              <DataList columns={[1]} items={[{
                label: 'Account Name',
                text: previewCurrent.partnerName
              }, {
                label: 'Channel',
                text: previewCurrent.channel
              }, {
                label: 'Category',
                text: previewCurrent.category
              }, {
                label: 'Loan Amount',
                text: previewCurrent.loanAmount
              }, {
                label: 'Submitting User First Name',
                text: previewCurrent.submittingUser.firstName
              }, {
                label: 'Submitting User Last Name',
                text: previewCurrent.submittingUser.lastName
              }, {
                label: 'Submitting User Email',
                text: previewCurrent.submittingUser.email
              }, {
                label: 'Submitting User Phone',
                text: previewCurrent.submittingUser.phone
              }
              ]} />
            </Flex>
          </View>)}
          {previewUpdate && (
          <View backgroundColor="#d7d7d7" padding="1rem">
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="0.5rem"
            >
              <Heading level={6}>
                Update
              </Heading>
              <DataList columns={[1]} items={[{
                label: 'Account Name',
                text: previewUpdate.partnerName
              }, {
                label: 'Channel',
                text: previewUpdate.channel !== previewCurrent.channel ? <span style={styles.update}>{previewUpdate.channel}</span> : previewUpdate.channel
              }, {
                label: 'Category',
                text: previewUpdate.category !== previewCurrent.category ? <span style={styles.update}>{previewUpdate.category}</span> : previewUpdate.category
              }, {
                label: 'Loan Amount',
                text: previewUpdate.loanAmount !== previewCurrent.loanAmount ? <span style={styles.update}>{previewUpdate.loanAmount}</span> : previewUpdate.loanAmount
              }, {
                label: 'Submitting User First Name',
                text: previewUpdate.submittingUser.firstName !== previewCurrent.submittingUser.firstName ? <span style={styles.update}>{previewUpdate.submittingUser.firtName}</span> : previewUpdate.submittingUser.firstName
              }, {
                label: 'Submitting User Last Name',
                text: previewUpdate.submittingUser.lastName !== previewCurrent.submittingUser.lastName ? <span style={styles.update}>{previewUpdate.submittingUser.lastName}</span> : previewUpdate.submittingUser.lastName
              }, {
                label: 'Submitting User Email',
                text: previewUpdate.submittingUser.email !== previewCurrent.submittingUser.email ? <span style={styles.update}>{previewUpdate.submittingUser.email}</span> : previewUpdate.submittingUser.email
              }, {
                label: 'Submitting User Phone',
                text: previewUpdate.submittingUser.phone !== previewCurrent.submittingUser.phone ? <span style={styles.update}>{previewUpdate.submittingUser.phone}</span> : previewUpdate.submittingUser.phone
              }
              ]} />
            </Flex>
          </View>)}
        </Grid>
        <ModalControls actions={[
            {
              onClick: () => updateApp(),
              label: 'Update',
            },
            {
              onClick: () => toggleOpen(false), label: 'Cancel'
            }
          ]}
        />
      </Modal>
  );
}

const styles = {
  update: {
    color: "#c00"
  }
}
export default UpdatePreview;