
import React, { useEffect, useState } from 'react';

const TextWithHyperlinks = ({ text }) => {
  const [processedText, setProcessedText] = useState(null);

  useEffect(() => {
    const processText = () => {
      try {
        // Regular expression to find links with labels in square brackets
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

        // Replace links with HTML anchor tags using the labels
        const processedHtml = text.replace(linkRegex, (match, label, url) => {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${label}</a>`;
        });

        // Replace hyphens with a list view
        const listHtml = processedHtml.replace(/^- (.+)$/gm, (match, item) => {
          return `<li>${item}</li>`;
        });

        // Wrap the list items in an unordered list
        setProcessedText(`<ul style="list-style: none; padding: 0;">${listHtml}</ul>`);
      } catch (error) {
        console.error('Error processing text:', error);
        setProcessedText('Error processing text');
      }
    };

    processText();
  }, [text]);

  return <div dangerouslySetInnerHTML={{ __html: processedText || 'Loading...' }} />;
};

export default TextWithHyperlinks;