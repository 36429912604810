import React, { useEffect, useState } from "react";
import { Grid, Image, Box, Spinner } from 'theme-ui';
import { useFormikContext } from 'formik';
import { API } from "aws-amplify";
import { Card, SingleSelect, Form, Button as LumosButton, useNotify, TextInput } from '@loanpal/lumos__core';
import { Categories } from "../../utils/categories";
import { Roles } from "../../utils/roles";
import { styles } from "./styles";
import {
  TextField,
  Flex,
  FieldGroupIcon,
  Divider
} from "@aws-amplify/ui-react";
import {
  email,
  phonePattern
} from "../../utils/formValidation";
import { phoneAWS } from "../../utils/format";
import { getStates, addChannelsToUser, getUserById, getOffersByCategory, addLicenseToUser } from "../../utils/api";
import { generatePassword } from "../../utils/passwordGenerator";
import { stateMapping } from "../../utils/state-mapping";
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
// Sales Rep Advanced Installer
const ROLEID = "e2f63a2c-c025-44de-bf73-d992f0ed2ab5";
// Change environment dev/prod accordingly
const env = "prod";
/*
"name": "Service Admin"
"id": "d6508641-2b1e-4654-8133-bc7b01d91d36",
"name": "Service Manager"
"id": "e2f63a2c-c025-44de-bf73-d992f0ed2ab5"
*/

// const stateLicenses = ["CA"];

export const UserSelect = ({ users, clientId, defaultSalesRep }) => {
    const { values, setFieldValue, setValues } = useFormikContext();
    const [spinner, setSpinner] = useState(false);
    const [timer, setTimer] = useState(null);
    const [salesRepEmailCheck, setSalesRepEmailCheck] = useState(true);
    const [userExists, setUserExists] = useState({exists: true, status: "loading"});
    const [suErrorMessage, setSuErrorMessage] = useState("");
    const [srpHasError, setSrpHasError] = useState(false);
    const [srfnHasError, setSrfnHasError] = useState(false);
    const [srlnHasError, setSrlnHasError] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [hasLicense, setHasLicense] = useState(false);
    const [srlicenseIdHasError, setSrlicenseIdHasError] = useState({empty: true, status: false});

  useEffect(() => {
    if (values.salesRepEmail && salesRepEmailCheck) {
      getUserByEmail(clientId, values.salesRepEmail);
      setSalesRepEmailCheck(false);
    }
    // eslint-disable-next-line
  }, [values.salesRepEmail, salesRepEmailCheck])

    let userItems = [];
    userItems = users.map((e) => [
        ...userItems,
        { name: `${e.email}`, value: e.id }
    ]).flat();

  const validateSalesRepFields = (values) => {
    const number = phoneUtil.parseAndKeepRawInput(values.salesRepPhone, 'US');
    let error = false;
    if (values.salesRepFName === "") { setSrfnHasError(true); error = true }
    if (values.salesRepLName === "") { setSrlnHasError(true); error = true }
    if (values.salesRepPhone === "" || !phonePattern(values.salesRepPhone) || !phoneUtil.isValidNumberForRegion(number, 'US')) { setSrpHasError(true); error = true }
    if (values.licenseId !== "" ) {
      error = validateSalesRepLicense(values);
    }
    return error;
  }

const validateSalesRepLicense = (values) => {
  let error = false;
  if (values.licenseId === "") { setSrlicenseIdHasError({empty: true, status: true}); error = true }
  // if (values.licenseState === "") { setFieldError("licenseState", "Please select a state"); error = true }
  return error;
}

  const saveLicense = async (clientId, salesRep) => {
    if (validateSalesRepLicense(values)) return;
    setSpinner(true);
    setButtonStatus(true);
    const result = await addLicenseToUser(clientId, salesRep.id, values.licenseId, values.licenseState);
    if (result && result !== "Error") {
      setHasLicense(true);
    } else {
      setSrlicenseIdHasError({empty: false, status: true});
    }
    setSpinner(false);
    setButtonStatus(false)
  }

  const createUser = async (clientId) => {
    if (validateSalesRepFields(values)) return;
    setSpinner(true);
    setButtonStatus(true);
    try {
    const myInit0 = {
        body: { data: {
          "firstName": values.salesRepFName,
          "lastName": values.salesRepLName,
          "password": generatePassword(10),
          "email": values.salesRepEmail.toLowerCase(),
          "mobilePhone": phoneAWS(values.salesRepPhone),
          "roleId": ROLEID
        }},
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

       await API.post("developerApi", "/create-user", myInit0)
      .then(async (response) => {
          if (response.id) {
            // get Channels
            const states = await getStates(clientId);
            await addChannelsToUser(clientId, states, response.id);
            const userById = await getUserById(clientId, response.id);
            if (userById) {
              setFieldValue("salesRep", userById);
              if (values.licenseId !== "" && values.licenseState !== "") {
                saveLicense(clientId, userById);
              }
              setUserExists({exists: true, status: "ready"});
              setSuErrorMessage("");
              setSpinner(false);
              setButtonStatus(false);
            }
          } else {
            console.log("Error creating user");
            setSpinner(false);
            setButtonStatus(false);
          }
      })
    } catch (err) {
        setValues({
          ...values,
          salesRep: defaultSalesRep,
          salesRepEmail: defaultSalesRep.email
        });
        setSuErrorMessage("There was a problem creating the account, we will use the default Sales Rep email: " + defaultSalesRep.email);
        setUserExists({exists: false, status: "default"});
        setSpinner(false);
        setButtonStatus(false);
    }
  }

  const getUserByEmail = async (clientId, salesRepEmail) => {
    try {
      setSpinner(true);
      if (!email(salesRepEmail)) {
        setUserExists({exists: false, status: "invalid email"});
        setSuErrorMessage("Not a valid Email");
        setSpinner(false);
        return;
      }
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          email: encodeURIComponent(salesRepEmail)
        },
      };
      // get user by Email
      await API.get("developerApi", "/users", myInit0)
      .then(async (response) => {
        if (response.data.length > 0) {
          // get userById
          const myInit1 = {
              queryStringParameters: {
                apiId: `${clientId}ApiId`,
                apiKey: `${clientId}ApiKey`,
                clientId: clientId,
                userId: response.data[0].id
              },
            };
          await API.get("developerApi", "/users", myInit1)
          .then(async (response) => {
            if (response) {
                const userById = response;
                setFieldValue("salesRep", userById);
                if (Roles.find(role => role === userById.role.name) === undefined) {
                    setUserExists({exists: false, status: "invalid role"});
                    setSuErrorMessage(`Sales Rep exists but ${userById.role.name} is not a valid role`);
                    setSpinner(false);
                    return;
                }
                // const channels = userById.channels;
                // if (channels.find(({ name }) => name.includes(formState.channel)) === undefined) {
                //     setUserExists({exists: false, status: "channel rights"});
                //     setSuErrorMessage(`User exists but needs ${formState.channel} channel rights`);
                //     return;
                // }
                const licensesArray = userById.licenses;
                if (licensesArray.length > 0 && licensesArray[0].status === "ACTIVE") {
                  setHasLicense(true)
                }
                setUserExists({exists: true, status: "ready"});
            }
          })
        } else {
          setFieldValue("salesRep", undefined);
          setUserExists({exists: false, status: "undefined"});
          setSuErrorMessage("Sales Rep is not in our system, an authorized user is required, try a different email or lets create an account.");
        }
        setSpinner(false);
      })
    } catch (err) {
      console.log("error getting user", err);
      setSpinner(false);
      return false;
    }
  }

    return(
        <Card style={styles.cardBox}>
        <Box
            sx={{
            button: {
                minHeight: 40,
                display: 'grid',
                gridTemplateColumns: '1fr 24px',
                textAlign: 'left'
            },
            }}
            >
            {/* <SingleSelect
                name="userId"
                label={`Existing Users`}
                items={userItems}
                /> */}
          <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
            <TextField
              label="Sales Rep Email"
              errorMessage={suErrorMessage}
              hasError = {values.salesRepEmail && !userExists.exists}
              name="salesRepEmail"
              size="small"
               width={{base: "100%", large: "50%"}}
               onChange={(e) => {
                clearTimeout(timer);
                setSalesRepEmailCheck(false);
                setUserExists({exists: false, status: "loading"});
                setSuErrorMessage("");
                setFieldValue("salesRepEmail", e.target.value);
                const newTimer = setTimeout(() => {
                  setSalesRepEmailCheck(true);
                }, 1000)
                setTimer(newTimer)
              }}
              style={styles.label}
              innerEndComponent={
                  // user exists
                  values.salesRepEmail && (
                  <FieldGroupIcon
                    ariaLabel="Check"
                    variation="link"
                    height="90%"
                  >
                    {userExists.status === "ready"  && (
                      <i className="gg-check-o-green"></i>
                    )}
                  </FieldGroupIcon>)
              }
            />
            <Box>
              {spinner && (
                <Box sx={{ minHeight: 35, marginTop: "20px" }}>
                  <Spinner style={styles.spinner} size="40" />
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
        {!hasLicense && values.salesRepEmail !== "" && userExists.exists && (
          <>
          <Divider
          orientation="horizontal"
          style={styles.divider}
          size="small"
          />
          <Box sx={{ pr: 2, pt: 2 }}>
            <Flex direction={{base: "column", large: "column"}} alignItems="flex-start" wrap={"wrap"}>
              <TextField
                maxLength={20}
                size="small"
                width={{base: "100%", large: "50%"}}
                descriptiveText="Required for CA Sales Professionals"
                label="CA Home Improvement Sales license (Optional)"
                onChange={(e) => {
                  setFieldValue("licenseId", e.target.value);
                  setSrlicenseIdHasError(false);
                }}
                hasError={srlicenseIdHasError.status}
                errorMessage={srlicenseIdHasError.empty ? "Please enter a valid license" : "There was a problem saving the license, please contact GoodLeap support to update it."}
              />
              {/* <SingleSelect
                placeholder="Select State"
                name="licenseState"
                label="What state is this license valid for?"
                sxProps={{ mb: 0, maxWidth: 300 }}
                items={stateLicenses.map((st) => ({ item: st, value: st }))}
                onChange={(e) => {
                  setErrors({});
                  setFieldValue("licenseState", e.target.value);
                }}
              /> */}
          </Flex>
          </Box>
          <Box sx={{ minHeight: 35, marginTop: "15px"}}>
            <LumosButton onClick={() => saveLicense(clientId, values.salesRep)} disabled={buttonStatus} >
                Save License
            </LumosButton>
          </Box>
          </>
        )}
        {values.salesRepEmail !== "" && userExists.status === 'undefined' && (
        <>
        <Divider
          orientation="horizontal"
          style={styles.divider}
          size="small"
        />
        <Box sx={{ pr: 2, pt: 2 }}>
          <Flex direction={{base: "column", large: "row"}} alignItems="flex-start">
              <TextField
                label="Sales Rep First Name"
                onChange={(e) => {
                  setSrfnHasError(false);
                  setFieldValue("salesRepFName", e.target.value);
                }}
                size="small"
                width={{base: "100%", large: "50%"}}
                style={styles.label}
                hasError={srfnHasError}
                errorMessage="Please enter Sales Rep First Name"
              />
              <TextField
                label="Sales Rep Last Name"
                onChange={(e) => {
                  setSrlnHasError(false);
                  setFieldValue("salesRepLName", e.target.value);
                }}
                size="small"
                width={{base: "100%", large: "50%"}}
                style={styles.label}
                hasError={srlnHasError}
                errorMessage="Please enter Sales Rep Last Name"
              />
              <TextField
                label="Sales Rep Phone"
                onChange={(e) => {
                  setSrpHasError(false);
                  setFieldValue("salesRepPhone", e.target.value);
                }}
                size="small"
                width={{base: "100%", large: "50%"}}
                style={styles.label}
                hasError={srpHasError}
                errorMessage="Please enter a valid phone number"
              />
            </Flex>
        </Box>
        <Divider
          orientation="horizontal"
          style={styles.divider}
          size="small"
          />
        <Box sx={{ pr: 2, pt: 2 }}>
            <Flex direction={{base: "column", large: "column"}} alignItems="flex-start" wrap={"wrap"}>
              <TextField
                maxLength={20}
                size="small"
                width={{base: "100%", large: "50%"}}
                descriptiveText="Required for CA Sales Professionals"
                label="CA Home Improvement Sales license (Optional)"
                onChange={(e) => {
                  setFieldValue("licenseId", e.target.value);
                }}
                hasError={srlicenseIdHasError.status}
                errorMessage={srlicenseIdHasError.empty ? "Please enter a valid license" : "There was a problem saving the license, please contact GoodLeap support to update it."}
              />
              {/* <SingleSelect
                placeholder="Select State"
                name="licenseState"
                label="What state is it valid for?"
                sxProps={{ mb: 0, maxWidth: 300 }}
                items={stateLicenses.map((st) => ({ item: st, value: st }))}
                onChange={(e) => {
                  setErrors({})
                  setFieldValue("licenseState", e.target.value);
                }}
              /> */}
          </Flex>
        </Box>
        <Box sx={{ minHeight: 35, marginTop: "15px"}}>
          <LumosButton onClick={() => createUser(clientId)} disabled={buttonStatus} >
              Create User
          </LumosButton>
        </Box>
        </>
        )}
        </Card>
    )
}

export const CategorySelect = ({ categories }) => {
 const { values, setFieldValue, setValues } = useFormikContext();
 const selectedSalesRep = values.salesRepEmail;
 const shouldSelectCategory = Boolean(
  selectedSalesRep !== '' && values.salesRep !== undefined
 );

// if the category changes, reset the rest of the inputs
  useEffect(() => {
    setValues({
      ...values,
      state: '',
      groupId: '',
      referenceNumber: '',
      shouldSelectChannel: 'false'
    });
  }, [values.categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

 useEffect(() => {
  if (!shouldSelectCategory) setFieldValue('categoryId', '');
  // eslint-disable-next-line
 }, [shouldSelectCategory]);

 if (!shouldSelectCategory) return null;

 const getCategory = (cat) => {
        const nextCat = Object.values(Categories).find(el => el.name.toLowerCase() === cat.name.toLowerCase())
        return renderCategory(nextCat);
  }
 const renderCategory = (cat) => (
    <Grid
      key={cat.id}
      sx={{ minHeight: 56, alignItems: 'center', textAlign: 'left', py: 2, pl: 2 }}
      columns="24px 1fr"
      gap={16}
    >
      <Image
        src={cat.imageUrl}
        width={24}
        height={24}
        aria-hidden
        alt=""
        mx="auto"
        sx={{ borderRadius: '100%' }}
      />
      <div>
        <Box color="neutral.d2" data-testid="loan-category-name" sx={{ fontWeight: 500 }}>
          {cat.name}
        </Box>
        <Box sx={{ fontSize: '0.75rem', lineHeight: '1.46667' }}>{cat.description}</Box>
      </div>
    </Grid>
  );
  return (
    <Card style={styles.cardBox}>
      <Box
        sx={{
          button: {
            minHeight: 46,
            display: 'grid',
            gridTemplateColumns: '1fr 24px',
            textAlign: 'left'
          },
        }}
      >
        <SingleSelect
          placeholder="None selected"
          name="categoryId"
          label="What will you be installing?"
          sxProps={{ mb: 0, maxWidth: 500 }}
          items={categories.map((cat) => ({ item: getCategory(cat), value: cat.id }))}
        />
      </Box>
    </Card>
  );
}

export const StateSelect = ({ clientId }) => {
  const { values, setFieldValue } = useFormikContext();
  const [stateSelection, setStateSelection] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const selectedSalesRep = values.salesRepEmail;
  const selectedCategory = values.categoryId;

  const shouldSelectState = Boolean(
    selectedSalesRep !== '' && values.salesRep !== undefined && selectedCategory !== ''
  );

  useEffect(() => {
    if (!shouldSelectState) setFieldValue('state', "");
    // eslint-disable-next-line
  }, [shouldSelectState]);

  useEffect(() => {
    if (selectedCategory !== '' && selectedSalesRep !== '') {
      getStatesFromChannels(clientId, selectedCategory, env);
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

   if (!shouldSelectState) return null;

  const getStatesFromChannels = async (clientId, categoryId, env) => {
    try {
      setSpinner(true);
      const offersWithStates = await getOffersByCategory(clientId, categoryId, env);
       if (offersWithStates.data.length > 0) {
         let approvedStates = [];
         approvedStates = offersWithStates.data.map((o) => {
           return [
             ...approvedStates,
             ...o.states
           ]
         });
        approvedStates = approvedStates.flat();
        approvedStates = approvedStates.filter((item, index) => approvedStates.indexOf(item) === index).sort();
        const stateSelection =
           approvedStates.map((code ) => ({ name: stateMapping[code], value: code })) || [];
        setStateSelection(stateSelection);
      }
      setSpinner(false);
    } catch (err) {
      console.log("error getting states", err);
      setSpinner(false);
      return setStateSelection([]);
    }
  }

  return (
  <Card style={styles.cardBox}>
    <Box
      sx={{
        button: {
          minHeight: 46,
          display: 'grid',
          gridTemplateColumns: '1fr 24px',
          textAlign: 'left'
        },
      }}
    >
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
      <SingleSelect
        name="state"
        label="State / Territory"
        items={stateSelection}
        sxProps={{ mb: 0, width: "100%" }}
      />
      <Box>
        {spinner && (
          <Box sx={{ minHeight: 35, marginTop: "25px" }}>
            <Spinner style={styles.spinner} size="40" />
          </Box>
        )}
      </Box>
      </Flex>
    </Box>
  </Card>
  )
}

export const ChannelSelect = ({ clientId }) => {
  const { values, setFieldValue, setValues, setFieldTouched } = useFormikContext();
  const [availableChannels, setAvailableChannels] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const selectedSalesRep = values.salesRepEmail;
  const selectedCategory = values.categoryId;
  const selectedState = values.state;

  const shouldSelectChannel = Boolean(
    selectedSalesRep !== '' && values.salesRep !== undefined && selectedCategory !== '' && selectedState !== ''
    // && availableChannels.length > 0
  );

  const getAvailableChannels = async (clientId, categoryId, state, env) => {
    try {
      setSpinner(true);
      const offersWithChannels = await getOffersByCategory(clientId, categoryId, env);
       if (offersWithChannels.data.length > 0) {
         let approvedChannels = [];
         approvedChannels = offersWithChannels.data.map((o) => {
           return [
             ...approvedChannels,
             ...o.channels
           ]
         });
        approvedChannels = approvedChannels.flat();
        approvedChannels = approvedChannels.filter((item, index) => approvedChannels.indexOf(item) === index).sort();
        const availableChannels =
           approvedChannels.map(( code ) => ({ name: code, value: code })) || [];
        setAvailableChannels(availableChannels);
        setSpinner(false);
      }
    } catch (err) {
      console.log("error getting channels", err);
      setSpinner(false);
      return setAvailableChannels([]);
    }
  }

  useEffect(() => {
    if (!shouldSelectChannel) setFieldValue('groupId', "");
    // eslint-disable-next-line
  }, [shouldSelectChannel]);

  useEffect(() => {
    if (selectedSalesRep !== '' && selectedCategory !== '' && selectedState !== '') {
      getAvailableChannels(clientId, selectedCategory, selectedState, env);
    }
    // eslint-disable-next-line
  }, [selectedState]);

  useEffect(() => {
    // default to available channel if there's only one
    if (availableChannels.length === 1) {
      // setFieldValue('groupId', availableChannels[0].code);
      setValues({
      ...values,
      groupId: availableChannels[0].value
      });
      setFieldTouched('groupId');
    }
    // for conditional validation
    setFieldValue('shouldSelectChannel', String(availableChannels.length > 0));
  }, [availableChannels]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!shouldSelectChannel) return null;

  return (
  <Card style={styles.cardBox}>
    <Box
      sx={{
        button: {
          minHeight: 46,
          display: 'grid',
          gridTemplateColumns: '1fr 24px',
          textAlign: 'left'
        },
      }}
    >
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
      <SingleSelect
        name="groupId"
        label="Channel"
        items={availableChannels}
        sxProps={{ mb: 0, width: "100%" }}
      />
      <Box>
        {spinner && (
          <Box sx={{ minHeight: 35, marginTop: "25px" }}>
            <Spinner style={styles.spinner} size="40" />
          </Box>
        )}
      </Box>
      </Flex>
      <Divider
        orientation="horizontal"
        style={styles.divider}
      />
      <TextInput
          name="referenceNumber"
          label={'Partner reference number (optional)'}
          sxProps={{ mb: 0, maxWidth: 500 }}
        />
    </Box>
  </Card>
  )
}

const Main = ({ state, send }) => {
  const { categories, users, sharedApp } = state.context;
  const { addToast } = useNotify();

  return (
    <Form
      initialValues={{
        categoryId: "",
        state: "",
        groupId: "",
        salesRep: undefined,
        userId: "",
        salesRepEmail: "",
        salesRepFName: "",
        salesRepLName: "",
        salesRepPhone: "",
        referenceNumber: "",
        shouldSelectChannel: 'true',
        licenseId: "",
        licenseState: "CA"
      }}
      onSubmit={({...values}) => {
        const { categoryId, userId, salesRepEmail, salesRep, state, groupId, referenceNumber, shouldSelectChannel } = values;
        const input = { categoryId, userId, salesRepEmail, salesRep, state, groupId, referenceNumber };
        if (categoryId === '' || salesRep === undefined || state === '' || (shouldSelectChannel && groupId === '')) {
          addToast({ variant: 'danger', message: `Not ready to proceed, please continue!` });
          return null;
        }
        send({type: 'SELECTED_CATEGORY', input});
      }}
      showSummary={false}
    >
    <UserSelect users={users} clientId={sharedApp.clientId} defaultSalesRep={sharedApp.submittingUser} />
    <CategorySelect categories={categories} />
    <StateSelect clientId={sharedApp.clientId} />
    <ChannelSelect clientId={sharedApp.clientId} />
    <Box style={styles.buttonBox}>
        <LumosButton type="submit" >
            Save and Continue
        </LumosButton>
    </Box>
    </Form>
  )
};

export default Main;
