import React, { useEffect, useState } from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { searchGoodLinkss, searchSharedApps, linksByClientIdSorted, linksByEmailwoClientId } from "../../graphql/queries";
import {
  Heading,
  Button as LumosButton,
  AlertInline,
  Modal,
  ModalControls,
  Icon
 } from "@loanpal/lumos__core";
import { Box } from "theme-ui";
import Loading from "../../utils/loading";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import { dateTimeStr } from "../../utils/format";
import {
  Authenticator,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  SearchField,
  View,
  Flex,
  Menu,
  MenuItem,
  Autocomplete,
  HighlightMatch
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../aws-exports";

Amplify.configure(awsExports);

const GoodLinks = () => {
  const [SharedApps, setSharedApps] = useState([]);
  const [appsOptions, setAppsOptions] = useState();
  const [goodLinks, setGoodLinks] = useState([]);
  const [clientId, setClientId] = useState("");
  const [value, setValue] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isOpen, toggleOpen] = useState(false);
  const [link, setLink] = useState();
  const [count, setCount] = useState("");

  useEffect(() => {
    fetchSharedApps();
    fetchAllLinks();
  }, []);

  useEffect(() => {
    setGoodLinks([]);
    if (clientId !== "") {
      fetchLinksByClientId();
    } else if (clientId === "") {
      setClientId("");
      setCount("");
      fetchAllLinks();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  // It is your responsibility to set up onSelect
  const onSelect = (option) => {
    const { label, id } = option;
    setValue(label);
    setClientId(id);
  };

  // It is your responsibility to set up onClear
  const onClear = () => {
    setValue('');
    setClientId("");
  };

  const renderOption = (option, value) => {
    const { label } = option;
    return (
      <Flex alignItems="center">
        <HighlightMatch style={styles.label} query={value}>{label}</HighlightMatch>
      </Flex>
    );
  };

  async function fetchSharedApps() {
    try {
      setSpinner(true);
      const appData = await API.graphql(graphqlOperation(searchSharedApps, {
        sort: {
          field: 'clientId',
          direction: 'asc'
        },
        limit: 1000
      }));
      const SharedApps = appData.data.searchSharedApps.items;
      setSharedApps(SharedApps);
        let options = [];
        if (appData.data.searchSharedApps.items.length > 0) {
        options = appData.data.searchSharedApps.items.map((app) => {
          return [
            ...options,
            { id: `${app.clientId}`, label: `${app.partnerName}`, key: `${app.id}` }
          ]
        }).flat();
        setAppsOptions(options);
      }
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      console.log("error fetching Shared Apps", err);
    }
  }

    async function fetchAllLinks() {
    try {
      const linkData = await API.graphql(
        graphqlOperation(searchGoodLinkss, {
          sort:  { field: "createdAt", direction: "desc" }
        } )
      );
      if (linkData.data.searchGoodLinkss.items.length > 0) {
        const links = linkData.data.searchGoodLinkss.items;
        if (links && links.length > 0) {
          setGoodLinks([...links]);
        }
      }
    } catch (err) {
      console.log("error fetching GoodLinks App", err);
    }
  }

  async function fetchLinksByClientId() {
    try {
      const linkData = await API.graphql(graphqlOperation(linksByClientIdSorted, {
        clientId: clientId,
        sortDirection: "DESC",
        limit: 1000 }));
      if (linkData.data.linksByClientIdSorted.items.length > 0) {
        setCount(linkData.data.linksByClientIdSorted.items.length);
        const links = linkData.data.linksByClientIdSorted.items;
        if (links && links.length > 0) {
          setGoodLinks([...links]);
        }
      }
    } catch (err) {
      console.log("error fetching GoodLinks App", err);
    }
  }

  async function fetchLinkByEmail(e) {
    if (e === "") return;
    try {
      console.log(e);
      const linkData = await API.graphql(
        graphqlOperation(linksByEmailwoClientId, { custEmail: e.toLowerCase() }));
      if (linkData.data.linksByEmailwoClientId.items.length > 0) {
        const links = linkData.data.linksByEmailwoClientId.items;
        if (links && links.length > 0) {
          setGoodLinks([...links]);
        }
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
    }
  }

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#f5f5f5",
                  marginBottom: "20px",
                  marginTop: "20px",
                  fontSize: "25px",
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <Flex
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <View>GoodLinks {clientId !== "" ? <span style={styles.count}> Total for {clientId}:</span> : ""} <span style={styles.countBold}>{count}</span></View>
                  </Flex>
                  <SearchField
                    label="Search"
                    placeholder="Search by email..."
                    size="small"
                    onSubmit={(e) => fetchLinkByEmail(e)}
                    onClear={() => fetchAllLinks()}
                  />
                </Flex>
              </Heading>
              <Box style={styles.selectBox}>
                {SharedApps && appsOptions &&
                  <Autocomplete
                    label=""
                    placeholder="Search by partner name..."
                    size="small"
                    onChange={onChange}
                    onClear={onClear}
                    onSelect={onSelect}
                    style={styles.select}
                    value={value}
                    options={appsOptions}
                    renderOption={renderOption}
                  />
                }
              </Box>
              {goodLinks.length > 0 ? (
                <Table caption="" highlightOnHover={false} size="small" variation="striped">
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Client Id</TableCell>
                      <TableCell as="th">Email</TableCell>
                      <TableCell as="th">Name</TableCell>
                      <TableCell as="th">Created At</TableCell>
                      <TableCell as="th"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {goodLinks.map((link, index) => (
                      <TableRow key={link.id ? link.id : index}>
                        <TableCell>{link.clientId}</TableCell>
                        <TableCell>{link.custEmail}</TableCell>
                        <TableCell>{link.custFirstName} {link.custLastName}</TableCell>
                        <TableCell>{dateTimeStr(link.createdAt)}</TableCell>
                        <TableCell>
                          <View width="4rem">
                            <Menu menuAlign="start" size="small">
                              <MenuItem size="small" onClick={() => {setLink(link); toggleOpen(true)}}><Icon variant="eye-open" size="sm" sx={{marginRight: '5px'}} />View</MenuItem>
                            </Menu>
                          </View>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <AlertInline variant="info">No items found.</AlertInline>
              )}
            </Box>
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
          </div>
          <Modal isOpen={isOpen} onDismiss={() => toggleOpen(false)} title="GoodLink">
          <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "14px",
                }}
              >
            {JSON.stringify(null, 2, link)}
            </Heading>
            <ModalControls actions={[
                {
                  onClick: () => toggleOpen(false), label: 'Close'
                }
              ]}
            />
          </Modal>
        </>
      )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  divider: { margin: "25px 0", color: "white" },
  space: { marginTop: "20px" },
  app: { marginBottom: 15 },
  input: {
    marginBottom: 20,
    padding: 8,
    fontSize: 16,
  },
  label: {
    fontSize: 16
  },
  count: {
    fontSize: 16,
    fontWeight: 500
  },
  countBold: {
    fontSize: 16,
    fontWeight: 600
  },
  select: {
    width: '100%'
  },
  selectBox: {
    marginBottom: "30px"
  },
  switch: {
    marginBottom: 10,
    padding: 8,
    fontSize: 16,
  },
  appClientId: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
  },
  menuButton: {
    backgroundColor: "#00838f",
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
  },
};

export default GoodLinks;
