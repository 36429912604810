export const styles = {
    cardBox: {
        boxSizing: 'border-box',
        margin: '0px 0px 8px',
        minWidth: '0px',
        fontFamily: 'Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        color: '#323232',
        lineHeight: '1.46667',
        fontSize: '0.875rem',
        padding: '16px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderImage: 'initial',
        borderRadius: '0.5rem',
        borderColor: '#d7d7d7',
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
    },
    buttonBox: {
        margin: '14px 0',
        display: 'flex',
        justifyContent: 'flex-start',
        // paddingLeft: '45px'
    },
    inputError: {
        padding: 8,
        fontSize: 14
    },
    spinner: {
      color: '#00838f',
    },
    bold: {
        fontWeight: 600
    },
    thin: {
        fontWeight: 100
    },
    spaceAround: {
        lineHeight: 2
    },
    label: {
        fontFamily: 'Inter,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
        lineHeight: 1.46667,
        fontSize: '0.875rem',
        color: '#323232',
        fontWeight: 500,
    },
    input: {
        fontFamily: 'Inter,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
        lineHeight: 1.46667,
        fontSize: '0.875rem',
        color: '#323232',
        fontWeight: 500,
        backgroundColor: "#ffffff"

    },
    divider: { margin: "5px 0", color: "white", borderColor: "white" },
    divider10: { margin: "10px 0", color: "white", borderColor: "white" },
    dividerPlus: { color: "#FF8300", borderColor: "#FF8300" },
    divider20Grey: { margin: "20px 0", color: "white", borderColor: "#a3afb8" },
    backButtonIcon: {
        backgroundColor: '#00838f',
        padding: '1px',
        marginRight: 10,
        borderRadius: '100%',
        height: 25
    },
    resetButtonIcon: {
        backgroundColor: '#00838f',
        padding: '1px',
        marginRight: 10,
        borderRadius: '100%',
        height: 25
    },
    backBar: {
        margin: -3,
        marginBottom: 3,
        // borderBottom: '1px solid',
        // borderColor: '#d7d7d7'
    },
    progressBar: {
        margin: -3,
        marginBottom: 3,
        marginTop: 3
    },
    isLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
     logo: {
        width: 150,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 4,
    },
    heading: {
        backgroundColor: "#003439",
    },
    loader: {
        height: "5rem"
    },
    period: {
        display: 'inline', fontSize: '70%', fontWeight: 'normal'
    },
    catLogo: {
        width: 24,
        position: "relative",
        top: 0,
        margin: "0 10px",
    },
    catHeader: {
        fontWeight: "500"
    },
    glpcard: {
        padding: "10px",
        backgroundColor: "#FCF5EA"
    },
    solarPlusText: {
        lineHeight: 1.46667,
        fontSize: '0.875rem',
        color: '#FF8300',
        fontWeight: 100
    },
    hiPlusText: {
        lineHeight: 1.46667,
        fontSize: '0.875rem',
        color: '#1c70af',
        fontWeight: 100
    },
    offerText: {
        lineHeight: 1.46667,
        fontSize: '0.875rem',
        color: '#000000',
        fontWeight: 600,
    },
    amountText: {
        lineHeight: 1.46667,
        fontSize: '0.875rem',
        color: '#000000',
        fontWeight: 200,
    }
}