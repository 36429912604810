import React, { useState } from "react"
import { object, string, number } from "yup"
import { money } from "@loanpal/yup"
import {
  Button,
  Heading,
  Form,
  ChoiceInput,
  NumberInput,
  Modal,
  ModalControls,
  TextInput
} from "@loanpal/lumos__core";
import { Flex } from '@aws-amplify/ui-react';
import { Card, Spinner } from "theme-ui"
import { CUSTOMER_SUPPORT_PHONE } from "../../utils/glodbals"
import { styles } from "./styles";
import {
  CitizenshipStatus,
  EmploymentStatus,
  Language
} from "../../utils/types"


const employmentItems = [
  {
    label: "Employed",
    value: EmploymentStatus.EMPLOYED
  },
  {
    label: "Not employed",
    value: EmploymentStatus.NOT_EMPLOYED
  },
  {
    label: "Retired",
    value: EmploymentStatus.RETIRED
  },
  {
    label: "Other",
    value: EmploymentStatus.OTHER
  }
]
const languageItems = [
  {
    label: "English",
    value: Language.ENGLISH
  },
  {
    label: "Spanish",
    value: Language.SPANISH
  }
]
const citizenshipItems = [
  {
    label: "U.S Citizen",
    value: CitizenshipStatus.US_CITIZEN
  },
  {
    label: "Lawful Permanent Resident Alien",
    value: CitizenshipStatus.LAWFUL_PERMANENT_RESIDENT_ALIEN
  },
  {
    label: "Other",
    value: CitizenshipStatus.OTHER
  }
]

const BorrowerDetail = ({ send, state }) => {
    const [isLoading, setIsLoading] = useState(false);
    const key = state.matches('startApplication.details') ? 'startApplication.details' : 'startApplication.coborrowerDetails';
    const metaKey = `applicationForm.${key}`;
    const pageTitle = state.meta[metaKey].title;
    const isCoborrower = key === 'startApplication.coborrowerDetails';

    const defaultValues = !isCoborrower ? {
        spokenLanguage: Language.ENGLISH,
        citizenshipStatus: state.context.borrowerDetails ? state.context.borrowerDetails.citizenshipStatus : "",
        occupation: state.context.borrowerDetails ? state.context.borrowerDetails.occupation: "",
        employmentStatus: state.context.borrowerDetails ? state.context.borrowerDetails.employmentStatus : "",
        annualIncome: state.context.borrowerDetails ? state.context.borrowerDetails.annualIncome : ""
    } : {
        spokenLanguage: Language.ENGLISH,
        citizenshipStatus: state.context.coborrowerDetails ? state.context.coborrowerDetails.citizenshipStatus : "",
        occupation: state.context.coborrowerDetails ? state.context.coborrowerDetails.occupation: "",
        employmentStatus: state.context.coborrowerDetails ? state.context.coborrowerDetails.employmentStatus : "",
        annualIncome: state.context.coborrowerDetails ? state.context.coborrowerDetails.annualIncome : ""
    }

  const askEmploymentDuration = false
  const schema = object({
    employmentStatus: string()
      .oneOf([
        EmploymentStatus.NOT_EMPLOYED,
        EmploymentStatus.EMPLOYED,
        EmploymentStatus.RETIRED,
        EmploymentStatus.OTHER
      ])
      .required("Select a value for employment status"),
    occupation: string().required(`Enter your occupation title`),
    employmentDuration: askEmploymentDuration
      ? number()
          .min(0)
          .required("Enter the approximate number of years employed")
          .typeError(
            "Enter the approximate number of years employed in whole numbers"
          )
      : number(),
    // @ts-ignore
    annualIncome: money()
      .required("Enter the approximate individual pre-tax annual income")
      .typeError("Amount must be a valid currency like $40,000.00")
      .min(1000, "Income must be greater than $1,000.00"),
    citizenshipStatus: string()
      .oneOf([
        CitizenshipStatus.OTHER,
        CitizenshipStatus.LAWFUL_PERMANENT_RESIDENT_ALIEN,
        CitizenshipStatus.US_CITIZEN
      ])
      .required("Select a value for Citizenship status"),
    spokenLanguage: string()
      .oneOf([Language.ENGLISH, Language.SPANISH])
      .required("Select a preferred language")
  })

  const citizenshipErrorActions = [
    { onClick: () => send("ACKNOWLEDGE"), label: "Ok" }
  ]

  if (key === "startApplication.coborrowerDetails")
    citizenshipErrorActions.push({
      onClick: () => send("CHANGE_COBORROWER"),
      label: "Change co-borrower"
    })

  return (
    <>
      <Form
        initialValues={defaultValues}
            enableReinitialize={false}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={schema}
            onSubmit={({ ...values }  ) => {
                console.log("onSubmit", values);
                setIsLoading(true);
                setTimeout(() => send('NEXT_PAGE', { input: { ...values } }), 3000);
            }}
            showSummary={false}
      >
        <Card>
          <div sx={{ bg: "white" }}>
            <Heading variant="h5" as="h2" sx={{ pb: 3 }}>
              {pageTitle}
            </Heading>
            <ChoiceInput
              name="employmentStatus"
              label="Employment status"
              items={employmentItems}
            />
          </div>
          {askEmploymentDuration && (
            <NumberInput
              autoComplete="off"
              name="employmentDuration"
              label="Approximately how many years have you been employed?"
              placeholder="Years"
              size={32}
            />
          )}
          <TextInput
            name="occupation"
            label="Occupation Title"
            autoComplete="off"
            size={32}
        />
          <NumberInput
            autoComplete="off"
            name="annualIncome"
            label="Individual pre-tax annual income"
            prefix="$"
            suffix="per year"
            tooltip={
              <div sx={{ maxWidth: 350 }}>
                <Heading as="div" variant="h5">
                  Individual pre-tax annual income
                </Heading>
                You do not need to reveal alimony, child support or separate
                maintenance income unless you want to have it considered as a
                basis for repaying your loan.
              </div>
            }
            size={32}
          />
          <ChoiceInput
            name="citizenshipStatus"
            label="Citizenship"
            items={citizenshipItems}
          />
          <ChoiceInput
            name="spokenLanguage"
            label="Preferred language / Idioma preferido"
            items={languageItems}
          />
        </Card>
            <Flex
                direction="row"
                justifyContent="start"
                alignItems="stretch"
                alignContent="flex-end"
                wrap="nowrap"
                gap="1rem"
                >
                <Button type="submit" disabled={isLoading} sx={{ mt: 3, px: 5 }}>
                    {isLoading ? (
                    <div styles={styles.isLoading}>
                        <Spinner size={20} sx={{ mr: 2, mb: -1 }} />
                        SAVING...
                    </div>
                    ) : (
                    'SAVE AND CONTINUE'
                    )}
                </Button>
                </Flex>
      </Form>
      <Modal
        title="You don't qualify"
        isOpen={state.matches(`${key}.citizenship`)}
        onDismiss={() => send("ACKNOWLEDGE")}
      >
        If you are not a US Citizen or Lawful Permanent Resident Alien,{" "}
        GoodLeap cannot process or approve your loan. If you have questions,
        please call {CUSTOMER_SUPPORT_PHONE}.
        <ModalControls actions={citizenshipErrorActions} />
      </Modal>
      <Modal
        title="Add co-borrower information"
        isOpen={state.matches("borrower.details.coborrowerPrompt")}
        onDismiss={() => send("ACKNOWLEDGE")}
      >
        The next few screens will ask you information about your co-borrower.
        <ModalControls
          actions={[
            {
              onClick: () => send("NEXT_PAGE"),
              label: "OK"
            }
          ]}
        />
      </Modal>
    </>
  )
}

export default BorrowerDetail
