import React from 'react';
import { Form, SingleSelect } from '@loanpal/lumos__core';
import { object, string } from "yup";
import { Box } from "theme-ui";

const Users = ({ usersItems, setInput, sUser, readOnly }) => {
  const schema = object({
    user: string().required('Select User')
  });

  const initialValues = {
    user: sUser ? sUser.id : ""
  };

  const wrapSetInput = (value) => {
    setInput(value);
  }

  return (
    <>
    {usersItems &&
        <>
        <Form onSubmit={console.log} validationSchema={schema} initialValues={initialValues}>
          <Box sx={{ width: "100%", mr: 5, mt: 2 }}>
            <SingleSelect
              dangerouslyOnChange={(e) => {
                  wrapSetInput(e);
              }}
              name="user"
              label={`Existing Users`}
              items={usersItems}
              readOnly={!!readOnly}
            />
          </Box>
      </Form>
      </>
    }
    </>
  )
}

export default Users;