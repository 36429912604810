import React from 'react';
import AWS from 'aws-sdk';
import {
  Button as LumosButton
 } from "@loanpal/lumos__core";

const ExportDynamoDBTable = ({ tableName }) => {
  const handleExport = async () => {
    try {
      // Configure AWS credentials
      // CAUTION: DO NOT COMIT THE AWS KEYS
      AWS.config.update({
        accessKeyId: '',
        secretAccessKey: '',
        region: 'us-west-2',
      });

      // Create a DynamoDB instance
      const dynamodb = new AWS.DynamoDB();

      // Scan the DynamoDB table
      const scanParams = {
        TableName: tableName,
      };
      const scanResult = await dynamodb.scan(scanParams).promise();
      const items = scanResult.Items;

          // Recursive function to get all headers
    const getHeaders = (item, parentKey = '') => {
    const headers = [];
    for (const key in item) {
        const nestedKey = parentKey ? `${parentKey}.${key}` : key;
        if (item.hasOwnProperty(key)) {
        if (typeof item[key] === 'object' && !Array.isArray(item[key])) {
            headers.push(...getHeaders(item[key], nestedKey));
        } else {
            headers.push(nestedKey);
        }
        }
    }
    return headers;
    };

    // Recursive function to get nested value by path
    const getValueByPath = (obj, path) => {
    if (path.length === 0) return obj;
    const [currentPath, ...remainingPath] = path;
    if (obj && obj.hasOwnProperty(currentPath)) {
        return getValueByPath(obj[currentPath], remainingPath);
    }
    return undefined;
    };

    // Recursive function to flatten an item
    const flattenItem = (item, headers) => {
    const values = [];
    headers.forEach((header) => {
        const nestedValue = getValueByPath(item, header.split('.'));
        values.push(nestedValue !== undefined ? nestedValue : '');
    });
    return values;
    };

     // Prepare CSV content
    let csvContent = '';
    if (items.length > 0) {
    const headers = getHeaders(items[0]);
    csvContent += headers.join(',') + '\n';
    items.forEach((item) => {
        const values = flattenItem(item, headers);
        csvContent += values.join(',') + '\n';
    });
    }

      // Create a temporary anchor element to download the CSV
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
      downloadAnchor.download = `${tableName}.csv`;
      document.body.appendChild(downloadAnchor);

      // Trigger the click event to download the file
      downloadAnchor.click();

      // Cleanup
      document.body.removeChild(downloadAnchor);
    } catch (error) {
      console.error('Error exporting DynamoDB table:', error);
    }
  };

  return (
    <div>
      <LumosButton onClick={handleExport}>Export QuickLinks</LumosButton>
    </div>
  );
};

export default ExportDynamoDBTable;
