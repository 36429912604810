import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ChooseBundleLoan, StartScreen, Greeting, SharedAppBundle, SharedAppEmail, Decision } from '../../application';
import Loading from "../../../utils/loading";
import { useParams } from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine, assign } from 'xstate';
import { Box } from "theme-ui";
import {
  Heading,
  Card as LumosCard,
  Modal,
  ModalControls
} from "@loanpal/lumos__core";
import {
  phoneDigits,
  moneyStringToNumber
} from "../../../utils/format";
import { HomeOccupationEnum } from "../../../utils/types";
import { BackBar } from "../../application/back-button";
import ProgressSteps from "../../application/progress-steps";
import logo from "../../../images/goodleap-white-orange.svg";
// api
import { API, graphqlOperation } from "aws-amplify";
import { getSharedApp } from "../../../graphql/queries";
import { default as ErrorPage } from "../../../utils/error";

const fetchSharedApp = async (context) => {
  try {
    const appData = await API.graphql(
      graphqlOperation(getSharedApp, { id: context.appid })
    );
    return(appData.data.getSharedApp);
  } catch (err) {
    console.log("error fetching SharedApp", err);
  }
}

var clearOtherFields = assign({
})

var setSelectedLoan = assign({
  selectedLoan: function (_, e) { return e.input.solarLoanId; },
  selectedLoanAmount: function (_, e) { return e.input.selectedLoanAmount; },
  selectedBundleAmount: function (_, e) { return e.input.selectedBundleAmount; },
  solarLoan: function (_, e) { return e.input.solarLoan; },
  bundleTag: function (_, e) { return e.input.bundleTag; },
  solarLoanAmount: function (_, e) { return e.input.solarLoanAmount; },
  selectedAction: function (_, e) { return e.input.selectedAction; },
  selectedCategory: function (_, e) { return e.input.selectedCategory; },
  selectedOffer: function (_, e) { return e.input.selectedOffer; },
  dealerFee: function (_, e) { return e.input.dealerFee; },
  selectedStates: function (_, e) { return e.input.selectedStates; },
  selectedState: function (_, e) { return e.input.selectedState; }
});

var setCoborrower = assign({
  hasCoborrower: function(_, e) { return e.input.hasCoborrower; },
  steps: function(_,e) {
    const { hasCoborrower } = e.input
    if (hasCoborrower === 'yes') return setProgress(initialProgress, ["intro"])
    return setProgress(
      initialProgress.filter(x => x.key !== "coborrower"),
      ["intro"]
    )
  }
})

var setBorrowerData = assign({
  borrowerInfo: function(_, e) { return {
    firstName: e.input.firstName,
    lastName: e.input.lastName,
    dob: e.input.dob,
    ssn: e.input.ssn,
    mobile: e.input.mobile,
    secondaryPhoneNumber: e.input.secondaryPhoneNumber,
    email: e.input.email,
  }}
});

var setCoBorrowerData = assign({
  coborrowerInfo: function(_, e) { return {
    firstName: e.input.firstName,
    lastName: e.input.lastName,
    dob: e.input.dob,
    ssn: e.input.ssn,
    mobile: e.input.mobile,
    secondaryPhoneNumber: e.input.secondaryPhoneNumber,
    email: e.input.email,
  }}
});

var setBorrowerLocationData = assign({
  borrowerLocation: function(_, e) {
    return {
    homeOwnership: e.input.homeOwnership,
    totalMonthlyPayment: e.input.totalMonthlyPayment,
    totalRemainingBalance: e.input.totalRemainingBalance,
    homeOccupancy: e.input.homeOccupancy,
    isCoborrowerMailingSameAsBorrower: e.input.isCoborrowerMailingSameAsBorrower,
    isMobileOrManufacturedHome: e.input.type === "MANUFACTURED_HOME" ? true : false,
    subjectProperty: {
        street1: e.input.subjectProperty.street1,
        street2: e.input.subjectProperty.street2,
        city: e.input.subjectProperty.city,
        state: e.input.subjectProperty.state,
        zip: e.input.subjectProperty.zip
    },
    address: e.input.homeOccupancy === "PRIMARY" ? {
      street1: e.input.subjectProperty.street1,
        street2: e.input.subjectProperty.street2,
        city: e.input.subjectProperty.city,
        state: e.input.subjectProperty.state,
        zip: e.input.subjectProperty.zip
    } : {
      street1: e.input.address.street1,
      street2: e.input.address.street2,
      city: e.input.address.city,
      state: e.input.address.state,
      zip: e.input.address.zip
    }
  }}
});

var setBorrowerDetailsData = assign({
  borrowerDetails: function(_, e) {
    return {
    employmentStatus: e.input.employmentStatus,
    occupation: e.input.occupation,
    employmentDuration: e.input.employmentDuration,
    annualIncome: e.input.annualIncome,
    citizenshipStatus: e.input.citizenshipStatus,
    spokenLanguage: e.input.spokenLanguage
  }}
});

var setCoBorrowerLocationData = assign({
  coborrowerLocation: function(_, e) {
    return {
    homeOwnership: e.input.homeOwnership,
    totalMonthlyPayment: e.input.totalMonthlyPayment,
    totalRemainingBalance: e.input.totalRemainingBalance,
    homeOccupancy: e.input.homeOccupancy,
    isCoborrowerMailingSameAsBorrower: e.input.isCoborrowerMailingSameAsBorrower,
    subjectProperty: {
        street1: e.input.subjectProperty.street1,
        street2: e.input.subjectProperty.street2,
        city: e.input.subjectProperty.city,
        state: e.input.subjectProperty.state,
        zip: e.input.subjectProperty.zip
    },
    address: e.input.homeOccupancy === "PRIMARY" ? {
      street1: e.input.subjectProperty.street1,
        street2: e.input.subjectProperty.street2,
        city: e.input.subjectProperty.city,
        state: e.input.subjectProperty.state,
        zip: e.input.subjectProperty.zip
    } : {
      street1: e.input.address.street1,
      street2: e.input.address.street2,
      city: e.input.address.city,
      state: e.input.address.state,
      zip: e.input.address.zip
    }
  }}
});


var setCoBorrowerDetailsData = assign({
  coborrowerDetails: function(_, e) {
    return {
    employmentStatus: e.input.employmentStatus,
    employmentDuration: e.input.employmentDuration,
    occupation: e.input.occupation,
    annualIncome: e.input.annualIncome,
    citizenshipStatus: e.input.citizenshipStatus,
    spokenLanguage: e.input.spokenLanguage
  }}
});

var setDisclosuresData = assign({
  disclosuresDetails: function(_, e) {
    return {
    electronicConsent: e.input.electronicConsent,
    disclosureToken: e.input.token
  }}
});

var setApplicationResponse = assign({
  applicationResponse: function (_, e) { return e.input.applicationResponse; },
  submitted: function (_, e) { return e.input.submitted; }
})

const initialProgress = [
  { key: 'intro', title: 'Intro', complete: false },
  { key: 'borrower', title: 'Borrower information', complete: false },
  { key: 'coborrower', title: 'Co-borrower information', complete: false },
  { key: 'disclosure', title: 'Disclosures', complete: false },
  { key: 'review', title: 'Review', complete: false },
];

const setProgress = (steps, keys) =>
  steps.map(x => {
    if (keys.includes(x.key)) return Object.assign({}, x, { complete: true })
    return Object.assign({}, x, { complete: false })
  })

const setIntroProgress = assign({
  steps: ({ steps }) => setProgress(steps, ["intro"])
});

const setBorrowerProgress = assign({
  steps: ({ steps }) => setProgress(steps, ["intro"])
});

const setCoborrowerProgress = assign({
  steps: ({ steps }) => setProgress(steps, ['intro', 'borrower']),
});

const setDisclosureProgress = assign({
  steps: ({ steps }) => setProgress(steps, ['intro', 'borrower', 'coborrower']),
});

const setReviewProgress = assign({
  steps: ({ steps }) => setProgress(steps, ['intro', 'borrower', 'coborrower', 'disclosure']),
});

const setSubmitProgress = assign({
  steps: ({ steps }) => setProgress(steps, ['intro', 'borrower', 'coborrower', 'disclosure', 'review']),
});

const getReferenceId = (refId, mobile, lastName, bundleTag) => {
  return refId === "lastName" ? lastName
          : refId === "mobilePhone" ? phoneDigits(mobile)
          : refId === "goodleapplus" ? bundleTag
          : ""
}

const getPropertyInfo = (borrowerLocation, coborrowerLocation) => {
  const property = borrowerLocation.subjectProperty
  const subjectProperty = {
    street: property.street1,
    ...(property.street2 !== "" && { street2: property.street2 }),
    city: property.city,
    state: property.state,
    zip: property.zip
  }
  let mortgageTotalBalances
  let mortgageTotalPayments

  if (borrowerLocation) {
    const { totalMonthlyPayment, totalRemainingBalance } = borrowerLocation
    mortgageTotalPayments = totalMonthlyPayment
      ? { value: totalMonthlyPayment, type: "USD" }
      : undefined
    mortgageTotalBalances = totalRemainingBalance
      ? { value: totalRemainingBalance, type: "USD" }
      : undefined
  } else if (coborrowerLocation) {
    const { totalMonthlyPayment, totalRemainingBalance } = coborrowerLocation
    mortgageTotalPayments = totalMonthlyPayment
      ? { value: totalMonthlyPayment, type: "USD" }
      : undefined
    mortgageTotalBalances = totalRemainingBalance
      ? { value: totalRemainingBalance, type: "USD" }
      : undefined
  }

  return {
    address: subjectProperty,
    mortgageTotalBalances,
    mortgageTotalPayments,
    isMobileOrManufacturedHome: borrowerLocation.isMobileOrManufacturedHome
  }
}

const getApplicant = (borrowerInfo, borrowerLocation, borrowerDetails)  => ({
  firstName: borrowerInfo.firstName,
  lastName: borrowerInfo.lastName,
  dob: borrowerInfo.dob,
  ssn: borrowerInfo.ssn,
  homeOccupancy: borrowerLocation.homeOccupancy,
  homeOwnership: borrowerLocation.homeOwnership,
  annualIncome: {
    value: borrowerDetails.annualIncome,
    type: "USD",
  },
  email: borrowerInfo.email,
  primaryPhoneNumber: {
    value: borrowerInfo.mobile,
    type: "MOBILE"
  },
  ...(borrowerInfo.secondaryPhoneNumber.length && {
    secondaryPhoneNumber: {
      value: borrowerInfo.secondaryPhoneNumber,
      type: "HOME"
    },
  }),
  electronicConsent: true,
  employmentDuration: borrowerDetails.employmentDuration,
  spokenLanguage: borrowerDetails.spokenLanguage,
  employmentStatus: borrowerDetails.employmentStatus,
  occupation: borrowerDetails.occupation,
  citizenshipStatus: borrowerDetails.citizenshipStatus,
  address: {
    street: borrowerLocation.address.street1,
    ...(borrowerLocation.address.street2 !== "" && {
      street2: borrowerLocation.address.street2,
    }),
    city: borrowerLocation.address.city,
    state: borrowerLocation.address.state,
    zip: borrowerLocation.address.zip,
  },
});

const getCoApplicant = (borrowerLocation, coborrowerLocation, coborrowerInfo, coborrowerDetails) => {
  let useAddress = coborrowerLocation.address || coborrowerLocation.subjectProperty;
  if (coborrowerLocation.homeOccupancy === HomeOccupationEnum.PRIMARY)
    useAddress = borrowerLocation.subjectProperty;
  return {
    firstName: coborrowerInfo.firstName,
    lastName: coborrowerInfo.lastName,
    dob: coborrowerInfo.dob,
    ssn: coborrowerInfo.ssn,
    homeOccupancy: coborrowerLocation.homeOccupancy,
    homeOwnership: coborrowerLocation.homeOwnership,
    annualIncome: {
      value: coborrowerDetails.annualIncome,
      type: "USD",
    },
    email: coborrowerInfo.email,
    primaryPhoneNumber: {
      value: coborrowerInfo.mobile,
      type: "MOBILE"
    },
    ...(coborrowerInfo.secondaryPhoneNumber.length && {
      secondaryPhoneNumber: {
        value: coborrowerInfo.secondaryPhoneNumber,
        type: "HOME"
      },
    }),
    electronicConsent: true,
    employmentDuration: coborrowerDetails.employmentDuration,
    spokenLanguage: coborrowerDetails.spokenLanguage,
    employmentStatus: coborrowerDetails.employmentStatus,
    occupation: coborrowerDetails.occupation,
    citizenshipStatus: coborrowerDetails.citizenshipStatus,
    address: {
      street: useAddress.street1,
      ...(useAddress.street2 !== "" && {
        street2: useAddress.street2,
      }),
      city: useAddress.city,
      state: useAddress.state,
      zip: useAddress.zip,
    },
  };
};

const getSalesRep = (payloadSalesRep) => ({
  firstName: payloadSalesRep.firstName ?? '',
  lastName: payloadSalesRep.lastName ?? '',
  email: payloadSalesRep.email,
  phone: payloadSalesRep.phone ?? '',
});

const buildPayload = (context) => {
  const {
    borrowerDetails,
    borrowerInfo,
    borrowerLocation,
    bundleTag,
    selectedCategory,
    selectedOffer,
    selectedLoanAmount,
    sharedApp,
    disclosuresDetails,
    solarLoan,
    hasCoborrower
  } = context;
  const autopay = solarLoan.enrollments.find(element => element.name === "AUTOPAY") ? true : false;
  const payload = {
    referenceNumber: getReferenceId(sharedApp.referenceId, borrowerInfo.mobile, borrowerInfo.lastName, bundleTag),
    categoryId: selectedCategory,
    offerId: selectedOffer,
    disclosureToken: disclosuresDetails.disclosureToken,
    subjectProperty: getPropertyInfo(borrowerLocation, null),
    applicant: getApplicant(borrowerInfo, borrowerLocation, borrowerDetails),
    submittingUser: getSalesRep(sharedApp.submittingUser),
    amount: { value: moneyStringToNumber(selectedLoanAmount), type: "USD" },
    applicantSignature: disclosuresDetails.electronicConsent
  }
  if (hasCoborrower === "yes") {
    const { coborrowerLocation, coborrowerInfo, coborrowerDetails } = context
    const coApplicant = getCoApplicant(borrowerLocation, coborrowerLocation, coborrowerInfo, coborrowerDetails);
    if (autopay) {
      return JSON.stringify({ ...payload, coApplicant, enrollments: ["AUTOPAY"] });
    } else {
      return JSON.stringify({ ...payload, coApplicant });
    }
  } else {
    if (autopay) {
      return JSON.stringify({...payload, enrollments: ["AUTOPAY"] })
    } else {
      return JSON.stringify(payload)
    }
  }
}

const applicationPayload = async (context) => {
  try {
    const payload = buildPayload(context);
    console.log("PAYLOAD", JSON.parse(payload));
    return JSON.parse(buildPayload(context));
  } catch (e) {
    console.log("ERROR building Payload")
  }
}

const hasCoborrower = context => {
  return context.hasCoborrower === 'yes' ? true : false;
}

const applicationFormMachine = createMachine(
  {
    predictableActionArguments: true,
    preserveActionOrder: true,
    id: 'applicationForm',
    initial: 'fetchSharedApp',
    context: {
      steps: initialProgress,
      clientId: undefined,
      appid: undefined,
      sharedApp: undefined,
      disclosures: undefined,
      solarLoan: undefined,
      referenceNumber: undefined,
      bundleClientId: undefined,
      selectedLoan: undefined,
      selectedLoanAmount: undefined,
      selectedBundleAmount: undefined,
      solarLoanAmount: undefined,
      bundleTag: undefined,
      selectedAction: undefined,
      selectedCategory: undefined,
      selectedOffer: undefined,
      dealerFee: undefined,
      selectedStates: undefined,
      selectedState: undefined,
      borrowerInfo: undefined,
      borrowerLocation: undefined,
      borrowerDetails: undefined,
      hasCoborrower: false,
      coborrowerInfo: undefined,
      coborrowerLocation: undefined,
      coborrowerDetails: undefined,
      disclosuresDetails: undefined,
      applicationResponse: undefined,
      applicationPayload: undefined,
      submitted: false
    },
    states: {
      fetchSharedApp: {
        id: 'fetchSharedApp',
        invoke: {
          src: fetchSharedApp,
          onDone: {
            target: 'chooseBundleLoan',
            actions: assign({ sharedApp: (context, event) => event.data }),
          }
        }
      },
      chooseBundleLoan: {
          id: 'chooseBundleLoan',
          initial: 'main',
          states: {
            main: {
              on: {
                  SELECTED_LOAN: [
                  {
                      target: 'handDeviceToConsumerPrompt',
                      actions: [clearOtherFields, setSelectedLoan, setCoborrower]
                  }
                  ],
                  SHARE_APPLICATION: [
                  {
                    target: '#applicationForm.shareApplication',
                    actions: [clearOtherFields, setSelectedLoan]
                  }
                  ]
              }
            },
            handDeviceToConsumerPrompt: {
              on: {
                ACKNOWLEDGE: '#applicationForm.startApplication.main',
                NEXT_PAGE: '#applicationForm.startApplication.main'
              }
            },
          }
      },
      startApplication: {
          initial: 'main',
          entry: ['setIntroProgress'],
          states: {
            main: {
              meta: {
                title: 'Borrower information',
              },
              on: {
                  PREVIOUS_PAGE: '#applicationForm.chooseBundleLoan.main',
                  NEXT_PAGE: {
                    target: 'address',
                    actions: [setBorrowerData]
                  }
                }
              },
              address: {
                on: {
                    PREVIOUS_PAGE: '#applicationForm.startApplication.main',
                    NEXT_PAGE: {
                      target: 'details',
                      actions: [setBorrowerLocationData]
                    }
                  }
              },
              details: {
                entry: ['setBorrowerProgress'],
                meta: {
                  title: 'Borrower details',
                },
                on: {
                  PREVIOUS_PAGE: '#applicationForm.startApplication.address',
                  NEXT_PAGE: [
                    {
                      target: 'coborrowerPrompt',
                      cond: (context, event) => context.hasCoborrower === 'yes',
                      actions: [setBorrowerDetailsData]
                    },
                    {
                      target: 'disclosures',
                      actions: [setBorrowerDetailsData]
                    }
                  ]
                }
              },
              coborrowerPrompt: {
                on: {
                  ACKNOWLEDGE: 'coborrower',
                  NEXT_PAGE: 'coborrower'
                }
              },
              coborrower: {
                entry: ['setCoborrowerProgress'],
                meta: {
                    title: 'Co-borrower information',
                },
                on: {
                  NEXT_PAGE: {
                    target: 'coborrowerAddress',
                    actions: [setCoBorrowerData]
                  },
                  PREVIOUS_PAGE: '#applicationForm.startApplication.details'
                }
              },
              coborrowerAddress: {
                on: {
                  NEXT_PAGE: {
                    target: 'coborrowerDetails',
                    actions: [setCoBorrowerLocationData]
                  },
                  PREVIOUS_PAGE: '#applicationForm.startApplication.coborrower'
                }
              },
              coborrowerDetails: {
                meta: {
                  title: 'Co-borrower details',
                },
                on: {
                  NEXT_PAGE: {
                    target: 'disclosures',
                    actions: [setCoBorrowerDetailsData]
                  },
                  PREVIOUS_PAGE: '#applicationForm.startApplication.coborrowerAddress'
                }
              },
              disclosures: {
                entry: ['setDisclosureProgress'],
                  on: {
                    NEXT_PAGE: {
                      target: 'edit',
                      actions: [setDisclosuresData],
                    },
                    PREVIOUS_PAGE: [
                      {
                        target: '#applicationForm.startApplication.coborrower',
                        cond: 'hasCoborrower',
                      },
                      {
                        target: '#applicationForm.startApplication.details'
                      }
                    ]
                  }
              },
              edit: {
                entry: ['setReviewProgress'],
                on: {
                  SUBMIT: 'submittingApplication',
                  PREVIOUS_PAGE: '#applicationForm.startApplication.disclosures',
                  EDIT_BORROWER_INFO: '#applicationForm.startApplication.main',
                  EDIT_BORROWER_LOCATION: '#applicationForm.startApplication.address',
                  EDIT_BORROWER_DETAILS: '#applicationForm.startApplication.details',
                  EDIT_COBORROWER_INFO: '#applicationForm.startApplication.coborrower',
                  EDIT_COBORROWER_LOCATION: '#applicationForm.startApplication.coborrowerAddress',
                  EDIT_COBORROWER_DETAILS: '#applicationForm.startApplication.coborrowerDetails',
                  EDIT_DISCLOSURES: '#applicationForm.startApplication.disclosures'
                }
              },
              submittingApplication: {
                entry: ['setSubmitProgress'],
                invoke: {
                  id: 'submitLoan',
                  src: (context, event) => applicationPayload(context),
                  onDone: {
                      target: '#applicationForm.applicationResponse',
                      actions: assign({ applicationPayload: (context, event) => event.data })
                  },
                  onError: {
                    target: "#applicationForm.error"
                  },
                }
              }
            }
          },
          shareApplication: {
            initial: 'main',
            states: {
                main: {
                  on: {
                    PREVIOUS_PAGE: '#applicationForm.chooseBundleLoan'
                  }
                }
            }
        },
        applicationResponse: {
          on: {
            APPLICATION_RESPONSE: {
              target: 'decision',
              actions: [
                (context, event) => console.log("IN APPLICATION RESPONSE", event.input),
                setApplicationResponse
              ]
            }
          }
        },
        decision: {
          // entry: (context, event) => postApplicationResponse(context)
          type: 'final'
        },
        error: {
          type: 'final'
        }
    },
  },
  {
    actions: {
      setIntroProgress,
      setBorrowerProgress,
      setCoborrowerProgress,
      setDisclosureProgress,
      setReviewProgress,
      setSubmitProgress,
      setCoborrower,
      buildPayload
    },
    guards: {
      hasCoborrower
    }
  });


const BundleLoanForm = () => {
  const { id, client, bundleClientId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectFromSharedApp = Boolean( queryParams.size > 0 );
  const [state, send, service] = useMachine(applicationFormMachine, { context:
    {
      clientId: client,
      appid: id,
      bundleClientId: bundleClientId,
      sharedApp: undefined,
      disclosures: undefined,
      selectedLoan: undefined,
      paymentAmount: undefined,
      selectedLoanAmount: undefined,
      selectedBundleAmount: undefined,
      solarLoanAmount: undefined,
      bundleTag: undefined,
      referenceNumber: undefined,
      selectedAction: undefined,
      selectedCategory: undefined,
      selectedOffer: undefined,
      dealerFee: undefined,
      selectedStates: undefined,
      selectedState: undefined,
      steps: initialProgress,
      borrowerInfo: undefined,
      borrowerLocation: undefined,
      borrowerDetails: undefined,
      hasCoborrower: undefined,
      coborrowerInfo: undefined,
      coborrowerLocation: undefined,
      coborrowerDetails: undefined,
      disclosuresDetails: undefined,
      applicationResponse: undefined,
      applicationPayload: undefined,
      submitted: false
    }
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      console.log("STATE", state);
    });
    return subscription.unsubscribe;
  }, [service]);

  const pageProps = { state, send };

  const renderNode = () => {
    switch (true) {
    //   case state.matches('fetchSharedApp'):
    //   case state.matches('fetchCategories'):
    //     return <Loading />;
      case state.matches('error'):
        return <ErrorPage sharedApp={state.context.sharedApp} errorMessage={"ERROR no submit app response"} />;
      case state.matches('applicationResponse'):
        return <Decision {...pageProps} />;
      case state.matches('decision'):
        return <Decision {...pageProps} />;
      case state.matches('chooseBundleLoan'):
         return <ChooseBundleLoan {...pageProps} />;
      case state.matches('startApplication'):
        return <SharedAppBundle {...pageProps} />;
      case state.matches('shareApplication'):
        return <SharedAppEmail {...pageProps} />;
      // case state.matches('offerSelect'):
      //   return <OfferSelect {...pageProps} />;
      // case state.matches('sendEmail'):
      //   return <EmailInput {...pageProps} />;
      default:
        return <Loading />;
    }
  };
  return (
    <>
    {state.matches('chooseBundleLoan') && (
     <Box
      sx={{
        maxWidth: "960px",
        backgroundColor: "#ffffff",
        mx: "auto",
         display: 'flex',
        flexDirection: 'column',
        // minHeight: '100vh',
      }}>
      <>
      <StartScreen />
        <Box
          sx={{
            // width: "100%",
            margin: "0 auto",
            padding: "10px",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            borderColor: '#d7d7d7',
            backgroundColor: "#ffffff",
            m: 2 }}
        >
          <Greeting logo="GLP" redirectFromSharedApp={redirectFromSharedApp} />
          <Box sx={{ bg: '#ffffff' }}>{renderNode()}</Box>
        </Box>
       </>
      </Box>)}
    {(state.matches('startApplication') || state.matches('applicationResponse')) && (
      <Box sx={{ bg: '#f5f5f5' }}>
        <Box
          sx={{
            maxWidth: "100%",
            mx: "auto",
            backgroundColor: "#f5f5f5",
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
            <Box>
            <Heading
              className="heading-box"
              sx={{
                width: "960px",
                margin: "0 auto",
                backgroundColor: "#003439",
              }}
            >
              <img
                src={logo}
                alt="GoodLeap"
                style={styles.logo}
                className="logo"
              />
            </Heading>
          </Box>
          <LumosCard
              sx={{
                width: 960,
                margin: "0 auto",
              }}
              className="card-form"
            >
            {state.matches('startApplication') && <BackBar onClick={() => send('PREVIOUS_PAGE')}>Back</BackBar>}
            <ProgressSteps steps={state.context.steps} sx={{ mb: 4, mt: 4 }} />
            {renderNode()}
            </LumosCard>
        </Box>
      </Box>)}
    {state.matches('shareApplication') && (<Box sx={{ bg: '#f5f5f5' }}>{renderNode()}</Box>)}
    {state.matches('decision') && (<Box sx={{ bg: '#f5f5f5' }}>{renderNode()}</Box>)}
    {state.matches('error') && (<Box sx={{ bg: '#f5f5f5' }}>{renderNode()}</Box>)}
    <Modal
        isOpen={state.matches("chooseBundleLoan.handDeviceToConsumerPrompt")}
        title="Ready to start the process?"
        onDismiss={() => send("ACKNOWLEDGE")}
      >
      Please hand the device to the borrower to complete the customer form
      now.
      <ModalControls
        actions={[
          {
            onClick: () => send("ACKNOWLEDGE"),
            label: "Start now"
          }
        ]}
      />
    </Modal>
    </>
  )
}

const styles = {
  logo: {
    width: 150,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 4,
  }
};

export default BundleLoanForm;
