import React from "react";
import { Box } from "theme-ui";
import { Card, Heading, Icon } from "@loanpal/lumos__core";
import IconList from "./icon-list";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        pt: 6,
        "@media screen and (max-width: 80em)": {
          pt: 4,
        },
      }}
    >
      <Box sx={{ width: "100%", mx: "auto", maxWidth: 500 }}>
        <Card variant="float" sx={{ px: 4 }}>
          <Heading
            variant="h3"
            as="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Icon variant="color-warning" sx={{ mr: 3 }} size="md" />
            Well this doesn&apos;t seem right.
          </Heading>
          <Box
            sx={{
              mt: 1,
              pb: 3,
              mb: 3,
              borderBottom: "1px solid",
              borderColor: "neutral.l3",
            }}
          >
            It looks like we&apos;ve encountered an unexpected problem.
          </Box>
          <section>
            <Heading variant="h5" as="div">
              What should I do?
            </Heading>
            <Box
              as="ul"
              sx={{ p: 0, mt: "12px", listStyleType: "none", ml: -2 }}
            >
              <IconList icon="refresh">
                Try refreshing, we automatically track these issues.
              </IconList>
              <IconList icon="envelope">
                If the issue continues, please&nbsp;
                <a href="mailto:integrations@goodleap.com">Email Us</a>
              </IconList>
            </Box>
          </section>
        </Card>
      </Box>
    </Box>
  );
};

export default ErrorPage;
