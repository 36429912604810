import logo from "../../images/goodleap-white-orange.svg";

const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://goodleap.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} className="App-logo" alt="GoodLeap" />
        </a>
      </header>
    </div>
  );
};

export default Home;
