import React, { useState, useEffect } from "react";
import { Box, Text } from "theme-ui";
import Loading from "../../../utils/loading";
import { Divider } from "@aws-amplify/ui-react";
import { DisplayLoan } from "../../application";
import {
         Heading,
         Icon,
         Card
        } from '@loanpal/lumos__core';
import { getLoanById } from "../../../utils/api";
import { styles } from "../styles";

const Declined = ({ state, send }) => {
    const [loanDetails, setLoanDetails] = useState();
    const [decision] = useState(state.context.applicationResponse);
    const [sharedApp] = useState(state.context.sharedApp);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (decision && sharedApp) {
        getLoan();
      }
    // eslint-disable-next-line
    }, []);

    const getLoan = async () => {
      try {
        setLoading(true);
        await getLoanById(sharedApp.clientId, decision.id).then(loan => {
          if (loan) {
            setLoanDetails(loan);
          }
        });
      } catch (err) {
        console.log("Error getting underlying loan", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    return (
      <>
          {loading && <Loading />}
          {loanDetails && (
          <>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pl: 2, pr: 2, mb: 3, fontSize: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Heading variant="h5" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '26px' }}>
                {loanDetails.borrower.firstName}, your application has been declined
             </Heading>
            </Box>
          </Box>
          <Box>
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <Card>
                <Box
                    sx={{
                        display: 'flex',
                        rowGap: '15px',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                    >
                    <Box
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        }}
                    >
                    <Icon
                        variant="circle-file-edit"
                        size="lg"
                        sx={{
                            mr: 2,
                            minHeight: '48px',
                            minWidth: '48px'
                        }}
                    />
                        <Text
                            as="div"
                            sx={{
                                fontWeight: '500',
                            }}
                        >
                        Unfortunately, we are unable to provide you with a GoodLeap loan to finance your project.
                        <br />
                        <br />
                        You will receive an Adverse Action notice detailing why we were unable to provide you with a{' '}
                        GoodLeap loan today.
                        <br />
                        <br />
                        If you have any questions about this decision, call the GoodLeap team at (844) 910-0111.
                        </Text>
                    </Box>
                </Box>
            </Card>
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <DisplayLoan loan={loanDetails}  loanStatus={null} loanAmount={loanDetails.amount.value} consumerDisplay={true} conditional={true} />
          </Box>
          </>
        )}
      </>
    )
}

export default Declined;