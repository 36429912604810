import React, { useRef, useState } from "react"
import {
  Card,
  Heading,
  Button,
  ButtonLink
} from "@loanpal/lumos__core";
import { DefinitionList } from '../../utils/definition';
import { formatDate, numberToMoney } from '../../utils/format';
import { HomeOccupationEnum, HomeOwnershipEnum, CitizenshipStatus, EmploymentStatus, Language, SubjectPropertyType } from "../../utils/types";
import Address from '../../utils/address';
import { Box, Image } from 'theme-ui';
import ReCAPTCHA from "react-google-recaptcha";
import { Flex } from '@aws-amplify/ui-react';

// api
import { API } from "aws-amplify";

const SummaryCard = ({ title, index, editEvent, send, children }) => (
  <Card sx={{ mb: 3, pb: 3 }} data-id={index}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {title && (
        <Heading variant="h4" sx={{ mb: 2 }}>
          {title}
        </Heading>
      )}
      <Box sx={{ ml: "auto" }}>
        <ButtonLink onClick={() => send(editEvent)}>Edit</ButtonLink>
      </Box>
    </Box>
    {children}
  </Card>
);

const formatOccupancy = {
  [HomeOccupationEnum.PRIMARY]: "Primary residence",
  [HomeOccupationEnum.SECONDARY]: "Second or vacation home",
  [HomeOccupationEnum.INVESTMENT]: "Investment property",
  [HomeOccupationEnum.OTHER]: "I do not occupy or own this home"
}

const formatOwnership = {
  [HomeOwnershipEnum.OWNED_WITH_MORTGAGE]: "Yes, with one or more mortgages",
  [HomeOwnershipEnum.OWNED_FREE_AND_CLEAR]:
    "Yes, free and clear with no mortgages",
  [HomeOwnershipEnum.NOT_OWNED]: "No, I do not own this home"
}

const formatCitizenShip = {
  [CitizenshipStatus.US_CITIZEN]: "U.S. Citizen",
  [CitizenshipStatus.LAWFUL_PERMANENT_RESIDENT_ALIEN]:
    "Lawful Permanent Resident Alien",
  [CitizenshipStatus.OTHER]: "Other"
}

const formatEmployment = {
  [EmploymentStatus.EMPLOYED]: "Employed",
  [EmploymentStatus.NOT_EMPLOYED]: "Not employed",
  [EmploymentStatus.RETIRED]: "Retired",
  [EmploymentStatus.OTHER]: "Other"
}

const formatLanguage = {
  [Language.ENGLISH]: "English",
  [Language.SPANISH]: "Spanish"
}

const EditUserInfo = ({ type, send, state }) => {
  const rootEvent = type === "borrower" ? "EDIT_BORROWER" : "EDIT_COBORROWER"
  const info = `${type}Info`
  const details = `${type}Details`
  const location = `${type}Location`
  return (
    <>
      {type === "borrower" && (<SummaryCard
        index={`${type}-info`}
        title={`Where will the work be done?`}
        editEvent={`${rootEvent}_INFO`}
        send={send}
      >
        <DefinitionList
          description={[
            {
              title: "Project Address",
              value: (
                <Address
                  address={state.context[location].subjectProperty}
                />
              )
            }
          ].filter(Boolean)
          }
        />
      </SummaryCard>)}
      <SummaryCard
        index={`${type}-info`}
        title={`${
          type === "borrower" ? "Borrower" : "Co-borrower"
        } information`}
        editEvent={`${rootEvent}_INFO`}
        send={send}
      >
        <DefinitionList
          description={[
            {
              title: "Name",
              value: `${state.context[info].firstName} ${state.context[info].lastName}`
            },
            {
              title: "Date of birth",
              value: (formatDate(state.context[info].dob))
            },
            {
              title: "Social security number",
              value: state.context[info].ssn
            },
            {
              title: "Mobile phone number",
              value: state.context[info].mobile
            },
            {
              title: "Email address",
              value: state.context[info].email
            }
          ]}
        />
      </SummaryCard>
      <SummaryCard
        index={`${type}-location`}
        title={`${
          type === "borrower" ? "Borrower" : "Co-borrower"
        } location`}
        editEvent={`${rootEvent}_LOCATION`}
        send={send}
      >
        <DefinitionList
          // @ts-ignore
          description={[
            location === "borrowerLocation" && {
              title: "Address",
              value: (
                <Address
                  address={state.context[location].subjectProperty}
                />
              )
            },
            {
              title: "Is this property a mobile/manufactured home?",
              value:
                state.context[location].isMobileOrManufacturedHome === SubjectPropertyType.MANUFACTURED_HOME
                      ? 'Yes'
                      : 'No',
              emptyState: null
            },
            {
              title: "This home is your",
              value:
                formatOccupancy[
                  state.context[location].homeOccupancy ||
                    HomeOccupationEnum.PRIMARY
                ]
            },
            {
              title: "Do you own this home?",
              value: state.context[location].homeOwnership
                ? formatOwnership[
                    state.context[location].homeOwnership
                  ]
                : undefined,
              emptyState: "N/A"
            },
            {
              title: "Estimated monthly mortgage payment",
              value: state.context[location].totalMonthlyPayment ? (
                numberToMoney(state.context[location].totalMonthlyPayment)
              ) : (
                undefined
              ),
              emptyState: "N/A"
            },
            {
              title: "Estimated mortgage balance",
              value: state.context[location].totalRemainingBalance ? (
                numberToMoney(state.context[location].totalRemainingBalance)
              ) : (
                undefined
              ),
              emptyState: "N/A"
            },
            state.context[location].homeOccupancy !==
            HomeOccupationEnum.PRIMARY
              ? {
                  title: "Mailing address",
                  value: (
                    <Address
                      address={
                        state.context[location].address.street1
                          ? state.context[location].address
                          : state.context[location].subjectProperty
                      }
                    />
                  )
                }
              : null,
            state.context[location].homeOccupancy ===
              HomeOccupationEnum.PRIMARY && location === "coborrowerLocation"
              ? {
                  title: "Mailing address",
                  value: (
                    <Address
                      address={
                        state.context[location].subjectProperty.street1
                          ? state.context[location].subjectProperty
                          : state.context[location].address
                      }
                    />
                  )
                }
              : null
          ].filter(Boolean)}
        />
      </SummaryCard>
      <SummaryCard
        index={`${type}-details`}
        title={`${
          type === "borrower" ? "Borrower" : "Co-borrower"
        } details`}
        send={send}
        editEvent={`${rootEvent}_DETAILS`}
      >
        <DefinitionList
          description={[
            {
              title: "Employment status",
              value: state.context[details].employmentStatus
                ? formatEmployment[
                    state.context[details].employmentStatus
                  ]
                : undefined,
              emptyState: "N/A"
            },
            {
              title: "Individual pre-tax annual income",
              value: state.context[details].annualIncome ? (
                numberToMoney(state.context[details].annualIncome)
              ) : (
                undefined
              )
            },
            {
              title: "Citizenship",
              value: state.context[details].citizenshipStatus
                ? formatCitizenShip[
                    state.context[details].citizenshipStatus
                  ]
                : undefined,
              emptyState: "N/A"
            },
            {
              title: "Preferred language",
              value: state.context[details].spokenLanguage
                ? formatLanguage[
                    state.context[details].spokenLanguage
                  ]
                : undefined,
              emptyState: "N/A"
            }
          ]}
        />
      </SummaryCard>
    </>
  )
}


const Summary = ({ state, send, payload }) => {
  const captchaRef = useRef(null);
  const [canApply, setCanApply] = useState(false);

  const pageProps = {
    state,
    send,
    payload,
  };

  const signature = state.context.disclosuresDetails.electronicConsent;

    const handleCaptchaChange = async(value) => {
    const myValues = {
        queryStringParameters: {
          response: value
       },
      };
      const response = await API.post("developerApi", "/siteverify", myValues);
      if (response.success) {
        setCanApply(true);
      } else {
        setCanApply(false);
      }
  }

  return (
    <>
      <EditUserInfo type="borrower" {...pageProps} />
      {!state.context.hasCoborrower && state.context.hasCoborrower === "no" && (
        <Card sx={{ mb: 3, pb: 3 }}>
          <div sx={{ mb: 3 }}>
            <Heading variant="h4">Co-borrower information</Heading>
            You are the only borrower.
          </div>
          <Button onClick={() => send("CHANGE_COBORROWER")} variant="secondary">
            Add co-borrower
          </Button>
        </Card>
      )}
      {state.context.hasCoborrower && state.context.hasCoborrower === "yes" && (
        <EditUserInfo type="coborrower" {...pageProps} />
      )}
    <SummaryCard index="disclosures" send={send} editEvent="EDIT_DISCLOSURES">
        <div
          sx={{
            variant: "text.small",
            color: "neutral.base",
            pb: 1,
            fontWeight: 500
          }}
        >
          Borrower signature
        </div>
        <Card>
          <Image
            alt="borrower signature"
            style={{ width: "100%", height: "auto" }}
            src={signature}
          />
        </Card>
    </SummaryCard>
    <Box
        sx={{
            display: "flex",
            justifyContent: "flex-start",
            pt: 3,
            my: 3,
            borderTop: "1px solid",
            borderTopColor: "neutral.l3",
        }}>
            <ReCAPTCHA
            ref={captchaRef}
            sitekey="6Ld6Kb8hAAAAADN-z4PFwBU-mBk8HcGaWkKtmSs-"
            onChange={handleCaptchaChange}
    />
    </Box>
    <Flex
        direction="row"
        justifyContent="start"
        alignItems="stretch"
        alignContent="flex-end"
        wrap="nowrap"
        gap="1rem"
    >
        {canApply && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => send("SUBMIT")}>Submit to GoodLeap</Button>
            </Box>
        )}
        {!canApply && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button disabled>Submit to GoodLeap</Button>
            </Box>
        )}
    </Flex>
    </>
  )
}

export default Summary
