import React from "react";
import { Box } from "theme-ui";
import {
  Heading
} from "@loanpal/lumos__core";
import logo from "../../images/goodleap-white-orange.svg";

const StartScreen = () => {
  return (
    <Box>
      <Heading
        className="heading-box"
        sx={{
          width: "960px",
          margin: "0 auto",
          backgroundColor: "#003439",
        }}
      >
        <img src={logo} alt="GoodLeap" style={styles.logo} />
      </Heading>
    </Box>
  )
};

const styles = {
  logo: {
    width: 150,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 4,
  }
};

export default StartScreen;