import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css'
import {
   Modal, ModalControls
} from "@loanpal/lumos__core";
import Loading from "../../utils/loading";

// progress
import BorrowerInfo from './borrower-info';
import BorrowerLocation from './borrower-location';
import BorrowerDetails from "./borrower-details";
import Summary from "./summary";

import { getDisclosures } from "../../utils/api";
import Disclosures from './disclosures';

const Main = ({ state, send }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [SharedApp, setSharedApp] = useState(state.context.sharedApp);
  const [disclosuresData, setDisclosures] = useState(state.context.disclosures);

  useEffect(() => {
    fetchSharedApp(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (state.matches('startApplication.disclosures')) {
      setLoading(true);
      fetchDisclosures()
    }
    // eslint-disable-next-line
  }, [state]);

  async function fetchSharedApp(id) {
    try {
      if (state.context.sharedApp) {
        const app = state.context.sharedApp;
        app.loanAmount = state.context.selectedLoanAmount;
        setSharedApp(app);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error fetching SharedApp", err);
    }
  }

const fetchDisclosures = async () => {
    try {
    if (!state.context.disclosures) {
      const appData = await getDisclosures(SharedApp.clientId);
      setDisclosures(appData);
      setLoading(false);
    }
  } catch (err) {
    setLoading(false);
    console.log("error fetching disclosures", err);
  }
}

  return (
    <>
      {(loading || state.matches('startApplication.submittingApplication')) && <Loading />}
      {!loading  && (state.matches("startApplication.main") || state.matches('startApplication.coborrower')) && (
        <BorrowerInfo state={state} send={send} />
      )}
      {(state.matches('startApplication.address') || state.matches('startApplication.coborrowerAddress')) && (
        <BorrowerLocation state={state} send={send} />
      )}
      {(state.matches('startApplication.details') || state.matches("startApplication.coborrowerDetails")) && (
        <BorrowerDetails state={state} send={send} />
      )}
      {disclosuresData && state.matches('startApplication.disclosures') && (

        <Disclosures disclosures={disclosuresData} send={send} />
      )}
      {state.matches('startApplication.edit') && (
        <Summary state={state} send={send} payload={state.context} />
      )}
      <Modal
        title="Add co-borrower information"
        isOpen={state.matches('startApplication.coborrowerPrompt')}
        onDismiss={() => send('NEXT_PAGE')}
      >
        The next few screens will ask you information about your co-borrower.
        <ModalControls
          actions={[
            {
              onClick: () => send('NEXT_PAGE'),
              label: 'OK',
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default Main;
