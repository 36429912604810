import { API, graphqlOperation } from "aws-amplify";
import { updateRequests } from "../graphql/mutations";

import {
  dateTimeStr
} from "./format";

const sandbox_promotionId = "2345f0aa-db7a-42b9-807b-9e8a82b81ae3";
const prod_promotionId =  "26d09475-32ec-448c-bb43-9f70aea55644";
const host = window.location.host;

export const getOffersByChannel = async (clientId, channel) => {
  try {
    const promo = getPromotionId();
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          promotionId: promo,
          channel: channel
        },
      };
      return await API.get("developerApi", "/offers", myInit1)
  } catch (err) {
    console.log("error getting loan by id", err);
    return null;
  }
}

export const getDisclosures = async (clientId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      return await API.get("developerApi", "/disclosures", myInit1)
  } catch (err) {
    console.log("error getting current disclosures", err);
    return null;
  }
}

export const getLoanById = async (clientId, loanId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.get("developerApi", "/loanById", myInit1)
  } catch (err) {
    console.log("error getting loan by id", err);
    return null;
  }
}

export const getLoanByTags = async (clientId, tags) => {
  try {
    const loan = await Promise.all(tags.map(async (tag) => {
      const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          tag: tag
        },
      };
      const loanFound = await API.get("developerApi", "/loanByTag", myInit1)
         return {loan: loanFound, tag: tag};
    }));
    return loan.find((l) => l.loan.data.length === 1);
  } catch (err) {
    console.log("error getting loan by tag", err);
    return {loan: null, tag: ""};
  }
}

export const getLoanByTag = async (clientId, tag) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          tag: tag
        },
      };
      return await API.get("developerApi", "/loanByTag", myInit1)
  } catch (err) {
    console.log("error getting loan by tag", err);
    return false;
  }
}

export const deleteTagByLoanId = async (clientId, loanId, tag) => {
  try {
    const payload = JSON.stringify({
        tags: [
          tag
        ]
    });
    const myInit1 = {
      body: { data: payload || {} },
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.post("developerApi", "/deleteTag", myInit1)
  } catch (err) {
    console.log("error deleting tag", err);
    return false;
  }
}

export const addTagByLoanId = async (clientId, loanId, tag) => {
  try {
    const payload = JSON.stringify({
        tags: [
          tag
        ]
    });
    const myInit1 = {
      body: { data: payload || {} },
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.post("developerApi", "/addTag", myInit1)
  } catch (err) {
    console.log("error adding tag", err);
    return false;
  }
}

export const applyForLoan = async (clientId, payload) => {
  try {
    const myInit1 = {
      body: { data: payload || {} },
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      return await API.post("developerApi", "/submitLoan", myInit1)
  } catch (err) {
    console.log("error submitting loan application", err);
    return null;
  }
}

export const changeOrders = async (clientId, loanId, payload) => {
  try {
    const myInit1 = {
      body: { data: payload || {} },
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.post("developerApi", "/changeOrders", myInit1)
  } catch (err) {
    console.log("error submitting change order", err);
    return false;
  }
}

export const requestLoanDocuments = async (clientId, loanId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.post("developerApi", "/requestLoanDocuments", myInit1)
  } catch (err) {
    console.log("error requesting loan documents", err);
    return false;
  }
}

export const getCategories = async (clientId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      return await API.get("developerApi", "/categories", myInit1)
  } catch (err) {
    console.log("error getting categories", err);
    return false;
  }
}

export const getLimitations = async (clientId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      return await API.get("developerApi", "/limitations", myInit1)
  } catch (err) {
    console.log("error getting limitations", err);
    return false;
  }
}

const getPromotionId = () => {
  // if (host.includes("sandbox") || (host.includes("localhost"))) {
  if (host.includes("sandbox")) {
    return sandbox_promotionId;
  } else  {
    return prod_promotionId;
  }
}

export const getPayment = async (clientId, offerId, loanAmount, enrollments = undefined) => {
    try {
    const promo = getPromotionId();
    const autopay = enrollments && enrollments.length !== 0 && enrollments[0].name === "AUTOPAY";
    let myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          amount: loanAmount,
          offerId: offerId
        },
      };
      if (autopay) myInit1.queryStringParameters = {...myInit1.queryStringParameters, promotionId: promo };
      console.log(autopay, enrollments, myInit1);
      return await API.get("developerApi", "/payments", myInit1)
  } catch (err) {
    console.log("error getting payments", err);
    return false;
  }
}

export const getOfferByLoanId = async (clientId, loanId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.get("developerApi", "/offersByLoanId", myInit1)
  } catch (err) {
    console.log("error getting offer by loan id", err);
    return false;
  }
}

export const getLoanStatus = async (clientId, loanId) => {
  try {
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          loanId: loanId
        },
      };
      return await API.get("developerApi", "/loanStatus", myInit1)
  } catch (err) {
    console.log("error getting loan status", err);
    return false;
  }
}

export const getUserById = async (clientId, userId) => {
  try {
    // get userById
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          userId: userId
        },
      };
    return await API.get("developerApi", "/users", myInit1)
  } catch (err) {
    console.log("error getting user by id", err);
    return false;
  }
}

export const addChannelsToUser = async (clientId, states, sUserId) => {
  let channelsMap = [];
  channelsMap = states.map((st) => {
    let stateMap = [];
    stateMap = st.channels.map((ch) => {
      return  [
        ...stateMap,
        `${st.code}_${ch}`
      ]
    })
    return [
      ...channelsMap,
      ...stateMap.flat()
    ]
  });
  const allChannels = channelsMap.flat();
  try {
        const payload = JSON.stringify({
          channels: allChannels
        });
        const myInit = {
          body: { data: payload || {} },
          queryStringParameters: {
            apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
            userId: sUserId
          },
        };
        return await API.post("developerApi", "/user-channels", myInit);
    } catch (err) {
        console.log("error updating channels", err);
    }
}

const trimAndRemoveSpaces = (str) => {
  // Trim leading and trailing spaces
  str = str.trim();

  // Remove spaces within the string
  str = str.replace(/\s+/g, '');

  return str;
}

export const addLicenseToUser = async (clientId, sUserId, license, state) => {
  try {
        const payload = JSON.stringify({
          id: trimAndRemoveSpaces(license),
          appliesTo: state
        });
        const myInit = {
           body: { data: payload || {} },
           queryStringParameters: {
           apiId: `${clientId}ApiId`,
           apiKey: `${clientId}ApiKey`,
           clientId: clientId,
           userId: sUserId
          },
        };
        return await API.post("developerApi", "/user-license", myInit);
    } catch (err) {
      console.log("error updating user license", err);
       return("Error");
    }
}

export const getStates = async (clientId) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get states
     return await API.get("developerApi", "/states", myInit0)
    } catch (err) {
      console.log("error getting states", err);
      return false;
    }
  }

export const getOffersByCategory = async (clientId, categoryId, env) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          categoryId: categoryId,
          env: env
        },
      };
      // get states
     return await API.get("developerApi", "/offersByCategory", myInit0)
    } catch (err) {
      console.log("error getting offers", err);
      return false;
    }
  }

export const notifyAdminErrorMessage = async (app, id, error, notifySlack = false) => {
  try {
    if (notifySlack) {
      console.log("sending email", app);
      const myParams = {
        queryStringParameters: {
          formUrl: app ? app.formUrl : "",
          clientId: app ? app.clientId : "",
          id: id,
          error: error,
          notifySlack: notifySlack
        }
      };
      await API.post("developerApi", "/notifyAdminErrorMessage", myParams);
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error Notifying Admin:", err);
  }
}

export const updateNotesRequest = async (req, message ) => {
    try {
        let note = req.notes;
        if (note !== null && note.trim() !== "") note = note.concat("\n");
        const _app = { ...req, notes: note.concat(dateTimeStr(Date.now())).concat(" - " + message) };
        await API.graphql(graphqlOperation(updateRequests, { input: _app }));
    } catch (err) {
        console.log("Error updating Request", err);
    }
  }