import React, { useState } from "react";

import { Card,
         Button as LumosButton,
         Heading,
         Icon
    } from '@loanpal/lumos__core';
import { Box, Spinner, Text, Image } from 'theme-ui';
import { Divider } from "@aws-amplify/ui-react";
import {
  Flex
} from "@aws-amplify/ui-react";
import { styles } from "../styles";
import logoGLP from '../../../images/glplus_grey_gradient.svg';

const DisplayConditionalDetails = ({ hiLoan, getStatus, addSnack, consumerDisplay, nextSteps }) => {
    const [loader2, setLoader2] = useState(false);

    const resetStatus = async () =>{
        try {
            setLoader2(true);
            await getStatus(hiLoan.partnerId, hiLoan.id, "hi");
        } catch (err) {
            console.log("error getting loan status", err);
            addSnack({ variant: 'warnint', message: `Error refreshing loan status!` });
            return false;
        } finally {
            setLoader2(false);
        }
    }

    return (
    <Card style={styles.glpcard}>
        {nextSteps &&  (
        <>
        {!consumerDisplay && (
            <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '120px', mb: 1}} />
        )}
        <Heading variant="h4" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '28px' }}>
                Next steps
        </Heading>
         <Box
            sx={{
                display: 'flex',
                rowGap: '15px',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            >
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                }}
            >
              <Icon
                  variant="circle-file-plus"
                  size="lg"
                  sx={{
                    mr: 2,
                    minHeight: '48px',
                    minWidth: '48px'
                  }}
              />
              {consumerDisplay && (
                <Text
                    as="div"
                    sx={{
                        fontWeight: '500',
                    }}
                >
                There are conditions that require your actions. Check your e-mail for instruction on how to proceed.
                </Text>
              )}
              {!consumerDisplay && (
                <Text
                    as="div"
                    sx={{
                        fontWeight: '500',
                    }}
                >
                There are conditions that require the consumer's action.
                </Text>
              )}
            </Box>
        </Box>
        <Divider
            orientation="horizontal"
            style={styles.divider}
            size="small"
        />
        <Box
            sx={{
                display: 'flex',
                rowGap: '15px',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            >
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                }}
            >
              <Icon
                  variant="circle-phone"
                  size="lg"
                  sx={{
                    mr: 2,
                    minHeight: '42px',
                    minWidth: '42px'
                  }}
              />
              {consumerDisplay && (
                <Text
                    as="div"
                    sx={{
                        fontWeight: '500',
                    }}
                >
                 Additional review is required by GoodLeap. We will provide a decision by email - you may also expect a call from GoodLeap for further verification.
                </Text>
              )}
              {!consumerDisplay && (
                <Text
                    as="div"
                    sx={{
                        fontWeight: '500',
                    }}
                >
                 Additional review is required by GoodLeap. We will provide a decision by email - the consumer may also expect a call from GoodLeap for further verification.
                </Text>
              )}
            </Box>
        </Box>
        </>
        )}
        <Flex
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            alignContent="flex-end"
            wrap="nowrap"
            gap="1rem"
            >
        {!consumerDisplay && (
        <LumosButton onClick={() => resetStatus()}>
            {loader2 ? (
                <div styles={styles.isLoading}>
                    <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                    Reloading status...
                </div>
            ) : (
                'Reload Loan Status'
            )}
        </LumosButton>
        )}
        </Flex>
    </Card>
)}

export default DisplayConditionalDetails;