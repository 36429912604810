import React, { useEffect } from "react"
import { ChoiceInput, AddressInput, ConfirmInput } from "@loanpal/lumos__core"
import { useFormikContext } from "formik"

import { HomeOccupationEnum } from "../../utils/types"

const occupancyOptions = [
  { label: "Primary residence", value: HomeOccupationEnum.PRIMARY },
  { label: "Second or vacation home", value: HomeOccupationEnum.SECONDARY },
  { label: "Investment property", value: HomeOccupationEnum.INVESTMENT },
  { label: "I do not occupy or own this home", value: HomeOccupationEnum.OTHER }
]

const Occupancy = ({
  isSingleBorrower,
  isCoborrower,
  borrowerLocationFormInput
}) => {
  const {
    values: { homeOccupancy, isCoborrowerMailingSameAsBorrower },
    setFieldValue
  } = useFormikContext()

  const isSubjectPropertyNotBorrowersPrimary =
    borrowerLocationFormInput?.homeOccupancy !== HomeOccupationEnum.PRIMARY
  const showMailingAddress =
    Boolean(homeOccupancy) && homeOccupancy !== HomeOccupationEnum.PRIMARY
  const singleBorrowerOptions = occupancyOptions.filter(
    ({ value }) => value !== HomeOccupationEnum.OTHER
  )
  const options = isSingleBorrower ? singleBorrowerOptions : occupancyOptions

  useEffect(() => {
    if (isCoborrowerMailingSameAsBorrower) {
      const address =
        borrowerLocationFormInput?.address.street1 !== ""
          ? borrowerLocationFormInput?.address
          : borrowerLocationFormInput?.subjectProperty
      setFieldValue("address", address)
    } else {
      setFieldValue("address", {
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip: ""
      })
    }
  }, [isCoborrowerMailingSameAsBorrower]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ChoiceInput
        name="homeOccupancy"
        label="This home is your"
        items={options}
      />
      {showMailingAddress && (
        <div>
          {isCoborrower && isSubjectPropertyNotBorrowersPrimary && (
            <ConfirmInput
              name="isCoborrowerMailingSameAsBorrower"
              label="Same mailing address provided by borrower"
            />
          )}
          <div sx={{ legend: { mb: 3, fontSize: 3, fontWeight: 700 } }}>
            <AddressInput
              label="What is your mailing address?"
              name="address"
              autoComplete="off"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Occupancy
