import {
    Text,
    Image,
    useTheme,
    View,
    Heading
  } from "@aws-amplify/ui-react";
  import logo from "../images/goodleap-white-orange.svg";

export const formFields = {
    confirmVerifyUser: {
      confirmation_code: {
        labelHidden: false,
        label: "New Label",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };
  export const components = {
    Header() {
      return (
        <View textAlign="center" style={styles.heading}>
          <Image alt="GoodLeap" src={logo} style={styles.logo} />
        </View>
      );
    },
    VerifyUser: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmVerifyUser: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };

  const styles = {
    logo: {
      width: 400,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 20,
    },
    heading: {
      backgroundColor: "#003439",
    },
  };