import React, { useState, useEffect } from "react";
import { Amplify, API } from "aws-amplify";
import { useParams } from "react-router-dom";
import {
  Authenticator,
  View,
  Flex
} from "@aws-amplify/ui-react";
import { Box } from "theme-ui";
import Loading from "../../utils/loading";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import {
  Heading,
  Button as LumosButton,
 } from "@loanpal/lumos__core";

 import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const Organizations = () => {
  const [spinner] = useState(false);
  const [Organizations, setOrganizations] = useState();
  const [offers, setOffers] = useState();
  const { id } = useParams();

  // TODO: Pagination
  // const [currentPageIndex, setCurrentPageIndex] = useState(1);
  // const [totalPages, setTotalPages] = useState();
  const [nextToken] = useState(undefined);
  // const [nextNextToken, setNextNextToken] = useState();
  // const [previousTokens, setPreviousToken] = useState([]);

  const sandbox_promotionId = "2345f0aa-db7a-42b9-807b-9e8a82b81ae3";
  const prod_promotionId =  "26d09475-32ec-448c-bb43-9f70aea55644";
  const host = window.location.host;
  const clientId = "ServiceTitan";
  // Change environment dev/prod accordingly
  const env = "prod";

  useEffect(() => {
    if (id === 'list') {
        fetchConnectedOrganizations(clientId);
    } else {
        fetchOrganzation(id);
    }
    // eslint-disable-next-line
  }, [nextToken]);

const fetchConnectedOrganizations = async (clientId) => {
    console.log(clientId)
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get connected organizations
      await API.get("developerApi", "/organizations", myInit0)
      .then(async (response) => {
          console.log(response);
          if (response.data) {
            setOrganizations(response.data)
          }
      },
      (err) => {
        console.log("Error getting connected organizations", err);
      })

    } catch (err) {
      console.log("error getting connected organizations", err);
      return false;
    }
  }

  const fetchOrganzation = async (id) => {
    console.log(clientId)
    try {
      const payload = JSON.stringify({
          organizationId: id
      });
      const myInit0 = {
        body: { data: payload || {} },
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          organizationId: id
        },
      };

      // get organization token
      await API.post("developerApi", "/token", myInit0)
      .then(async (response) => {
          console.log(response);
          if (response.token) {
            const myInit1 = {
              queryStringParameters: {
                token: response.token,
                promotionId: getPromotionId()
              },
            };

            await API.get("developerApi", "/offers2", myInit1)
            .then((response) => {
              if (response.data.length > 0) {
               console.log("Offers", response);
               setOffers(response.data);
              }
            })
          }
      },
      (err) => {
        console.log("Error getting organization details", err);
      })

    } catch (err) {
      console.log("error getting organization details", err);
      return false;
    }
  }

  const getPromotionId = () => {
  if (host.includes("sandox") || (env === "dev"))  {
    return sandbox_promotionId;
  } else  {
    return prod_promotionId;
  }
}

return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  marginTop: "20px",
                  fontSize: "20px",
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  {Organizations &&(
                    <View>Organizations</View>
                  )}
                  {offers && (
                    <View>Offers</View>
                  )}
                </Flex>
              </Heading>
              <Flex sx={{ justifyContent: 'space-between' }}>
                {/* <Box sx={{ width: '80%' }}>
                    <Search onSearch={onSearch} placeHolder="Search Organizations" isSearching={isSearching} />
                </Box> */}
            </Flex>
            </Box>
            <Box>
              {/* {Organizations && (
                {Organizations}
              )}
              {offers && (
                {offers}
              )} */}
            </Box>
            </div>
        </>
    )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px 20px",
    backgroundColor: "#ffffff"
  },
  divider: { margin: "25px 0", color: "white" },
  space: { marginTop: "20px" },
  app: { marginBottom: 15 },
  input: {
    marginBottom: 0,
    padding: 6,
    fontSize: 14,
    // width: "250px"
  },
  switch: {
    marginBottom: 10,
    // padding: 8,
    fontSize: 16,
  },
  accountName: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
  },
  paginationButton: {
    marginTop: "30px",
    width: 130,
    height: 40,
  },
  paginationButtonRight: {
    width: 130,
    height: 40,
    marginTop: "30px",
    marginLeft: "200px"
  },
  menuButton: {
    backgroundColor: "#00838f",
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  newRequestButton: {
    width: 130,
    height: 40,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
  },
  catLogo: {
    width: 25,
    position: "relative",
    top: 5,
    margin: "0 auto",
  },
  count: {
    fontSize: "80%",
    fontWeight: 100,
    position: "relative",
    top: -1
  }
};

export default Organizations;
