import React, { useState } from "react"
import {
  Form,
  ConfirmInput,
  Signature,
  Card,
  Heading,
  Button
} from "@loanpal/lumos__core"
import { styles } from "./styles";

import { Box, Spinner, Text } from "theme-ui";

import { object, string, bool } from "yup";
import TextWithHyperlinks from "../../utils/TextWithHyperlinks";

const Disclosures = ({ disclosures, send }) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!disclosures.data || !disclosures.data[0]) return null
  const { sections, token } = disclosures.data[0];
  if (!sections) return null
  const initialValues = sections.reduce(
    (acc, _, index) => Object.assign({}, acc, { [`section_${index}`]: false }),
    {
      electronicConsent: "",
      token: token
    }
  )
  const validationSection = sections.reduce(
    (acc, _, index) =>
      Object.assign({}, acc, {
        [`section_${index}`]: acc.checkbox ? bool()
          .oneOf(
            [true],
            "Before continuing the terms and conditions must be accepted"
          )
          .required(
            "Before continuing the terms and conditions must be accepted"
          ) :
          bool().notRequired()
      }),
    {
      electronicConsent: string().required(
        "Before continuing, the borrower must sign to agree to the terms and conditions"
      )
    }
  )

  const disclosureInputs = sections.map((x, index) => (
    x.checkbox ? (
      <Box
        key={index}
        sx={{
          "& > div": { maxWidth: "100%" },
          label: { fontWeight: 400 },
          p: { m: 0, mb: 1 },
          ul: { m: 0, pl: 2 }
        }}
      >
        <ConfirmInput
          key={index}
          name={`section_${index}`}
          label={<TextWithHyperlinks text={x.text.replace(/\n\t/g, '\n\n')} />}
        />
      </Box>
    ) : (
      <Text><TextWithHyperlinks text={x.text.replace(/\n\t/g, '\n\n')} /></Text>
    )
  ))

  return (
    <Card>
      <Heading variant="h5" as="h2" sx={{ pb: 3 }}>
        {disclosures.data[0].name}
      </Heading>
      <Form
        initialValues={initialValues}
        onSubmit={({ ...values }  ) => {
          console.log("onSubmit", values);
          setIsLoading(true);
          setTimeout(() => send('NEXT_PAGE', { input: { ...values } }), 3000);
        }}
        validationSchema={object(validationSection)}
      >
        {disclosureInputs}
        <Box sx={{ "& > div": { maxWidth: "100%" } }}>
          <Signature
            name="electronicConsent"
            label="Borrower signature"
            description="By signing below, I acknowledge that all information provided is true and correct to the best of my
          knowledge."
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" disabled={isLoading} sx={{ mt: 0, px: 5 }}>
                {isLoading ? (
                <div styles={styles.isLoading}>
                    <Spinner size={20} sx={{ mr: 2, mb: -1 }} />
                    SAVING...
                </div>
                ) : (
                'SAVE AND CONTINUE'
                )}
            </Button>
         </Box>
      </Form>
    </Card>
  )
}

export default Disclosures;
