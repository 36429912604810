import React, { useState } from "react";
import { Box, Spinner } from "theme-ui";
import { object, string, mixed } from "yup";
import { money } from '@loanpal/yup';
import { styles } from "./styles";
import {
  Form,
  Button,
  AddressInput,
  ChoiceInput,
  Heading
} from "@loanpal/lumos__core";
import { Flex } from '@aws-amplify/ui-react';
import { HomeOccupationEnum, HomeOwnershipEnum, SubjectPropertyType } from "../../utils/types";
import Ownership from "./ownership";
import Occupancy from "./occupancy";

const propertyTypeOptions = [
  { label: 'Yes', value: SubjectPropertyType.MANUFACTURED_HOME },
  { label: 'No', value: SubjectPropertyType.SITE_BUILT_HOME },
];

const BorrowerLocation = ({ state, send }) => {
    const [solarLoan] = useState(state.context.solarLoan);
    const [isLoading, setIsLoading] = useState(false);
    const key = state.matches('startApplication.address') ? 'startApplication.address' : 'startApplication.coborrowerAddress';
    const isCoborrower = key === 'startApplication.coborrowerAddress';
    const shouldAskPropertyType = !isCoborrower;
    const isSingleBorrower = !state.context.hasCoborrower;
    const borrowerLocationFormInput = isCoborrower ? state.context.borrowerLocation : null;

    const initialValues =  {
        homeOwnership: "",
        totalMonthlyPayment: "",
        totalRemainingBalance: "",
        homeOccupancy: "",
        isCoborrowerMailingSameAsBorrower: false,
        subjectProperty: {
            street1: solarLoan.borrower.address.street ? solarLoan.borrower.address.street : "",
            street2: solarLoan.borrower.address.street2 ? solarLoan.borrower.address.street2 : "",
            city: solarLoan.borrower.address.city ? solarLoan.borrower.address.city : "",
            state: solarLoan.borrower.address.state ? solarLoan.borrower.address.state: "",
            zip: solarLoan.borrower.address.zip ? solarLoan.borrower.address.zip : ""
        },
        address: {
            street1: solarLoan.coBorrower ? solarLoan.coBorrower.address.street : '',
            street2: solarLoan.coBorrower ? solarLoan.coBorrower.address.street2 : '',
            city: solarLoan.coBorrower ? solarLoan.coBorrower.address.city : '',
            state: solarLoan.coBorrower ? solarLoan.coBorrower.address.state : '',
            zip: solarLoan.coBorrower ? solarLoan.coBorrower.address.zip : '',
        },
        shouldRequirePropertyTypeQuestion: shouldAskPropertyType
    }

    const validationSchema = object().shape({
    subjectProperty: object().shape({
        street1: string().required("Enter your street address"),
        street2: string(),
        city: string().required("Enter your city"),
        state: mixed()
        .oneOf(
            [state.context.selectedStates],
            `This loan offer is not configured for properties in ${state.context.selectedState}`
        )
        .required("Select a State / Territory"),
        zip: string().required("Enter your zip / postal code")
    }),
    homeOwnership: string()
        .oneOf([
        HomeOwnershipEnum.OWNED_WITH_MORTGAGE,
        HomeOwnershipEnum.OWNED_FREE_AND_CLEAR,
        HomeOwnershipEnum.NOT_OWNED
        ])
        .required(
        "Select your relationship to the property where installation will take place"
        ),
    totalMonthlyPayment: money().when(
        ["homeOwnership", "shouldRequireMortgagePaymentInput"],
        {
        is: (homeOwnership, shouldRequire) =>
            Boolean(homeOwnership) &&
            Boolean(shouldRequire) &&
            homeOwnership === HomeOwnershipEnum.OWNED_WITH_MORTGAGE &&
            shouldRequire === "true",
        then: money()
            .typeError("Amount must be a valid currency like $100,000.00")
            .moreThan(0, "Estimated mortgage balance must be more than 0")
            .required("Enter estimated monthly mortgage payment"),
        otherwise: money()
        }
    ),
    totalRemainingBalance: money().when(
        ["homeOwnership", "shouldRequireMortgagePaymentInput"],
        {
        is: (homeOwnership, shouldRequire) =>
            Boolean(homeOwnership) &&
            Boolean(shouldRequire) &&
            homeOwnership === HomeOwnershipEnum.OWNED_WITH_MORTGAGE &&
            shouldRequire === "true",
        then: money()
            .typeError("Amount must be a valid currency like $100,000.00")
            .moreThan(0, "Estimated mortgage balance must be more than 0")
            .required("Enter estimated mortgage balance"),
        otherwise: money()
        }
    ),
    homeOccupancy: string()
        .oneOf([
        HomeOccupationEnum.PRIMARY,
        HomeOccupationEnum.SECONDARY,
        HomeOccupationEnum.INVESTMENT,
        HomeOccupationEnum.OTHER
        ])
        .required(
        "Select your relationship to the property where installation will take place"
        ),
    address: object().when("homeOccupancy", {
        is: val => Boolean(val) && val !== HomeOccupationEnum.PRIMARY,
        then: object().shape({
        street1: string().required("Enter your street address"),
        city: string().required("Enter your city"),
        state: string().required("Enter your state / territory"),
        zip: string().required("Enter your zip / postal code")
        }),
        otherwise: object().notRequired()
    }),
    type: string().when("shouldRequirePropertyTypeQuestion", {
        is: shouldRequirePropertyTypeQuestion => shouldRequirePropertyTypeQuestion,
        then: string().required("Choose a property type.")
    }),
    otherwise: string().notRequired()
    })
    return (
        <Form
            initialValues={initialValues}
            enableReinitialize={false}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={({ ...values }  ) => {
                console.log("onSubmit", values);
                setIsLoading(true);
                setTimeout(() => send('NEXT_PAGE', { input: { ...values } }), 3000);
            }}
            showSummary={false}
          >
              <Box sx={{ pl: 4, pr: 4, pb: 4, pt: 2, backgroundColor: "#fafafa", mt: 2 }}>
                 {isCoborrower ? (
                    <Heading variant="h5" as="h2" sx={{ mb: 3 }}>
                        Co-borrower address
                    </Heading>
                ) : (
                   <Box sx={{ legend: { mb: 3, mt:2, fontSize: 3, fontWeight: 700 } }}>
                    <AddressInput label="Where will the work be done?" name="subjectProperty" autoComplete="off" readOnly />
                   </Box>
                )}
                {shouldAskPropertyType && (
                    <ChoiceInput items={propertyTypeOptions} label="Is this property a mobile/manufactured home?" name="type" />
                )}
                <Ownership isCoborrower={isCoborrower} shouldAskMortgage={true} HomeOwnershipEnum={HomeOwnershipEnum} />
                <Occupancy
                    isCoborrower={isCoborrower}
                    isSingleBorrower={isSingleBorrower}
                    borrowerLocationFormInput={borrowerLocationFormInput}
                />
                  <Flex
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                  >
                    <Button type="submit" disabled={isLoading} sx={{ mt: 3, px: 5 }}>
                      {isLoading ? (
                        <div styles={styles.isLoading}>
                          <Spinner size={20} sx={{ mr: 2, mb: -1 }} />
                          SAVING...
                        </div>
                      ) : (
                        'SAVE AND CONTINUE'
                      )}
                    </Button>
                  </Flex>
              </Box>
            </Form>
    )
}

export default BorrowerLocation;