import Table from '@loanpal/lumos__table';
import { DateTime } from 'luxon';
import { formatDateTime } from "../../utils/format";

export const loanStatusPanel = (loan) => {
  if (!loan) return null;
  const statusObj = loan;
  const formatValue = (value) => {
    if (DateTime.fromISO(value).isValid) return formatDateTime(value);
   if (typeof value === 'boolean')
        return value ? 'Yes' : 'No';
    if (typeof value === 'object' || Array.isArray(value))
        return JSON.stringify(value, null, 2);
    return value;
  };

  const items = Object.keys(statusObj)
    .sort()
    .map((key) => ({
      key,
      value: formatValue(statusObj[key]),
    }));

  const columns = [
    {
      Header: 'Key',
      accessor: 'key',
    },
    {
      Header: 'Value',
      accessor: 'value',
      textAlign: 'right',
    },
  ];
  return <Table columns={columns} data={items} compact />;
};